import { cloneDeep } from "lodash";

import { ModernFile } from "@kraaft/shared/core/modules/file/file";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KRoomSchema,
  KSchema,
  KSchemaColumn,
  KSchemaColumnDefinition,
  KSchemaIcon,
  KSchemaSection,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { AutoNumberingMode } from "@kraaft/shared/core/modules/schema/schema.actions";
import { HoverState } from "@kraaft/shared/core/modules/schema/schema.offline";
import {
  addElementAfterColumnWithKey,
  ensureSchemaColumnsIndex,
  getSchemaTitleColumn,
  KSchemaUtils,
  legacyReorderElement,
} from "@kraaft/shared/core/modules/schema/schema.utils";

export function optimisticSchemaRename(
  schema: KSchema,
  payload: { name: string },
) {
  schema.name = payload.name;
  return schema;
}

export function optimisticSchemaRenameColumn(
  schema: KSchema,
  payload: { columnKey: string; name: string },
) {
  const column = KSchemaUtils.findColumn(schema.rootSection, payload.columnKey);
  if (!column) {
    return schema;
  }
  column.name = payload.name;
  return schema;
}

export function legacyOptimisticSchemaReorderElement(
  schema: KSchema,
  payload: {
    target: string;
    placement: HoverState;
    tryToMoveInsideElement: boolean;
  },
) {
  legacyReorderElement(
    schema,
    payload.target,
    payload.placement,
    payload.tryToMoveInsideElement,
  );
  return schema;
}

export function getContainerKeyAndIndexForReorder(
  schema: KSchema,
  payload: {
    target: string;
    placement: HoverState;
    tryToMoveInsideElement: boolean;
  },
) {
  const afterElement = KSchemaUtils.findWithContext(
    schema.rootSection,
    payload.placement.key,
  );
  const movedElement = KSchemaUtils.findWithContext(
    schema.rootSection,
    payload.target,
  );
  if (!afterElement || !movedElement) {
    return null;
  }
  let added = movedElement.element.index > afterElement.element.index ? 1 : 0;
  added += payload.placement.placement === "before" ? -1 : 0;

  return {
    containerKey:
      payload.tryToMoveInsideElement &&
      afterElement.element.elementType === "section"
        ? afterElement.element.key
        : afterElement.parentSection.key,
    index: afterElement.element.index + added,
  };
}

export function optimisticSchemaEditSection(
  schema: KSchema,
  payload: {
    key: string;
    edits: {
      name?: string;
      color?: string;
    };
  },
) {
  const section = KSchemaUtils.findSectionWithContext(
    schema.rootSection,
    payload.key,
  );
  if (!section) {
    return schema;
  }
  if (payload.edits.name) {
    section.element.name = payload.edits.name;
  }
  if (payload.edits.color) {
    section.element.color = payload.edits.color;
  }
  return schema;
}

export function optimisticSchemaEditMetadata(
  schema: KSchema,
  payload: {
    name?: string;
    icon?: KSchemaIcon;
    autonumbering?: AutoNumberingMode;
    display?: KRoomSchema["roomCardDisplayColumns"];
  },
) {
  if (payload.name !== undefined) {
    schema.name = payload.name;
  }
  if (payload.icon !== undefined && schema.collection === "folder") {
    schema.icon = payload.icon;
  }
  if (payload.autonumbering && schema.collection === "folder") {
    const titleColumn = getSchemaTitleColumn(schema);
    if (payload.autonumbering.mode === "manual") {
      titleColumn.type = KColumnType.shortText;
    } else {
      titleColumn.type = KColumnType.automatedAutoIncrement;
      (
        titleColumn as KSchemaColumn<KColumnType.automatedAutoIncrement>
      ).prefix = payload.autonumbering.prefix;
    }
  }
  if (payload.display && schema.collection === "room") {
    schema.roomCardDisplayColumns = payload.display;
  }
  return schema;
}

export function optimisticSchemaAddSection(
  schema: KSchema,
  payload: { section: KSchemaSection; placement: HoverState },
) {
  addElementAfterColumnWithKey(
    schema,
    {
      ...payload.section,
      isOptimistic: true,
    },
    payload.placement,
    undefined,
  );
  return schema;
}

export function optimisticSchemaAddColumn(
  schema: KSchema,
  payload: {
    column: KSchemaColumn;
    placement: HoverState;
    containerKey: string;
  },
) {
  const container = KSchemaUtils.findElement(
    schema.rootSection,
    payload.containerKey,
  );

  if (container?.elementType !== "section") {
    return;
  }

  const sectionElements = KSchemaUtils.orderedSectionElements(container);
  const targetElementIndex = sectionElements.findIndex(
    (element) => element.key === payload.placement.key,
  );

  const targetIndex =
    targetElementIndex + (payload.placement.placement === "after" ? 1 : 0);

  ensureSchemaColumnsIndex([
    ...sectionElements.slice(0, targetIndex),
    payload.column,
    ...sectionElements.slice(targetIndex),
  ]);

  payload.column.isOptimistic = true;
  container.elements[payload.column.key] = payload.column;

  return schema;
}

export function optimisticSchemaEditColumnDefinition(
  schema: KSchema,
  payload: { columnKey: string; definition: KSchemaColumnDefinition },
) {
  const column = KSchemaUtils.findColumn(schema.rootSection, payload.columnKey);
  if (!column) {
    return schema;
  }
  Object.assign(column, payload.definition);
  return schema;
}

export function optimisticSchemaDeleteColumn(
  schema: KSchema,
  payload: { columnKey: string },
) {
  KSchemaUtils.deleteColumn(schema.rootSection, payload.columnKey);
  return schema;
}

function createUniqueElementKeys(element: KSchemaSection | KSchemaColumn) {
  if (element.elementType === "column") {
    element.key = `${element.key}_`;
    element.reportKey = `${element.reportKey}_`;
    return;
  }
  Object.values(element.elements).forEach(createUniqueElementKeys);
}

export function optimisticDuplicateSchemaElement(
  schema: KSchema,
  payload: {
    key: string;
  },
) {
  const context = KSchemaUtils.findWithContext(schema.rootSection, payload.key);
  if (!context) {
    return schema;
  }
  const { parentSection, element } = context;
  const clonedElement = cloneDeep(element);
  createUniqueElementKeys(clonedElement);
  const clonedElementKey = `${element.key}_`;
  clonedElement.key = clonedElementKey;
  clonedElement.reportKey = clonedElementKey;
  parentSection.elements[clonedElementKey] = clonedElement;
}

export function optimisticEditSchemaElementDescription(
  schema: KSchema,
  key: string,
  description: {
    text?: string | undefined;
    image?: ModernFile | null | undefined;
    document?: ModernFile | null | undefined;
  },
) {
  const element = KSchemaUtils.findElement(schema.rootSection, key);

  if (!element) {
    return schema;
  }
  element.description ??= { text: "", image: undefined, document: undefined };

  if (description.text !== undefined) {
    element.description.text = description.text;
  }

  if (description.image !== undefined) {
    element.description.image = description.image
      ? {
          downloadUrl: description.image.path,
          filename: description.image.filename,
        }
      : undefined;
  }

  if (description.document !== undefined) {
    element.description.document = description.document
      ? {
          downloadUrl: description.document.path,
          filename: description.document.filename,
        }
      : undefined;
  }

  return schema;
}
