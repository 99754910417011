import {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { ListRenderItemInfo, SectionList, View } from "react-native";
import { SectionListData } from "react-native/Libraries/Lists/SectionList";
import isEqual from "fast-deep-equal";

import { MapButtonContainer } from "@kraaft/shared/components/mapButton/container";
import { OutdatedVersionBanner } from "@kraaft/shared/components/outdatedVersionBanner";
import { RoomCardConnector } from "@kraaft/shared/components/roomCard/roomCardConnector";
import { ResetRoomFiltersButton } from "@kraaft/shared/components/roomList/resetRoomFiltersButton";
import {
  LoadingFooter,
  LoadMoreErrorFooter,
  NoResultFooter,
} from "@kraaft/shared/components/roomList/roomListFooters";
import { useLastReadRoomId } from "@kraaft/shared/core/modules/roomCard/hooks/useLastReadRoomId";
import { useRoomCards } from "@kraaft/shared/core/modules/roomCard/hooks/useRoomCards";
import { AnyRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { useAppRoomFilters } from "@kraaft/shared/core/utils/useAppRoomFilters";
import { Spacing } from "@kraaft/ui";

import * as utils from "./roomListUtils";
import { useOnScrollRoomList } from "./useOnScrollRoomList";

import { styles } from "./roomList.styles";

export interface RoomListProps {
  onItemLongPress?: (item: AnyRoomCard) => void;
  onItemPress: (item: AnyRoomCard) => void;
  onItemHover?: (item: AnyRoomCard | undefined) => void;
}

const listSeparator = () => (
  <View style={styles.separator}>
    <View style={styles.separatorLine} />
  </View>
);

const listHeader = () => (
  <>
    <OutdatedVersionBanner />
    <View style={styles.mapButton}>
      <MapButtonContainer type="rooms" context={{ location: "Home" }} />
    </View>
    <ResetRoomFiltersButton />
  </>
);

const renderSectionFooter = ({
  section,
}: { section: SectionListData<AnyRoomCard> }) =>
  section.key === "pinned" ? <View style={styles.pinnedSeparator} /> : null;

const RoomList_: FunctionComponent<RoomListProps> = ({
  onItemPress,
  onItemLongPress,
  onItemHover,
}) => {
  const sectionListRef = useRef<SectionList>(null);
  const hovered = useRef<AnyRoomCard | undefined>();

  const { filters } = useAppRoomFilters();

  const justReadRoomId = useLastReadRoomId();
  const {
    pinnedRoomCards,
    otherRoomCards,
    isLoading,
    isLoadingMore,
    loadMore,
    isTyping,
    hasError,
  } = useRoomCards({ filters, justReadRoomId });

  useEffect(() => {
    sectionListRef.current
      ?.getScrollResponder()
      ?.scrollTo({ x: 0, y: 0, animated: false });
  }, [filters]);

  const handleHover = useCallback(
    (item: AnyRoomCard, value: boolean) => {
      if (value) {
        hovered.current = item;
        onItemHover?.(hovered.current);
      } else if (item.roomId === hovered.current?.roomId) {
        hovered.current = undefined;
        onItemHover?.(hovered.current);
      }
    },
    [onItemHover],
  );

  const renderItem = useCallback(
    ({ item: roomCard }: ListRenderItemInfo<AnyRoomCard>) => {
      return (
        <RoomCardConnector
          key={roomCard.id}
          roomCard={roomCard}
          onPress={onItemPress}
          onLongPress={onItemLongPress}
          onHover={handleHover}
        />
      );
    },
    [onItemPress, onItemLongPress, handleHover],
  );

  const footer = useMemo(
    () =>
      isLoading || isLoadingMore || isTyping ? (
        <LoadingFooter />
      ) : hasError ? (
        <LoadMoreErrorFooter />
      ) : pinnedRoomCards.length === 0 && otherRoomCards.length === 0 ? (
        <NoResultFooter />
      ) : null,
    [
      hasError,
      isLoading,
      isLoadingMore,
      isTyping,
      otherRoomCards.length,
      pinnedRoomCards.length,
    ],
  );

  const onScroll = useOnScrollRoomList();

  const sections = useMemo<SectionListData<AnyRoomCard>[]>(() => {
    if (isLoading) {
      return [
        {
          key: "notPinned",
          data: [],
        },
      ];
    }

    const value = [
      {
        key: "notPinned",
        data: otherRoomCards,
      },
    ];

    if (pinnedRoomCards.length > 0) {
      value.unshift({
        key: otherRoomCards.length > 0 ? "pinned" : "pinnedLonely",
        data: pinnedRoomCards,
      });
    }

    return value;
  }, [isLoading, otherRoomCards, pinnedRoomCards]);

  return (
    <SectionList
      nativeID="rooms-list"
      ref={sectionListRef}
      sections={sections}
      keyExtractor={utils.extractKey}
      renderItem={renderItem}
      renderSectionFooter={renderSectionFooter}
      ItemSeparatorComponent={listSeparator}
      ListHeaderComponent={listHeader}
      ListFooterComponent={footer}
      onEndReached={loadMore}
      onEndReachedThreshold={1}
      onScroll={onScroll}
      contentContainerStyle={{ paddingBottom: Spacing.S128 }}
    />
  );
};

export const RoomList = memo(RoomList_, isEqual) as typeof RoomList_;
