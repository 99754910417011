import React, { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TextInputProps } from "react-native";

import { useInputDefaultValueBehavior } from "@kraaft/helper-hooks";
import { useKeyboardAwareScrollViewContext } from "@kraaft/shared/components/keyboardAware/keyboardAwareScrollViewContext";
import { EditorProps } from "@kraaft/shared/components/modular/details/editors/types";
import { EMPTY_LOCKED_RECORD_PLACEHOLDER } from "@kraaft/shared/components/modular/details/utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { useUrlMatcherForTextInput } from "@kraaft/shared/core/utils/useUrlMatcherForTextInput";
import { DroppableInput, TextInputWithMatchers } from "@kraaft/ui";

type Props = EditorProps<KColumnType.longText>;

const LongTextEditor = ({
  editor: { label, disabled, value, setValue, sectionLockInfo },
  column,
  testID,
}: Props) => {
  const { t } = useTranslation();
  const [text, setText, { onBlur, ...parentExtraInputProps }] =
    useInputDefaultValueBehavior(value ?? "", false);
  const matchers = useUrlMatcherForTextInput();

  const { update: updateKeyboardAwareScrollview } =
    useKeyboardAwareScrollViewContext();

  const handleBlur = useCallback(() => {
    onBlur();
    if (!sectionLockInfo.isLocked && !disabled) {
      setValue(text);
    }
  }, [sectionLockInfo.isLocked, disabled, onBlur, setValue, text]);

  const placeholder = !sectionLockInfo.isLocked
    ? t("modularity.answer")
    : EMPTY_LOCKED_RECORD_PLACEHOLDER;

  const handleOnLayout = useOnTextInputLayoutHeightChangedCallback(
    updateKeyboardAwareScrollview,
  );

  const extraTextInputProps = useMemo<
    React.ComponentProps<typeof DroppableInput>["extraTextInputProps"]
  >(() => {
    return {
      onLayout: handleOnLayout,
      matchers,
      ...parentExtraInputProps,
    };
  }, [handleOnLayout, matchers, parentExtraInputProps]);

  return (
    <DroppableInput
      nativeID={`${testID}-${column.key}`}
      accessibilityLabel={label}
      multiline
      disabled={disabled || sectionLockInfo.isLocked}
      value={text}
      placeholder={placeholder}
      autoFocus={parentExtraInputProps.autoFocus}
      disableAutocomplete
      containerStyle={styles.inputContainer}
      onChangeText={setText}
      onBlur={handleBlur}
      TextInputComponent={TextInputWithMatchers}
      extraTextInputProps={extraTextInputProps}
      translate={t}
    />
  );
};

/**
 * Local hook for now since it might never be used elsewhere,
 * @param callback is the callback that needs to be invoked whenever a textInput height changes
 */
function useOnTextInputLayoutHeightChangedCallback(callback: () => void) {
  const lastTextInputHeightRef = useRef<number>();
  const handleOnLayout = useCallback<NonNullable<TextInputProps["onLayout"]>>(
    ({ nativeEvent }) => {
      const roundedHeight = Math.round(nativeEvent.layout.height);
      if (
        lastTextInputHeightRef.current &&
        roundedHeight !== lastTextInputHeightRef.current
      ) {
        callback();
      }
      lastTextInputHeightRef.current = roundedHeight;
    },
    [callback],
  );

  return handleOnLayout;
}

const LONG_TEXT_INPUT_MAX_HEIGHT = 150;

const styles = StyleSheet.create({
  inputContainer: {
    maxHeight: LONG_TEXT_INPUT_MAX_HEIGHT,
  },
});

export { LongTextEditor };
