import { useMemo } from "react";
import { ImageStyle, StyleSheet, View } from "react-native";
import { assert } from "ts-essentials";

import { type ResourceKey } from "@kraaft/shared/core/services/i18next";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { PermissionName } from "@kraaft/shared/core/services/permission/permissions.types";
import {
  Image,
  ImageSource as ImageSourcePropType,
  Spacing,
  Text,
} from "@kraaft/ui";

import cameraAsset from "./assets/camera.png";
import contactsAsset from "./assets/contacts.png";
import galleryAsset from "./assets/gallery.png";
import geolocationAsset from "./assets/geolocation.png";
import microphoneAsset from "./assets/microphone.png";
import notificationsAsset from "./assets/notifications.png";

interface Manifest {
  title: ResourceKey;
  subtitle: ResourceKey | undefined;
  image: ImageSourcePropType;
  width: number;
  height: number;
}

const FACTORY = {
  notification: {
    title: "permissions.notification.title",
    subtitle: "permissions.notification.subtitle",
    image: notificationsAsset,
    height: 516,
    width: 503,
  },
  camera: {
    title: "permissions.camera.title",
    subtitle: "permissions.camera.subtitle",
    image: cameraAsset,
    height: 392,
    width: 478,
  },
  location: {
    title: "permissions.location.title",
    subtitle: "permissions.location.subtitle",
    image: geolocationAsset,
    height: 516,
    width: 487,
  },
  photo_library: {
    title: "permissions.photo_library.title",
    subtitle: "permissions.photo_library.subtitle",
    image: galleryAsset,
    height: 492,
    width: 544,
  },
  audio_recording: {
    title: "permissions.audio_recording.title",
    subtitle: "permissions.audio_recording.subtitle",
    image: microphoneAsset,
    height: 536,
    width: 496,
  },
  read_contacts: {
    title: "permissions.read_contacts.title",
    subtitle: "permissions.read_contacts.subtitle",
    image: contactsAsset,
    height: 516,
    width: 517,
  },
} satisfies Record<PermissionName, Manifest | null>;

interface PermissionRequesterContentProps {
  type: PermissionName;
}

export const PermissionRequesterContent = ({
  type,
}: PermissionRequesterContentProps) => {
  const factoryElement = FACTORY[type];
  assert(
    factoryElement !== undefined,
    `PermissionRequesterContent :: unknown type(${type})`,
  );
  const { title, subtitle, image, height, width } = factoryElement;

  const imageStyle = useMemo<ImageStyle>(
    () => ({ aspectRatio: width / height }),
    [height, width],
  );

  return (
    <View style={styles.centeredContainer}>
      <Image
        source={image}
        contentFit="contain"
        style={[styles.image, imageStyle]}
      />
      <View style={styles.textContainer}>
        <Text
          weight="bold"
          size="TITLE"
          color="FONT_HIGH_EMPHASIS"
          style={styles.text}
        >
          <Trans i18nKey={title} />
        </Text>
        <Text size="BODY" color="FONT_HIGH_EMPHASIS" style={styles.text}>
          <Trans i18nKey={subtitle} />
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredContainer: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    height: "auto",
    width: "35%",
  },
  textContainer: {
    gap: Spacing.S16,
  },
  text: {
    textAlign: "center",
  },
});
