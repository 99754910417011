import { useTranslation } from "react-i18next";

import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { ColorStyle, Icon, Text } from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";
import { ConditionExplainer } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementCondition/conditionExplainer";

interface ElementConditionProps {
  condition: CompositeCondition;
}

const ElementCondition = ({ condition }: ElementConditionProps) => {
  const { t } = useTranslation();

  return (
    <Box row items="center" gap="S8" pb="S8">
      <Icon name="eye" color={ColorStyle.FONT_LOW_EMPHASIS} />
      <Text size="BODY" color="FONT_LOW_EMPHASIS">
        {t("formBuilder.schema.condition.displayedIf")}
        &nbsp;
        <ConditionExplainer compositeCondition={condition} />
      </Text>
    </Box>
  );
};

export { ElementCondition };
