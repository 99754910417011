import { Platform, StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  marker: {
    height: 50,
    width: "auto",
    aspectRatio: Platform.select({ native: 1 }),
    resizeMode: "contain",

    bottom: "46.5%", // Offset the picture to center the dot at the bottom with the screen
  },
});
