import { createReducer } from "@reduxjs/toolkit";

import { LibrarySchemaStateActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.actions";
import { OfflineLibrarySchemaStateActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.offline";
import { LibrarySchemaState } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

const initialState: LibrarySchemaState = {
  fetched: false,
  librarySchemas: {},
  companyLibrarySchemaIds: [],
  publicLibrarySchemaIds: [],
  createdColumnKey: undefined,
};

export const librarySchemaReducers = createReducer(
  initialState,
  ({ addCase }) => {
    addCase(UserActions.userDisconnectedFromFirebase, () => initialState);

    addCase(OfflineLibrarySchemaStateActions.set, (state, { payload }) => {
      state.fetched = true;

      state.librarySchemas = payload.aggregates;
      const [publicLibrarySchemaIds, companyLibrarySchemaIds] = Object.values(
        payload.aggregates,
      ).reduce<[string[], string[]]>(
        (acc, curr) => {
          if (!curr.companyId) {
            acc[0].push(curr.id);
          } else {
            acc[1].push(curr.id);
          }
          return acc;
        },
        [[], []],
      );
      state.publicLibrarySchemaIds = publicLibrarySchemaIds;
      state.companyLibrarySchemaIds = companyLibrarySchemaIds;
    });

    addCase(LibrarySchemaStateActions.setCreatedKey, (state, { payload }) => {
      state.createdColumnKey = payload;
    });
  },
);
