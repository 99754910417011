import {
  KSchema,
  KSchemaColumnDefinition,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { optimisticSchemaEditColumnDefinition } from "@kraaft/shared/core/modules/schema/schema.optimisticHelper";
import { Api } from "@kraaft/shared/core/services/api";
import { Operation } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations";

export const editColumnDefinitionOperation = Operation.editOne<KSchema>()
  .payload<{ columnKey: string; definition: KSchemaColumnDefinition }>()
  .expected((aggregate, payload) => {
    optimisticSchemaEditColumnDefinition(aggregate, payload);
    return aggregate;
  })
  .mutate(async (payload) => {
    const { updatedAt } = await Api.editSchemaColumn({
      schemaId: payload.id,
      columnKey: payload.columnKey,
      column: {
        definition: payload.definition,
      },
    });
    return { updatedAt };
  });
