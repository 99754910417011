import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { optimisticSchemaRenameColumn } from "@kraaft/shared/core/modules/schema/schema.optimisticHelper";
import { Api } from "@kraaft/shared/core/services/api";
import { EditOneOperationBuilder } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations/editOne.operation";

export const renameSchemaColumnOperation =
  EditOneOperationBuilder.create<LibrarySchema>()
    .payload<{ columnKey: string; name: string }>()
    .expected((aggregate, payload) => {
      optimisticSchemaRenameColumn(aggregate.schema, payload);
      return aggregate;
    })
    .mutate(async (payload) => {
      const { updatedAt } = await Api.editLibrarySchemaColumn({
        librarySchemaId: payload.id,
        columnKey: payload.columnKey,
        update: {
          name: payload.name,
        },
      });
      return { updatedAt };
    });
