import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useMeshContext } from "@kraaft/helper-hooks";
import { RoomFiltersContext } from "@kraaft/shared/components/filters/roomFilters.context";

export function useReadingStatusSummary() {
  const { t } = useTranslation();

  const { readingStatus } = useMeshContext(RoomFiltersContext);

  return useMemo(() => {
    if (readingStatus === "read") {
      return t("roomFilters.read");
    }
    if (readingStatus === "unread") {
      return t("roomFilters.unread");
    }
    return t("roomFilters.allReadingStatus");
  }, [readingStatus, t]);
}
