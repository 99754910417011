import { assert } from "ts-essentials";

import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { Api } from "@kraaft/shared/core/services/api";
import { Operation } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations";

export const setHighlightedCheckboxOperation = Operation.editOne<KSchema>()
  .payload<{ columnKey: string | undefined }>()
  .expected((aggregate, payload) => {
    assert(aggregate.collection === "folder");
    aggregate.highlightedCheckbox = payload.columnKey;
    return aggregate;
  })
  .mutate(async (payload) => {
    const { updatedAt } = await Api.setPoolSchemaHighlightedCheckbox({
      schemaId: payload.id,
      columnKey: payload.columnKey,
    });
    return { updatedAt };
  });
