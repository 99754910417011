import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { OfflineLibrarySchemaActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.offline";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { KSchemaIcon } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { AutoNumberingMode } from "@kraaft/shared/core/modules/schema/schema.actions";
import { getColumnDefinitionFromAutonumberingMode } from "@kraaft/shared/core/modules/schema/schema.utils";
import { EditMetadata } from "@kraaft/web/src/components/schemaBuilder/tabs/editMetadata/editMetadata";

interface EditLibrarySchemaMetadataProps {
  librarySchema: LibrarySchema;
}

const EditLibrarySchemaMetadata = ({
  librarySchema,
}: EditLibrarySchemaMetadataProps) => {
  const dispatch = useDispatch();

  const handleChangeName = useCallback(
    (name: string) => {
      dispatch(
        OfflineLibrarySchemaActions.renameSchema({
          id: librarySchema.id,
          name,
        }),
      );
    },
    [dispatch, librarySchema.id],
  );

  const handleChangeIcon = useCallback(
    (icon: KSchemaIcon) => {
      dispatch(
        OfflineLibrarySchemaActions.editSchemaMetadata({
          id: librarySchema.id,
          icon,
        }),
      );
    },
    [dispatch, librarySchema.id],
  );

  const handleChangeAutonumberMode = useCallback(
    (autoNumberingMode: AutoNumberingMode) => {
      dispatch(
        OfflineLibrarySchemaActions.editSchemaColumnDefinition({
          id: librarySchema.id,
          columnKey: "title",
          definition:
            getColumnDefinitionFromAutonumberingMode(autoNumberingMode),
        }),
      );
    },
    [dispatch, librarySchema.id],
  );

  const handleChangeHighlightedCheckbox = useCallback(
    (columnKey: string | undefined) => {
      dispatch(
        OfflineLibrarySchemaActions.setSchemaHighlightedCheckbox({
          id: librarySchema.id,
          columnKey,
        }),
      );
    },
    [dispatch, librarySchema.id],
  );

  return (
    <EditMetadata
      schema={librarySchema.schema}
      onChangeName={handleChangeName}
      onChangeIcon={handleChangeIcon}
      onChangeAutonumberMode={handleChangeAutonumberMode}
      onChangeHighlightedCheckbox={handleChangeHighlightedCheckbox}
      skipConfirmation
    />
  );
};
export { EditLibrarySchemaMetadata };
