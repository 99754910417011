import React, { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  ImageStyle,
  Pressable,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";

import { useHover } from "@kraaft/helper-hooks";
import { Checkbox } from "@kraaft/shared/components/checkbox";
import { getGalleryImageSize } from "@kraaft/shared/components/galleries/photoGallery/useSortedGallery";
import { MiniImage } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { Color, Image } from "@kraaft/ui";

import { CHECKBOX_PADDING, styles } from "./photoListItem.styles";

interface PhotoListItemProps {
  imageHeight: number;
  image: MiniImage;
  allowSelection: boolean;
  onSelect: (id: string, newStatus: boolean) => void;
  onHover?: (media: MiniImage, hoverState: boolean) => void; // web
  selected: boolean;
  disabled: boolean;
  onPress: (messageId: string) => void;
  shouldDisplayCheckbox: boolean;
}

const CHECKBOX_SIZE = 32;

const PhotoListItem_ = ({
  shouldDisplayCheckbox,
  allowSelection,
  imageHeight,
  image,
  onSelect,
  onHover,
  selected,
  disabled,
  onPress,
}: PhotoListItemProps) => {
  const ref = useRef<View>(null);

  const { t } = useTranslation();

  const displayedImage = image.preview;

  const sizeStyle = useMemo<ImageStyle>(() => {
    const imageSize = getGalleryImageSize(displayedImage.size);

    const ratio = imageHeight / imageSize.height;

    return {
      width: imageSize.width * ratio,
      height: imageHeight,
      minHeight: CHECKBOX_PADDING * 2 + CHECKBOX_SIZE,
    };
  }, [displayedImage.size, imageHeight]);

  const handleHovered = useCallback(
    (hoverState: boolean) => {
      onHover?.(image, hoverState);
    },
    [image, onHover],
  );

  const hovered = useHover(ref, handleHovered);

  const shouldShowCheckbox =
    allowSelection && (shouldDisplayCheckbox || selected || hovered);

  const overlayStyle = useMemo<ViewStyle>(
    () => ({
      ...StyleSheet.absoluteFillObject,
      opacity: 0.2,
      transition: "all 250ms",
      backgroundColor:
        selected || hovered
          ? Color.WHITE
          : shouldDisplayCheckbox
            ? Color.BLACK
            : undefined,
    }),
    [hovered, selected, shouldDisplayCheckbox],
  );

  const handlePress = useCallback(() => {
    if (allowSelection && shouldDisplayCheckbox) {
      onSelect(image.messageId, !selected);
    } else {
      onPress(image.messageId);
    }
  }, [
    allowSelection,
    image.messageId,
    onPress,
    onSelect,
    selected,
    shouldDisplayCheckbox,
  ]);

  const onCheckboxPress = useCallback(() => {
    if (!disabled) {
      onSelect(image.messageId, !selected);
    }
  }, [disabled, image.messageId, onSelect, selected]);

  return (
    <Pressable
      accessibilityLabel={t("select")}
      onPress={handlePress}
      onLongPress={onCheckboxPress}
    >
      <View ref={ref}>
        <Image
          contentFit="cover"
          style={sizeStyle}
          source={displayedImage?.downloadUrl}
          preferredSize="thumbnail"
        />
        <View style={overlayStyle} />
        {shouldShowCheckbox && (
          <View style={styles.check}>
            <Checkbox
              size={CHECKBOX_SIZE}
              onPress={onCheckboxPress}
              checked={selected}
              greyedNotSelected
              showWhiteBackground
              outline={selected}
              whiteOnHover
              disabled={disabled}
            />
          </View>
        )}
      </View>
    </Pressable>
  );
};

export const PhotoListItem = React.memo(PhotoListItem_);
