import { useTranslation } from "react-i18next";

import { Icon, Text } from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";

export const ElementDescription = () => {
  const { t } = useTranslation();

  return (
    <Box row gap="S8" items="center" pb="S8">
      <Icon name="info-circle" color="FONT_LOW_EMPHASIS" />
      <Text size="BODY" color="FONT_LOW_EMPHASIS">
        {t("schemaColumnOptions.description.containsDescription")}
      </Text>
    </Box>
  );
};
