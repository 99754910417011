import { compactMap } from "@kraaft/helper-functions";
import {
  ModularFolderCreated,
  ModularFolderHistoryEntry,
} from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.state";
import { SchemaElementWithPath } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import {
  KSchemaConversion,
  RawColumnType,
} from "@kraaft/shared/core/modules/schema/schema.conversion";
import { onSnapshotQuery } from "@kraaft/shared/core/services/firestore";
import { FirestoreModularRecord } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { parseDate } from "@kraaft/shared/core/services/firestore/parseDate";
import {
  firestore,
  FirestoreTypes,
} from "@kraaft/shared/core/services/firestore/sdk";

type FirestoreModularRecordProperties = FirestoreModularRecord["properties"];
type FirestoreModularRecordProperty = FirestoreModularRecordProperties[string];

interface BaseFirestoreModularFolderHistoryEntry {
  id: string;
  modularFolderId: string;
  at: Date;
  by: string;
}

interface FirestoreModularFolderCreated
  extends BaseFirestoreModularFolderHistoryEntry {
  type: "created";
  properties: FirestoreModularRecordProperties;
}

interface FirestoreModularFolderPropertyChecked
  extends BaseFirestoreModularFolderHistoryEntry {
  type: "propertyChecked";
  key: string;
}

interface FirestoreModularFolderPropertyUnchecked
  extends BaseFirestoreModularFolderHistoryEntry {
  type: "propertyUnchecked";
  key: string;
}

interface FirestoreModularFolderPropertyDefined
  extends BaseFirestoreModularFolderHistoryEntry {
  type: "propertyDefined";
  key: string;
  value: FirestoreModularRecordProperty;
}

interface FirestoreModularFolderPropertyChanged
  extends BaseFirestoreModularFolderHistoryEntry {
  type: "propertyChanged";
  key: string;
  oldValue: FirestoreModularRecordProperty;
  newValue: FirestoreModularRecordProperty;
}

interface FirestoreModularFolderPropertyErased
  extends BaseFirestoreModularFolderHistoryEntry {
  type: "propertyErased";
  key: string;
  oldValue: FirestoreModularRecordProperty;
}

interface FirestoreModularFolderSectionLocked
  extends BaseFirestoreModularFolderHistoryEntry {
  type: "sectionLocked";
  section: string;
}

interface FirestoreModularFolderSectionUnlocked
  extends BaseFirestoreModularFolderHistoryEntry {
  type: "sectionUnlocked";
  section: string;
}

interface FirestoreModularFolderLocked
  extends BaseFirestoreModularFolderHistoryEntry {
  type: "locked";
}

interface FirestoreModularFolderUnlocked
  extends BaseFirestoreModularFolderHistoryEntry {
  type: "unlocked";
}

export type FirestoreModularFolderHistoryEntry =
  | FirestoreModularFolderCreated
  | FirestoreModularFolderPropertyChecked
  | FirestoreModularFolderPropertyChecked
  | FirestoreModularFolderPropertyUnchecked
  | FirestoreModularFolderPropertyDefined
  | FirestoreModularFolderPropertyChanged
  | FirestoreModularFolderPropertyErased
  | FirestoreModularFolderSectionLocked
  | FirestoreModularFolderSectionUnlocked
  | FirestoreModularFolderLocked
  | FirestoreModularFolderUnlocked;

function getColumnTypeFromSchemaElementWithPath(
  schemaElementWithPath: SchemaElementWithPath | undefined,
  firestoreModularRecordProperty: FirestoreModularRecordProperty,
) {
  if (
    schemaElementWithPath === undefined ||
    schemaElementWithPath.element.elementType !== "column"
  ) {
    return KSchemaConversion.toColumnType(
      RawColumnType[firestoreModularRecordProperty.columnType],
    );
  }
  return schemaElementWithPath.element.type;
}

export function normalizeModularFolderHistoryEntries(
  firestoreModularFolderHistoryEntries: FirestoreModularFolderHistoryEntry[],
  schemaElementWithPaths: Record<string, SchemaElementWithPath>,
): ModularFolderHistoryEntry[] {
  return compactMap(
    firestoreModularFolderHistoryEntries,
    // eslint-disable-next-line complexity
    (firestoreModularFolderHistoryEntry): ModularFolderHistoryEntry => {
      const baseModularFolderHistoryEntry = {
        id: firestoreModularFolderHistoryEntry.id,
        modularFolderId: firestoreModularFolderHistoryEntry.modularFolderId,
        at: parseDate(firestoreModularFolderHistoryEntry.at),
        by: firestoreModularFolderHistoryEntry.by,
      };

      switch (firestoreModularFolderHistoryEntry.type) {
        case "created": {
          const { properties, missingSchemaColumns } = Object.entries(
            firestoreModularFolderHistoryEntry.properties,
          ).reduce<{
            properties: ModularFolderCreated["properties"];
            missingSchemaColumns: ModularFolderCreated["missingSchemaColumns"];
          }>(
            (accumulator, [key, firestoreModularRecordProperty]) => {
              const schemaElementWithPath = schemaElementWithPaths[key];
              const property =
                KSchemaConversion.alignModularRecordPropertiesWithSchemaColumn(
                  firestoreModularRecordProperty,
                  getColumnTypeFromSchemaElementWithPath(
                    schemaElementWithPath,
                    firestoreModularRecordProperty,
                  ),
                );
              accumulator.properties[key] = property;
              if (
                schemaElementWithPath === undefined ||
                (schemaElementWithPath.element.elementType === "column" &&
                  firestoreModularRecordProperty.columnType !==
                    KSchemaConversion.toRawColumnType(
                      schemaElementWithPath.element.type,
                    ))
              ) {
                accumulator.missingSchemaColumns[key] = true;
              }
              return accumulator;
            },
            { properties: {}, missingSchemaColumns: {} },
          );
          return {
            ...baseModularFolderHistoryEntry,
            type: "created",
            properties,
            missingSchemaColumns: missingSchemaColumns,
          };
        }
        case "propertyChecked": {
          const schemaElementWithPath =
            schemaElementWithPaths[firestoreModularFolderHistoryEntry.key];

          return {
            ...baseModularFolderHistoryEntry,
            type: "propertyChecked",
            key: firestoreModularFolderHistoryEntry.key,
            isSchemaColumnMissing: schemaElementWithPath === undefined,
          };
        }
        case "propertyUnchecked": {
          const schemaElementWithPath =
            schemaElementWithPaths[firestoreModularFolderHistoryEntry.key];

          return {
            ...baseModularFolderHistoryEntry,
            type: "propertyUnchecked",
            key: firestoreModularFolderHistoryEntry.key,
            isSchemaColumnMissing: schemaElementWithPath === undefined,
          };
        }
        case "propertyDefined": {
          const schemaElementWithPath =
            schemaElementWithPaths[firestoreModularFolderHistoryEntry.key];

          return {
            ...baseModularFolderHistoryEntry,
            type: "propertyDefined",
            key: firestoreModularFolderHistoryEntry.key,
            value:
              KSchemaConversion.alignModularRecordPropertiesWithSchemaColumn(
                firestoreModularFolderHistoryEntry.value,
                getColumnTypeFromSchemaElementWithPath(
                  schemaElementWithPath,
                  firestoreModularFolderHistoryEntry.value,
                ),
              ),
            isSchemaColumnMissing: schemaElementWithPath === undefined,
          };
        }
        case "propertyChanged": {
          const schemaElementWithPath =
            schemaElementWithPaths[firestoreModularFolderHistoryEntry.key];

          return {
            ...baseModularFolderHistoryEntry,
            type: "propertyChanged",
            key: firestoreModularFolderHistoryEntry.key,
            oldValue:
              KSchemaConversion.alignModularRecordPropertiesWithSchemaColumn(
                firestoreModularFolderHistoryEntry.oldValue,
                getColumnTypeFromSchemaElementWithPath(
                  schemaElementWithPath,
                  firestoreModularFolderHistoryEntry.oldValue,
                ),
              ),
            newValue:
              KSchemaConversion.alignModularRecordPropertiesWithSchemaColumn(
                firestoreModularFolderHistoryEntry.newValue,
                getColumnTypeFromSchemaElementWithPath(
                  schemaElementWithPath,
                  firestoreModularFolderHistoryEntry.newValue,
                ),
              ),
            isSchemaColumnMissing: schemaElementWithPath === undefined,
          };
        }
        case "propertyErased": {
          const schemaElementWithPath =
            schemaElementWithPaths[firestoreModularFolderHistoryEntry.key];

          return {
            ...baseModularFolderHistoryEntry,
            type: "propertyErased",
            key: firestoreModularFolderHistoryEntry.key,
            oldValue:
              KSchemaConversion.alignModularRecordPropertiesWithSchemaColumn(
                firestoreModularFolderHistoryEntry.oldValue,
                getColumnTypeFromSchemaElementWithPath(
                  schemaElementWithPath,
                  firestoreModularFolderHistoryEntry.oldValue,
                ),
              ),
            isSchemaColumnMissing: schemaElementWithPath === undefined,
          };
        }
        case "sectionLocked": {
          const schemaElementWithPath =
            schemaElementWithPaths[firestoreModularFolderHistoryEntry.section];

          return {
            ...baseModularFolderHistoryEntry,
            type: "sectionLocked",
            section: firestoreModularFolderHistoryEntry.section,
            isSchemaSectionMissing: schemaElementWithPath === undefined,
          };
        }
        case "sectionUnlocked": {
          const schemaElementWithPath =
            schemaElementWithPaths[firestoreModularFolderHistoryEntry.section];

          return {
            ...baseModularFolderHistoryEntry,
            type: "sectionUnlocked",
            section: firestoreModularFolderHistoryEntry.section,
            isSchemaSectionMissing: schemaElementWithPath === undefined,
          };
        }
        case "locked": {
          return {
            ...baseModularFolderHistoryEntry,
            type: "locked",
          };
        }
        case "unlocked": {
          return {
            ...baseModularFolderHistoryEntry,
            type: "unlocked",
          };
        }
      }
    },
  );
}

export function firestoreSubscribeToMoldularFolderHistory(
  modularFolderId: string,
  callback: (
    modularFolderHistoryEntries: FirestoreModularFolderHistoryEntry[],
  ) => void,
): () => void {
  const query: FirestoreTypes.Query = firestore()
    .collection("modularFolderHistory-1n")
    .where("modularFolderId", "==", modularFolderId);

  const unsubscribe = onSnapshotQuery(
    "subscribeToMoldularFolderHistory",
    query,
    (snapshot) => {
      const firestoreModularFolderHistoryEntries = snapshot.docs.map((doc) => {
        return doc.data() as FirestoreModularFolderHistoryEntry;
      });

      callback(firestoreModularFolderHistoryEntries);
    },
  );
  return unsubscribe;
}
