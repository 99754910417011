import { getTaskManagerFromOfflineFeature } from "@kraaft/shared/core/utils/optimistic/newOptimistic/createOfflineFeature";

export async function getRegisteredTaskManagers() {
  return getTaskManagerFromOfflineFeature([
    (await import("@kraaft/shared/core/modules/dummy/dummy.offline"))
      .DummyOfflineFeature,
    (
      await import(
        "@kraaft/shared/core/modules/modularFolder/modularFolder.offlineFeature"
      )
    ).ModularFolderOfflineFeature,
    (await import("@kraaft/shared/core/modules/schema/schema.offlineFeature"))
      .SchemaOfflineFeature,
  ]);
}
