import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";

import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { closePreview } from "@kraaft/shared/core/modules/preview/previewActions";
import { selectPreviewParams } from "@kraaft/shared/core/modules/preview/previewSelectors";
import { Color, Radius, Sheet, Spacing } from "@kraaft/ui";
import { ConversationPreview } from "@kraaft/web/src/components/previewPopup/previews/conversationPreview";
import { ModularFolderPreview } from "@kraaft/web/src/components/previewPopup/previews/modularFolderPreview";

const INNER_HEIGHT_PERCENT = 85;
const INNER_WIDTH_PERCENT = 92.5;

interface PreviewPopupProps {
  content: React.ReactNode;
}

const PreviewPopupSheet = Sheet({
  default: "overlay",
}).create<PreviewPopupProps>(({ Content }) => ({ content, onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Content>
      <div className={classes.container}>
        <div className={classes.topRightContainer}>
          <HeaderIconButton
            style={{ backgroundColor: Color.WHITE }}
            accessibilityLabel={t("close")}
            icon="x-close"
            onPress={onClose}
            size="SMALL"
          />
        </div>
        <div className={classes.innerContainer}>{content}</div>
      </div>
    </Content>
  );
});

const useStyles = makeStyles({
  container: {
    flex: 1,
    position: "relative",
    display: "flex",
    maxWidth: "100%",
    maxHeight: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",

    transform: `translateY(-${(100 - INNER_HEIGHT_PERCENT) / 4}%)`,
  },
  innerContainer: {
    display: "flex",
    width: `${INNER_WIDTH_PERCENT}%`,
    height: `${INNER_HEIGHT_PERCENT}%`,
    borderRadius: Radius.MEDIUM,
    overflow: "hidden",
  },
  topRightContainer: {
    alignSelf: "flex-end",
    display: "flex",
    alignItems: "flex-end",
    padding: Spacing.S4,
    boxSizing: "border-box",
    width: `${(100 - INNER_WIDTH_PERCENT) / 2}%`,
    height: `${(100 - INNER_HEIGHT_PERCENT) / 2}%`,
  },
});

export const PreviewPopupRenderer = () => {
  const dispatch = useDispatch();
  const previewParams = useSelector(selectPreviewParams);

  const handleClose = useCallback(() => {
    dispatch(closePreview());
  }, [dispatch]);

  const previewContent = useMemo(() => {
    if (!previewParams.isOpen) {
      return null;
    }

    switch (previewParams.type) {
      case "room":
        return (
          <ConversationPreview
            roomId={previewParams.roomId}
            onClose={handleClose}
          />
        );
      case "modularFolder":
        return <ModularFolderPreview folderId={previewParams.folderId} />;
    }
  }, [previewParams, handleClose]);

  const { element } = PreviewPopupSheet.use({
    open: previewParams.isOpen,
    onClose: handleClose,
    content: previewContent,
  });

  return element;
};
