import { makeStyles } from "@mui/styles";

import { ColorStyle, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  emptyState: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  leftArrow: {
    position: "absolute",
    left: Spacing.S40,
    width: `calc(50% - ${150 + Spacing.S40}px)`,
    maxWidth: 400,
  },
  headerContainer: {
    margin: Spacing.S24,
    marginBottom: Spacing.S16,
  },
  databaseSubHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",

    margin: "0 16px",

    borderBottomColor: ColorStyle.SEPARATOR,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
  },
  filterButton: {
    marginLeft: "auto",
    marginBottom: 4,
  },

  viewerContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    paddingLeft: 40,
    paddingRight: Spacing.S24,

    overflow: "hidden",
  },
});
