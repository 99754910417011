import React, { type PropsWithChildren, useMemo } from "react";
import { StyleSheet, Text as RNText, type TextStyle } from "react-native";

import {
  getColorFromColorValue,
  getFontSizeFromSizeValue,
  webOnlyStyle,
} from "../../utils";
import type { TextProps } from "./text.types";
import { getFontFamilyFromWeight } from "./text.utils";

export const Text = React.forwardRef<RNText, PropsWithChildren<TextProps>>(
  (
    {
      id,
      children,
      weight = "regular",
      color = "FONT_HIGH_EMPHASIS",
      size,
      numberOfLines,
      ellipsizeMode,
      selectable,
      style,
      onPress,
      allowFontScaling,
      onLayout,
      breakWords,
    },
    forwardedRef,
  ) => {
    const textStyle = useMemo(() => {
      const _textStyle: TextStyle = {};

      if (weight !== undefined) {
        _textStyle.fontFamily = getFontFamilyFromWeight(weight);
      }
      if (color !== undefined) {
        _textStyle.color = getColorFromColorValue(color);
      }
      if (size !== undefined) {
        _textStyle.fontSize = getFontSizeFromSizeValue(size);
      }

      return _textStyle;
    }, [weight, color, size]);

    return (
      <RNText
        ref={forwardedRef}
        nativeID={id}
        style={[textStyle, breakWords && styles.breakWords, style]}
        numberOfLines={numberOfLines}
        ellipsizeMode={ellipsizeMode}
        selectable={selectable}
        onPress={onPress}
        allowFontScaling={allowFontScaling}
        onLayout={onLayout}
      >
        {children}
      </RNText>
    );
  },
);

const styles = StyleSheet.create({
  breakWords: {
    ...webOnlyStyle({ wordBreak: "break-word" }),
  },
});
