import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet } from "react-native";

import { EmptySignature } from "@kraaft/shared/components/modular/details/editors/signatureEditor/emptySignature";
import { SignaturePreview } from "@kraaft/shared/components/modular/details/editors/signatureEditor/signaturePreview";
import { EditorProps } from "@kraaft/shared/components/modular/details/editors/types";
import { SignaturePanel } from "@kraaft/shared/components/signature/signaturePanel";
import { SlidePanel } from "@kraaft/shared/components/slidePanel";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Button, ColorStyle, PixelSize, Radius, Spacing } from "@kraaft/ui";

type Props = EditorProps<KColumnType.signature>;

const SignatureEditor = ({
  editor: {
    label,
    disabled,
    value,
    setValue,
    sectionLockInfo,
    context: { portalHostname },
  },
  column,
  testID,
}: Props) => {
  const { t } = useTranslation();

  const [isSignatureOpened, openSignature, closeSignature] = useBooleanState();

  const handleChange = useCallback(
    (file: ModernFile<LocalPath> | undefined) => {
      setValue(file);
      closeSignature();
    },
    [closeSignature, setValue],
  );

  const canBeOpened = !sectionLockInfo.isLocked || !!value;

  return (
    <>
      <Pressable
        style={styles.container}
        onPress={canBeOpened ? openSignature : undefined}
        accessibilityLabel={label}
        focusable={false}
      >
        {value ? (
          <SignaturePreview
            attachment={value}
            onSignaturePress={openSignature}
          />
        ) : (
          <EmptySignature />
        )}
      </Pressable>
      {value === undefined && !sectionLockInfo.isLocked ? (
        <Button
          id={`${testID}-${column.key}`}
          icon="edit-04"
          text={t("modularity.add")}
          onPress={openSignature}
          disabled={disabled}
          variant="QUATERNARY"
          alignContent="left"
        />
      ) : null}
      <SlidePanel
        name="SignatureEditor"
        portalHostname={portalHostname}
        open={isSignatureOpened}
        onClose={closeSignature}
        title={column.name}
      >
        <SignaturePanel
          onChange={handleChange}
          attachment={value}
          sectionLockInfo={sectionLockInfo}
        />
      </SlidePanel>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    borderRadius: Radius.SMALL,
    padding: Spacing.S8,
    minHeight: 65,
  },

  emptyStateContainer: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  emptyStateImage: {
    height: PixelSize.S32,
    aspectRatio: 108 / 27,
  },
});

export { SignatureEditor };
