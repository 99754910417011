import { call, delay, put, spawn, takeEvery } from "typed-redux-saga/macro";

import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { navigationService } from "@kraaft/shared/core/services/navigation/provider";

import { subscribeToLibrarySchemasSaga } from "./sagas/subscribeToLibrarySchemas";
import * as actions from "./librarySchema.actions";

export function* librarySchemaSagas() {
  yield* spawn(subscribeToLibrarySchemasSaga);

  yield* takeEvery(
    actions.LibrarySchemaActions.addLibrarySchema,
    addLibrarySchemaSaga,
  );

  yield* takeEvery(
    actions.LibrarySchemaActions.duplicateLibrarySchema,
    duplicateLibrarySchemaSaga,
  );

  yield* takeEvery(
    actions.LibrarySchemaActions.copySchemaInLibrary,
    copySchemaInLibrarySaga,
  );

  yield* takeEvery(
    actions.LibrarySchemaActions.instantiateLibrarySchema,
    useLibrarySchemaSaga,
  );
}

function* addLibrarySchemaSaga(
  action: ReturnType<typeof actions.LibrarySchemaActions.addLibrarySchema>,
) {
  const { payload, meta } = action;

  try {
    const response = yield* call(Api.addLibrarySchema, {
      language: payload.language,
      name: payload.name,
      icon: payload.icon,
    });

    yield* put(showSuccess({ title: i18n.t("schemaLibrary.addedSuccess") }));
    yield* put(actions.LibrarySchemaActions.addLibrarySchemaSuccess(meta));

    yield* call(() => {
      navigationService.navigate("LibrarySchemaBuilder", {
        librarySchemaId: response.librarySchemaId,
      });
    });
  } catch (error) {
    yield* put(showError({ title: i18n.t("schemaLibrary.addedFailure") }));

    yield* put(
      actions.LibrarySchemaActions.addLibrarySchemaFailure(error, meta),
    );
  }
}

function* duplicateLibrarySchemaSaga(
  action: ReturnType<
    typeof actions.LibrarySchemaActions.duplicateLibrarySchema
  >,
) {
  const { payload, meta } = action;

  try {
    yield* call(Api.duplicateLibrarySchema, {
      librarySchemaId: payload.librarySchemaId,
      targetLanguage: payload.targetLanguage,
    });

    yield* put(
      showSuccess({ title: i18n.t("schemaLibrary.duplicatedSuccess") }),
    );
    yield* put(
      actions.LibrarySchemaActions.duplicateLibrarySchemaSuccess(meta),
    );
  } catch (error) {
    yield* put(showError({ title: i18n.t("schemaLibrary.duplicatedFailure") }));

    yield* put(
      actions.LibrarySchemaActions.duplicateLibrarySchemaFailure(error, meta),
    );
  }
}

function* copySchemaInLibrarySaga(
  action: ReturnType<typeof actions.LibrarySchemaActions.copySchemaInLibrary>,
) {
  const { payload, meta } = action;

  try {
    yield* call(Api.copySchemaInLibrary, {
      schemaId: payload.schemaId,
    });

    yield* put(
      showSuccess({ title: i18n.t("schemaLibrary.duplicatedSuccess") }),
    );
    yield* put(actions.LibrarySchemaActions.copySchemaInLibrarySuccess(meta));
  } catch (error) {
    yield* put(showError({ title: i18n.t("schemaLibrary.duplicatedFailure") }));

    yield* put(
      actions.LibrarySchemaActions.copySchemaInLibraryFailure(error, meta),
    );
  }
}

function* useLibrarySchemaSaga(
  action: ReturnType<
    typeof actions.LibrarySchemaActions.instantiateLibrarySchema
  >,
) {
  const { payload, meta } = action;

  try {
    const response = yield* call(Api.useLibrarySchema, {
      librarySchemaId: payload.librarySchemaId,
      poolId: payload.poolId,
    });

    yield* delay(1000);

    yield* put(
      showSuccess({ title: i18n.t("schemaLibrary.useSchemaSuccess") }),
    );
    yield* put(
      actions.LibrarySchemaActions.instantiateLibrarySchemaSuccess(meta),
    );

    yield* call(() => {
      navigationService.navigate("FolderSchemaBuilder", {
        schemaId: response.schemaId,
      });
    });
  } catch (error) {
    yield* put(showError({ title: i18n.t("schemaLibrary.useSchemaFailure") }));

    yield* put(
      actions.LibrarySchemaActions.instantiateLibrarySchemaFailure(error, meta),
    );
  }
}
