import { spawn } from "typed-redux-saga/macro";

import { loadMoreRoomCardsSaga } from "@kraaft/shared/core/modules/roomCard/sagas/loadMoreRoomCards";
import { onSwitchPoolSaga } from "@kraaft/shared/core/modules/roomCard/sagas/onSwitchPool";
import { roomJoinedSaga } from "@kraaft/shared/core/modules/roomCard/sagas/roomJoined";
import { roomLeftSaga } from "@kraaft/shared/core/modules/roomCard/sagas/roomLeft";
import { subscribeToPinnedRoomCardsSaga } from "@kraaft/shared/core/modules/roomCard/sagas/subscribeToPinnedRoomCards";
import { subscribeToRoomCardsSaga } from "@kraaft/shared/core/modules/roomCard/sagas/subscribeToRoomCards";
import { subscribeToRoomMemberCardSaga } from "@kraaft/shared/core/modules/roomCard/sagas/subscribeToRoomMemberCard";
import { updateSearchTextSaga } from "@kraaft/shared/core/modules/roomCard/sagas/updateSearchText";

export function* roomCardSaga() {
  yield* spawn(subscribeToRoomCardsSaga);
  yield* spawn(loadMoreRoomCardsSaga);
  yield* spawn(updateSearchTextSaga);
  yield* spawn(roomJoinedSaga);
  yield* spawn(roomLeftSaga);
  yield* spawn(onSwitchPoolSaga);
  yield* spawn(subscribeToPinnedRoomCardsSaga);
  yield* spawn(subscribeToRoomMemberCardSaga);
}
