import { StyleSheet, View } from "react-native";

import { EditorProps } from "@kraaft/shared/components/modular/details/editors/types";
import { formatDateCell } from "@kraaft/shared/components/modular/details/utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { ColorStyle, LabelWithIcon, Spacing } from "@kraaft/ui";

type Props = EditorProps<KColumnType.automatedCreatedAt>;

const AutomatedCreatedAtEditor = (props: Props) => {
  const {
    editor: { label, value, sectionLockInfo },
    column,
  } = props;

  const dateString = formatDateCell(value, column.displayTime);

  return (
    <>
      {dateString !== undefined ? (
        <View style={styles.valueContainer}>
          <LabelWithIcon
            iconName={KSchemaUtils.getIcon(props.column)}
            label={dateString}
            color={ColorStyle.FONT_HIGH_EMPHASIS}
          />
        </View>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  valueContainer: {
    flexDirection: "row",
    paddingHorizontal: Spacing.S4,
    paddingVertical: Spacing.S8,
  },
});

export { AutomatedCreatedAtEditor };
