import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StrictOmit } from "ts-essentials";

import { compactMap } from "@kraaft/helper-functions";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { selectSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { TemporaryWorkflow } from "@kraaft/shared/core/modules/workflows/types";
import {
  BaseTextInputComponent,
  CompactTextInput,
  Suggestion,
  TextInputWithSuggestionHandle,
} from "@kraaft/ui";
import { useWorkflowMentionPrefix } from "@kraaft/web/src/views/settings/workflows/workflowUtils";

const NOT_SELECTABLE_COLUMNS: KColumnType[] = [
  KColumnType.attachment,
  KColumnType.join,
  KColumnType.signature,
];

function createSuggestion(simpleSuggestion: StrictOmit<Suggestion, "name">) {
  return {
    name: simpleSuggestion.label,
    ...simpleSuggestion,
  };
}

interface ActionInputProps {
  workflow: TemporaryWorkflow;
  onChange: (newText: string) => void;
  onErrorStateChange?: (newState: boolean) => void;
  defaultValue?: string;
  singleLine?: boolean;
  placeholder?: string;
}

export const ActionInput = ({
  workflow,
  onChange,
  onErrorStateChange,
  defaultValue,
  singleLine,
  placeholder,
}: ActionInputProps) => {
  const inputSuggestionRef = useRef<TextInputWithSuggestionHandle>(null);
  const schema = useSelector(selectSchema(workflow.schemaId));
  const mentionPrefix = useWorkflowMentionPrefix(workflow.schemaId);
  const { t } = useTranslation();

  const getPlaceholderFromType = useCallback(
    (column: KSchemaColumn) => {
      const placeholders: Record<
        Exclude<KColumnType, KColumnType.join>,
        string
      > = {
        attachment: t("attachmentChoice"),
        checkbox: t("checkbox"),
        currency: t("currency"),
        date: t("date"),
        geolocation: t("geolocationChoice"),
        longText: t("longText"),
        number: t("number"),
        roomName: t("roomPropertyChoice"),
        roomMembers: t("roomPropertyChoice"),
        selectSingle: t("uniqueChoice"),
        selectMultiple: t("multipleChoice"),
        shortText: t("shortText"),
        user: t("userChoice"),
        signature: t("signature.word"),
        automatedAutoIncrement: t("shortText"),
        automatedCreatedAt: t("date"),
        automatedCreatedBy: t("userChoice"),
      };

      return placeholders[column.type as keyof typeof placeholders] ?? "";
    },
    [t],
  );

  const suggestions = useMemo<Suggestion[]>(() => {
    const baseSuggestions: Suggestion[] = [
      createSuggestion({
        label: `[${t("workflow.suggestions.basePrefix")}] ${t(
          "workflow.suggestions.initiator.label",
        )}`,
        value: "workflowInitiator",
        placeholder: t("workflow.suggestions.initiator.placeholder"),
      }),
    ];

    if (schema) {
      return [
        ...baseSuggestions,
        ...compactMap(
          [...KSchemaUtils.iterateColumns(schema.rootSection)],
          (column) =>
            !NOT_SELECTABLE_COLUMNS.includes(column.type)
              ? createSuggestion({
                  label: `${mentionPrefix} ${column.name}`,
                  value: column.key,
                  placeholder: getPlaceholderFromType(column),
                })
              : undefined,
        ),
      ];
    }
    return baseSuggestions;
  }, [t, schema, mentionPrefix, getPlaceholderFromType]);

  const handleContentChange = useCallback(
    (formattedText: string) => {
      onChange(formattedText);
    },
    [onChange],
  );

  return (
    <CompactTextInput
      ref={inputSuggestionRef}
      TextInputComponent={BaseTextInputComponent.TextInputWithSuggestion}
      value={defaultValue}
      onChangeText={handleContentChange}
      placeholder={placeholder}
      extraTextInputProps={{
        suggestions,
        onErrorStateChange,
        multiline: !singleLine,
      }}
    />
  );
};
