import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { optimisticDuplicateSchemaElement } from "@kraaft/shared/core/modules/schema/schema.optimisticHelper";
import { Api } from "@kraaft/shared/core/services/api";
import { HttpError } from "@kraaft/shared/core/services/firebase/httpError";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { getStore } from "@kraaft/shared/core/store";
import { EditOneOperationBuilder } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations/editOne.operation";

export const duplicateSchemaElementOperation =
  EditOneOperationBuilder.create<LibrarySchema>()
    .payload<{ key: string }>()
    .expected((aggregate, payload) => {
      optimisticDuplicateSchemaElement(aggregate.schema, payload);
      return aggregate;
    })
    .mutate(async (payload) => {
      try {
        const { updatedAt } = await Api.duplicateLibrarySchemaElement({
          schemaId: payload.id,
          key: payload.key,
        });
        return { updatedAt };
      } catch (e) {
        if (HttpError.isHttpErrorWithCode(e, "MaxColumnCountExceededError")) {
          getStore().dispatch(
            showError({
              title: i18n.t("formBuilder.schema.error.maxColumnCountExceeded"),
            }),
          );
        }
        throw e;
      }
    });
