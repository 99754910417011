import { FIRESTORE_VERBOSE } from "@kraaft/shared/constants/global";
import {
  DocumentReference,
  DocumentSnapshot,
  FirestoreError,
  onSnapshot,
} from "@kraaft/shared/core/services/firebase/modularQuery";

export function onSnapshotDocument<T extends DocumentReference>(
  message: string,
  documentReference: T,
  onNext: (snapshot: DocumentSnapshot) => void,
  onError?: (error: FirestoreError) => void,
  shouldUpdateOnMetadataUpdate?: boolean,
) {
  if (FIRESTORE_VERBOSE) {
    console.log("subscribing to", message);
  }

  const unsubscribe = onSnapshot(
    documentReference,
    {
      includeMetadataChanges: shouldUpdateOnMetadataUpdate ?? false,
    },
    {
      next: (snapshot: DocumentSnapshot) => {
        if (snapshot) {
          if (FIRESTORE_VERBOSE) {
            console.log(
              `snapshot received for ${message}: cache=${snapshot.metadata.fromCache}`,
            );
          }
          onNext(snapshot);
        } else {
          if (FIRESTORE_VERBOSE) {
            console.log(`snapshot received for ${message}: ${snapshot}`);
          }
        }
      },

      error: (error: FirestoreError) => {
        console.warn(`snapshot error for ${message}`, error);
        onError?.(error);
      },
    },
  );

  return () => {
    if (FIRESTORE_VERBOSE) {
      console.log("unsubscribing to", message);
    }
    unsubscribe();
  };
}
