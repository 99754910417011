import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import {
  KSchema,
  KSchemaColumn,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { SchemaStateActions } from "@kraaft/shared/core/modules/schema/schema.actions";
import { HoverState } from "@kraaft/shared/core/modules/schema/schema.offline";
import { optimisticSchemaAddColumn } from "@kraaft/shared/core/modules/schema/schema.optimisticHelper";
import { Api } from "@kraaft/shared/core/services/api";
import { HttpError } from "@kraaft/shared/core/services/firebase/httpError";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { getStore } from "@kraaft/shared/core/store";
import { Operation } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations";

export const addColumnOperation = Operation.editOne<KSchema>()
  .payload<{
    column: KSchemaColumn;
    placement: HoverState;
    index: number;
    containerKey: string;
  }>()
  .expected((aggregate, payload) => {
    optimisticSchemaAddColumn(aggregate, {
      column: { ...payload.column },
      containerKey: payload.containerKey,
      placement: payload.placement,
    });
    return aggregate;
  })
  .mutate(async (payload) => {
    try {
      const { updatedAt, columnKey } = await Api.addSchemaColumn({
        schemaId: payload.id,
        column: {
          name: payload.column.name,
          definition: payload.column,
          index: payload.index,
          containerKey: payload.containerKey,
        },
      });

      getStore().dispatch(
        SchemaStateActions.setCreatedKey({
          schemaId: payload.id,
          columnKey: columnKey,
        }),
      );

      return { updatedAt };
    } catch (e) {
      if (HttpError.isHttpErrorWithCode(e, "MaxColumnCountExceededError")) {
        getStore().dispatch(
          showError({
            title: i18n.t("formBuilder.schema.error.maxColumnCountExceeded"),
          }),
        );
      }
      throw e;
    }
  });
