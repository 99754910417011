import { useEffect } from "react";

import { omit } from "@kraaft/helper-functions";
import { TIMINGS_VERBOSE } from "@kraaft/shared/constants/global";
import { PerformanceSpanType } from "@kraaft/shared/core/utils/tracking/performanceSpans";

import { trackEvent } from "./trackEvent";

type TrackPrimitive = number | string | undefined;

const spans: Record<string, { startedAt: number }> = {};

type PerformanceSpanArgument =
  | PerformanceSpanType
  | [PerformanceSpanType, ...string[]];

function getPerformanceSpanType(arg: PerformanceSpanArgument) {
  if (Array.isArray(arg)) {
    return arg[0];
  }
  return arg;
}

function getPerformanceSpanLabel(arg: PerformanceSpanArgument) {
  if (Array.isArray(arg)) {
    return arg.join("-");
  }
  return arg;
}

export class Timings {
  static manuallyRegisterSpan(
    span: PerformanceSpanArgument,
    durationMs: number,
    arg?: Record<string, TrackPrimitive>,
  ) {
    const performanceLabel = getPerformanceSpanLabel(span);
    delete spans[performanceLabel];
    const trackObject = {
      ...(arg as any),
      eventName: "Performance span",
      span_type: getPerformanceSpanType(span),
      duration_ms: durationMs,
    };
    if (TIMINGS_VERBOSE) {
      console.log(
        `-- Span ${getPerformanceSpanLabel(
          span,
        )}: ${durationMs} ${JSON.stringify(
          omit(trackObject, ["eventName", "span_type", "duration_ms"]),
        )}`,
      );
    }
    trackEvent(trackObject);
  }

  static startSpan(
    span: PerformanceSpanArgument,
    arg?: Record<string, TrackPrimitive>,
  ) {
    spans[getPerformanceSpanLabel(span)] = { startedAt: Date.now(), ...arg };
  }

  static stopSpan(
    span: PerformanceSpanArgument,
    arg?: Record<string, TrackPrimitive>,
  ) {
    const existingSpan = spans[getPerformanceSpanLabel(span)];
    if (!existingSpan) {
      return;
    }
    const elapsedMs = Date.now() - existingSpan.startedAt;
    this.manuallyRegisterSpan(span, elapsedMs, { ...existingSpan, ...arg });
  }

  static startSpanAndExecute(
    span: PerformanceSpanArgument,
    fn: () => void,
    arg?: Record<string, TrackPrimitive>,
  ) {
    this.startSpan(span, arg);
    setTimeout(fn, 0);
  }

  static useStopSpanAfterRender(span: PerformanceSpanArgument) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      this.stopSpan(span);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPerformanceSpanLabel(span)]);
  }

  static useManualSpanAfterRender(
    span: PerformanceSpanArgument,
    startedAt: number,
  ) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      this.manuallyRegisterSpan(span, Date.now() - startedAt);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  }
}
