import { createSelector } from "@reduxjs/toolkit";
import { groupBy, memoize, pickBy } from "lodash";

import { compactMap } from "@kraaft/helper-functions";
import { getModularFolderIconWithColor } from "@kraaft/shared/components/modularFolderList/modularFolderItem/modularFolderItemUtils";
import {
  getGeolocatedModularFolders,
  getIndexedGeolocatedModularFolderCarouselItems,
  getModularFolderAttachmentColumn,
  getModularFolderAttachments,
  getModularFolderGeolocation,
  getModularFolderHighlighedCheckboxValue,
  getModularFolderSummary,
  modularFolderHasGeolocation,
} from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import {
  Attachment,
  AttachmentType,
  IndexedAttachmentWithGPS,
} from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { ModularFolderCarouselItem } from "@kraaft/shared/core/modules/modularFolder/components/modularFolderCarousel/modularFolderCarousel.types";
import { OfflineModularFolderSelectors } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { orderFolders } from "@kraaft/shared/core/modules/modularFolder/modularFolderUtils";
import {
  ModularFolderVisibility,
  ModularFolderVisibilityType,
} from "@kraaft/shared/core/modules/modularFolder/types";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  ModularFolder,
  SimplifiedModularFolder,
} from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";
import { OfflineSchemaSelectors } from "@kraaft/shared/core/modules/schema/schema.offline";
import {
  selectFolderSchema,
  selectFolderSchemas,
} from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { RootState } from "@kraaft/shared/core/store";
import {
  getEmptyArray,
  lodashKeyResolver,
} from "@kraaft/shared/core/utils/utils";

export const selectModularFolders = ({
  modularFolders: { modularFolders },
}: RootState) => modularFolders;

export const selectPristineModularFolder = memoize((modularFolderId: string) =>
  createSelector(selectModularFolders, (folders) => folders[modularFolderId]),
);

const selectModularFoldersByVisibility = ({ modularFolders }: RootState) =>
  modularFolders.folderByVisibility;

export const selectModularFolderCarousel = ({ modularFolders }: RootState) =>
  modularFolders.carousel;

export const selectModularFoldersWithSchemaIdAndVisibility = memoize(
  ({
    visibilities,
    schemaId,
  }: {
    visibilities: ModularFolderVisibility[];
    schemaId: string | undefined;
  }) =>
    createSelector(
      _selectModularFolders(visibilities),
      ({ isLoaded, folders }) => {
        const filteredFolders = pickBy(
          folders,
          (modularFolder) => !schemaId || modularFolder.schemaId === schemaId,
        );
        return {
          isLoaded,
          folders: filteredFolders,
        };
      },
    ),
  lodashKeyResolver,
);

export const selectOrderedModularFoldersWithSchemaIdAndVisibility = memoize(
  ({
    visibilities,
    schemaId,
  }: {
    visibilities: ModularFolderVisibility[];
    schemaId: string | undefined;
  }) =>
    createSelector(
      selectModularFoldersWithSchemaIdAndVisibility({ visibilities, schemaId }),
      ({ isLoaded, folders }) => ({
        isLoaded,
        folders: orderFolders(folders),
      }),
    ),
  lodashKeyResolver,
);

export const selectModularFoldersWithRoomIdAndSchemaId = memoize(
  ({ roomId, schemaId }: { roomId: string; schemaId: string | undefined }) =>
    selectModularFoldersWithSchemaIdAndVisibility({
      visibilities: [{ type: ModularFolderVisibilityType.Room, roomId }],
      schemaId,
    }),
  lodashKeyResolver,
);

export const selectOrderedModularFoldersWithRoomIdAndSchemaId = memoize(
  ({ roomId, schemaId }: { roomId: string; schemaId: string | undefined }) =>
    createSelector(
      selectModularFoldersWithRoomIdAndSchemaId({ roomId, schemaId }),
      ({ isLoaded, folders }) => ({
        isLoaded,
        folders: orderFolders(folders),
      }),
    ),
  lodashKeyResolver,
);

export const selectGeolocatedModularFoldersWithRoomIdAndSchemaId = memoize(
  ({ roomId, schemaId }: { roomId: string; schemaId: string | undefined }) =>
    createSelector(
      selectModularFoldersWithRoomIdAndSchemaId({ roomId, schemaId }),
      ({ folders }) => {
        return getGeolocatedModularFolders(orderFolders(folders));
      },
    ),
  lodashKeyResolver,
);

export const selectModularFolderCountForSchemaId = memoize((schemaId: string) =>
  createSelector(
    OfflineModularFolderSelectors.selectAll,
    (folders) =>
      Object.values(folders).filter((folder) => folder.schemaId === schemaId)
        .length,
  ),
);

export const selectModularFoldersBySchemaId = memoize((roomId: string) =>
  createSelector(
    _selectModularFolders([{ type: ModularFolderVisibilityType.Room, roomId }]),
    ({ isLoaded, folders }) => ({
      isLoaded,
      folders: groupBy(folders, (folder) => folder.schemaId),
    }),
  ),
);

const EMPTY_OBJECT: Record<string, ModularFolder> = {};
const _selectModularFolders = memoize(
  (visibilities: ModularFolderVisibility[]) =>
    createSelector(
      OfflineModularFolderSelectors.selectDetails,
      selectModularFoldersByVisibility,
      ({ state: folders, created }, folderByVisibilty) => {
        function mapFolders(ids: string[] | undefined) {
          if (ids === undefined || ids.length === 0) {
            return EMPTY_OBJECT;
          }
          return ids.reduce<Record<string, ModularFolder>>((_folders, id) => {
            const folder = folders[id];

            if (folder !== undefined) {
              _folders[id] = folder;
            }

            return _folders;
          }, {});
        }

        const data = compactMap(visibilities, (visibility) => {
          switch (visibility.type) {
            case ModularFolderVisibilityType.User: {
              return {
                isLoaded: Boolean(folderByVisibilty.userVisible),
                folders: {
                  ...mapFolders(folderByVisibilty.userVisible),
                  ...created,
                },
              };
            }
            case ModularFolderVisibilityType.Room: {
              return visibility.roomId
                ? {
                    isLoaded: Boolean(
                      folderByVisibilty.roomVisible[visibility.roomId],
                    ),
                    folders: {
                      ...mapFolders(
                        folderByVisibilty.roomVisible[visibility.roomId],
                      ),
                      ...pickBy(
                        created,
                        (modularFolder) =>
                          modularFolder.roomId === visibility.roomId,
                      ),
                    },
                  }
                : undefined;
            }
            case ModularFolderVisibilityType.Pool: {
              return visibility.poolId
                ? {
                    isLoaded: Boolean(
                      folderByVisibilty.poolVisible[visibility.poolId],
                    ),
                    folders: {
                      ...mapFolders(
                        folderByVisibilty.poolVisible[visibility.poolId],
                      ),
                      ...pickBy(
                        created,
                        (modularFolder) =>
                          modularFolder.poolId === visibility.poolId,
                      ),
                    },
                  }
                : undefined;
            }
          }
        });
        const isLoaded = data.every((item) => item.isLoaded);

        const compactedFolders = data.reduce<Record<string, ModularFolder>>(
          (_folders, item) => {
            for (const [key, value] of Object.entries(item.folders)) {
              _folders[key] = value;
            }
            return _folders;
          },
          {},
        );

        return {
          isLoaded,
          folders:
            Object.keys(compactedFolders).length > 0
              ? compactedFolders
              : EMPTY_OBJECT,
        };
      },
    ),
  lodashKeyResolver,
);

export const selectModularFolderWithCarousel = createSelector(
  [selectModularFolderCarousel, OfflineModularFolderSelectors.selectAll],
  (carousel, folders): SimplifiedModularFolder | ModularFolder | undefined => {
    if (!carousel?.show) {
      return undefined;
    }
    return folders[carousel.folderId];
  },
);

export const selectModularFolderCarouselData = createSelector(
  [
    selectModularFolderCarousel,
    selectModularFolderWithCarousel,
    OfflineSchemaSelectors.selectAll, // TODO improve this
  ],
  (carousel, modularFolder, schemas) => {
    if (!carousel?.show) {
      console.warn("This should not be called if carousel is closed");
      return undefined;
    }
    if (!modularFolder) {
      return undefined;
    }
    const { column } = carousel;

    const modularFolderAttachments = getModularFolderAttachmentColumn(
      modularFolder,
      column?.key,
      ["image", "video"],
    );

    const schema = schemas[modularFolder.schemaId];
    let isLocked: boolean;

    if (column && schema) {
      isLocked =
        column && schema
          ? ModularRecordUtils.getSectionLockInfo(
              schema.rootSection,
              modularFolder.properties,
              KSchemaUtils.getParentSectionKey(
                schema.rootSection,
                column.key,
              ) ?? "",
            ).isLocked
          : false;
    } else {
      isLocked = false;
    }

    const attachments = modularFolderAttachments.map<ModularFolderCarouselItem>(
      (attachment) => ({
        attachment,
        isLocked,
      }),
    );

    return {
      modularFolder,
      initialIndex: carousel.initialIndex,
      folderId: carousel.folderId,
      column: carousel.column,
      attachments,
    };
  },
);

export const selectModularFolderRoomLoadStatus = memoize(
  (roomId: string) =>
    ({ modularFolders }: RootState) =>
      modularFolders.roomLoadStatus[roomId] ?? "none",
);

export const selectModularFolderTitle = memoize((modularFolderId: string) =>
  createSelector(
    OfflineModularFolderSelectors.select(modularFolderId),
    (folder) =>
      ModularRecordUtils.getRecordField(
        folder,
        KSchemaRemarkableColumns.TITLE,
        [KColumnType.shortText, KColumnType.automatedAutoIncrement],
        "",
      ),
  ),
);

export const selectModularFolderPendingOperations = memoize(
  (modularFolderId: string) =>
    createSelector(
      OfflineModularFolderSelectors.select(modularFolderId),
      (folder) => folder?.pendingOptimisticOperations ?? 0,
    ),
);

export const selectModularFolderExists = memoize((modularFolderId: string) =>
  createSelector(
    OfflineModularFolderSelectors.select(modularFolderId),
    (folder) => Boolean(folder),
  ),
);

export const selectModularFolderProperties = memoize(
  (modularFolderId: string | undefined) =>
    createSelector(
      OfflineModularFolderSelectors.select(modularFolderId ?? ""),
      (folder) => folder?.properties,
    ),
);

export const selectModularFolderIconAndColor = memoize(
  (modularFolderId: string) =>
    createSelector(
      OfflineModularFolderSelectors.select(modularFolderId),
      selectFolderSchemas,
      (folder, schemas) => {
        const schema = folder ? schemas[folder.schemaId] : undefined;
        return getModularFolderIconWithColor(schema, folder);
      },
    ),
);

export const selectModularFolderCompletedValue = memoize(
  (modularFolderId: string | undefined) =>
    createSelector(
      OfflineModularFolderSelectors.select(modularFolderId ?? ""),
      selectFolderSchemas,
      (folder, schemas) => {
        if (!folder) {
          return false;
        }
        const schema = schemas[folder.schemaId];
        if (!schema) {
          return false;
        }
        return getModularFolderHighlighedCheckboxValue(
          schema.highlightedCheckbox,
          folder,
        )?.value;
      },
    ),
);

export const selectModularFolderFirstValidAttachmentColumn = memoize(
  (modularFolderId: string) =>
    createSelector(
      OfflineModularFolderSelectors.select(modularFolderId),
      selectFolderSchemas,
      (folder, schemas) => {
        if (!folder) {
          return undefined;
        }
        const schema = schemas[folder.schemaId];
        if (!schema) {
          return undefined;
        }
        return ModularRecordUtils.getValidAttachmentColumn(
          folder?.properties,
          schema,
        );
      },
    ),
);

export const selectModularFolderAttachments = memoize(
  (modularFolderId: string, types: readonly AttachmentType[]) =>
    createSelector(
      OfflineModularFolderSelectors.select(modularFolderId),
      (folder): Array<Attachment> => {
        if (!folder) {
          return getEmptyArray();
        }
        return getModularFolderAttachments(folder, types);
      },
    ),
  lodashKeyResolver,
);

export const selectModularFolderGeolocatedAttachments = memoize(
  (modularFolderId: string) =>
    createSelector(
      OfflineModularFolderSelectors.select(modularFolderId),
      (folder): Array<IndexedAttachmentWithGPS> => {
        return folder
          ? getIndexedGeolocatedModularFolderCarouselItems(folder)
          : getEmptyArray();
      },
    ),
);

export const selectModularFolderGeolocation = memoize(
  (modularFolderId: string) =>
    createSelector(
      OfflineModularFolderSelectors.select(modularFolderId),
      (folder) => {
        if (!folder || !modularFolderHasGeolocation(folder)) {
          return undefined;
        }
        return getModularFolderGeolocation(folder);
      },
    ),
);

export const selectModularFolderHasAtLeastOneLockedColumn = memoize(
  (modularFolderId: string) =>
    createSelector(
      OfflineModularFolderSelectors.select(modularFolderId),
      selectFolderSchemas,
      (folder, schemas) => {
        if (!folder) {
          return false;
        }
        const schema = schemas[folder.schemaId];
        return ModularRecordUtils.hasAtLeastOneLockedColumn(
          folder.properties,
          schema,
        );
      },
    ),
);

export const selectModularFoldersSummary = memoize(
  (roomId: string, schemaId: string) =>
    createSelector(
      selectModularFoldersWithRoomIdAndSchemaId({ roomId, schemaId }),
      selectFolderSchema(schemaId),
      (folders, schema) => {
        return schema
          ? `${schema.name} (${getModularFolderSummary(
              schema.highlightedCheckbox,
              Object.values(folders.folders),
            )})`
          : "";
      },
    ),
  lodashKeyResolver,
);

export const selectModularFolderSchemaId = memoize(
  (modularFolderId: string | undefined) =>
    createSelector(
      OfflineModularFolderSelectors.select(modularFolderId ?? ""),
      (folder) => {
        if (!folder) {
          return undefined;
        }
        return folder.schemaId;
      },
    ),
);
