import { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RoomFiltersValues } from "@kraaft/shared/components/filters/roomFilters.context";
import { RoomFiltersSheet } from "@kraaft/shared/components/filters/roomFilters.sheet";
import { RoomFiltersSheetComposition } from "@kraaft/shared/components/filters/roomFilters.sheetComposition";
import {
  HeaderIconButton,
  HeaderIconButtonProps,
} from "@kraaft/shared/components/headerIconButton";
import { FilterStateActions } from "@kraaft/shared/core/modules/filter/filterActions";
import { DEFAULT_ROOM_FILTERS } from "@kraaft/shared/core/modules/filter/filterReducers";
import {
  selectActiveFiltersCountAndText,
  selectPoolFilterState,
} from "@kraaft/shared/core/modules/filter/filterSelectors";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { TrackableViewProps } from "@kraaft/shared/core/types";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";

export type HeaderFilterButton = Omit<
  HeaderIconButtonProps,
  keyof TrackableViewProps
> & {
  id?: string;
};

const HeaderFilterButton = ({ id, ...props }: HeaderFilterButton) => {
  const dispatch = useDispatch();

  const buttonRef = useRef(null);
  const [isOpen, openRoomFiltersSheet, closeRoomFiltersSheet] =
    useBooleanState(false);

  const poolId = useSelector(selectCurrentPoolId);
  const initialRoomFilters = useSelector(selectPoolFilterState(poolId));

  const { activeFiltersCount, activeFiltersCountAsText } = useSelector(
    selectActiveFiltersCountAndText(poolId),
  );

  const handlePress = useCallback(() => {
    trackEvent({ eventName: "Click On Room Filters" });
    openRoomFiltersSheet();
  }, [openRoomFiltersSheet]);

  const handleFilter = useCallback(
    (newFilters: RoomFiltersValues) => {
      if (poolId === undefined) {
        return;
      }

      trackEvent({
        eventName: "Select Room Filters",
        visibility: newFilters.visibility,
        has_status: Boolean(newFilters.statusId),
        label_count: newFilters.labelIds.length,
        has_responsible: Boolean(newFilters.responsibleId),
        show_archived: Boolean(newFilters.showArchived),
        reading_status: newFilters.readingStatus,
      });

      dispatch(
        FilterStateActions.setRoomFilters({
          poolId,
          visibility: newFilters.visibility,
          statusId: newFilters.statusId,
          labelIds: newFilters.labelIds,
          responsibleId: newFilters.responsibleId,
          showArchived: newFilters.showArchived,
          readingStatus: newFilters.readingStatus,
        }),
      );
    },
    [dispatch, poolId],
  );

  return (
    <>
      <HeaderIconButton
        ref={buttonRef}
        id={id}
        icon="filter-funnel-03"
        accessibilityLabel={activeFiltersCountAsText}
        text={activeFiltersCountAsText}
        onPress={handlePress}
        variant="TERTIARY"
        size="SMALL"
        selected={activeFiltersCount > 0}
        condensed
        {...props}
      />
      {poolId !== undefined ? (
        <RoomFiltersSheet.Host
          open={isOpen}
          onClose={closeRoomFiltersSheet}
          anchor={buttonRef}
          placement="bottom-start"
        >
          <RoomFiltersSheetComposition
            poolId={poolId}
            onFilter={handleFilter}
            initialRoomFilters={initialRoomFilters}
            defaultRoomFilters={DEFAULT_ROOM_FILTERS}
            onClose={closeRoomFiltersSheet}
          />
        </RoomFiltersSheet.Host>
      ) : null}
    </>
  );
};

// biome-ignore lint/style/useExportType: <explanation>
export { HeaderFilterButton };
