import { createAction } from "@reduxjs/toolkit";

import { KSchemaIcon } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";

export const SchemaActions = {
  subscribe: createAction<{ poolId: string }>("@schema/SUBSCRIBE"),
  unsubscribe: createAction<{ poolId: string }>("@schema/UNSUBSCRIBE"),

  createSchema: createAction<{ name: string; icon: KSchemaIcon }>(
    "@schema/CREATE",
  ),
  deleteSchema: createAction<{ id: string }>("@schema/DELETE"),

  reorderSchemas: createAction<{ schemaId: string; index: number }[]>(
    "@schema/REORDER_SCHEMAS",
  ),
};

export type AutoNumberingMode =
  | { mode: "manual" }
  | { mode: "autonumber"; prefix: string };

export const SchemaStateActions = {
  setSchemasLoadedForPoolId: createAction<{ poolId: string }>(
    "@schema/state/SET_SCHEMAS_LOADED_FOR_POOL_ID",
  ),
  setCreatedKey: createAction<{ schemaId: string; columnKey: string }>(
    "@schema/state/SET_CREATED_COLUMN",
  ),
  optimistic: {
    deleteSchema: createAction<{ id: string }>("@schema/state/DELETE"),
    reorderSchemas: createAction<{ schemaId: string; index: number }[]>(
      "@schema/state/REORDER_SCHEMAS",
    ),
  },
};
