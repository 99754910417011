import { createAction } from "@reduxjs/toolkit";

import {
  getAddLibrarySchemaLoaderId,
  getCopySchemaInLibraryLoaderId,
  getDuplicateLibrarySchemaLoaderId,
  getInstantiateLibrarySchemaLoaderId,
} from "@kraaft/shared/core/modules/librarySchema/librarySchema.actions.utils";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import {
  actionUpdateLoaderMetaFailure,
  actionUpdateLoaderMetaSuccess,
  createLoaderMeta,
} from "@kraaft/shared/core/modules/loaders/loaderUtils";
import { KSchemaIcon } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";

import { LibrarySchemaLanguage } from "./librarySchema.state";

export interface ReportTemplateUpdatableValues {
  name?: string;
  enabled?: boolean;
}

export const LibrarySchemaActions = {
  subscribe: createAction("@librarySchema/SUBSCRIBE"),
  unsubscribe: createAction("@librarySchema/UNSUBSCRIBE"),

  addLibrarySchema: createAction(
    "@librarySchema/addLibrarySchema",
    (payload: {
      language: LibrarySchemaLanguage;
      name: string;
      icon: KSchemaIcon;
    }) => ({
      payload,
      meta: createLoaderMeta({
        id: getAddLibrarySchemaLoaderId(),
        status: LoaderStatus.LOADING,
      }),
    }),
  ),
  addLibrarySchemaSuccess: createAction(
    "@librarySchema/addLibrarySchemaSuccess",
    actionUpdateLoaderMetaSuccess,
  ),
  addLibrarySchemaFailure: createAction(
    "@librarySchema/addLibrarySchemaFailure",
    actionUpdateLoaderMetaFailure,
  ),

  duplicateLibrarySchema: createAction(
    "@librarySchema/duplicateLibrarySchema",
    (payload: {
      librarySchemaId: string;
      targetLanguage: LibrarySchemaLanguage;
    }) => ({
      payload,
      meta: createLoaderMeta({
        id: getDuplicateLibrarySchemaLoaderId(payload.librarySchemaId),
        status: LoaderStatus.LOADING,
      }),
    }),
  ),
  duplicateLibrarySchemaSuccess: createAction(
    "@librarySchema/duplicateLibrarySchemaSuccess",
    actionUpdateLoaderMetaSuccess,
  ),
  duplicateLibrarySchemaFailure: createAction(
    "@librarySchema/duplicateLibrarySchemaFailure",
    actionUpdateLoaderMetaFailure,
  ),

  copySchemaInLibrary: createAction(
    "@librarySchema/copySchemaInLibrary",
    (payload: { schemaId: string }) => ({
      payload,
      meta: createLoaderMeta({
        id: getCopySchemaInLibraryLoaderId(payload.schemaId),
        status: LoaderStatus.LOADING,
      }),
    }),
  ),
  copySchemaInLibrarySuccess: createAction(
    "@librarySchema/copySchemaInLibrarySuccess",
    actionUpdateLoaderMetaSuccess,
  ),
  copySchemaInLibraryFailure: createAction(
    "@librarySchema/copySchemaInLibraryFailure",
    actionUpdateLoaderMetaFailure,
  ),

  instantiateLibrarySchema: createAction(
    "@librarySchema/instantiateLibrarySchema",
    (payload: { librarySchemaId: string; poolId: string }) => ({
      payload,
      meta: createLoaderMeta({
        id: getInstantiateLibrarySchemaLoaderId(payload.librarySchemaId),
        status: LoaderStatus.LOADING,
      }),
    }),
  ),
  instantiateLibrarySchemaSuccess: createAction(
    "@librarySchema/instantiateLibrarySchemaSuccess",
    actionUpdateLoaderMetaSuccess,
  ),
  instantiateLibrarySchemaFailure: createAction(
    "@librarySchema/instantiateLibrarySchemaFailure",
    actionUpdateLoaderMetaFailure,
  ),
};

export type LibrarySchemaSource = "public" | "private" | "superadmin";

export const LibrarySchemaStateActions = {
  setCreatedKey: createAction<{ librarySchemaId: string; columnKey: string }>(
    "@librarySchema/state/SET_CREATED_COLUMN",
  ),
};
