import { ReactNode, useCallback, useMemo, useState } from "react";

import {
  RoomFiltersContextProvider,
  RoomFiltersValues,
} from "@kraaft/shared/components/filters/roomFilters.context";
import { RoomFiltersFlowRouteKey } from "@kraaft/shared/components/filters/roomFilters.flow";
import { RoomFiltersSheet } from "@kraaft/shared/components/filters/roomFilters.sheet";
import { RoomFiltersLabels } from "@kraaft/shared/components/filters/roomFiltersLabels";
import { RoomFiltersOverview } from "@kraaft/shared/components/filters/roomFiltersOverview";
import { RoomFiltersReadingStatus } from "@kraaft/shared/components/filters/roomFiltersReadingStatus";
import { RoomFiltersResponsible } from "@kraaft/shared/components/filters/roomFiltersResponsible";
import { RoomFiltersStatus } from "@kraaft/shared/components/filters/roomFiltersStatus";
import { RoomFiltersVisibility } from "@kraaft/shared/components/filters/roomFiltersVisibility";

interface RoomFiltersSheetCompositionProps {
  poolId: string;
  onFilter: (newFilters: RoomFiltersValues) => void;
  initialRoomFilters: RoomFiltersValues;
  defaultRoomFilters: RoomFiltersValues;
  onClose: () => void;
}

export const RoomFiltersSheetComposition = ({
  poolId,
  onFilter,
  initialRoomFilters,
  defaultRoomFilters,
  onClose,
}: RoomFiltersSheetCompositionProps) => {
  const [currentRouteKey, setCurrentRouteKey] =
    useState<RoomFiltersFlowRouteKey>("overview");

  const handleBack = useCallback(() => {
    setCurrentRouteKey("overview");
  }, []);

  const routes = useMemo<Record<RoomFiltersFlowRouteKey, ReactNode>>(
    () => ({
      overview: (
        <RoomFiltersOverview
          onClose={onClose}
          onNavigate={setCurrentRouteKey}
        />
      ),
      visibility: (
        <RoomFiltersVisibility onClose={onClose} onBack={handleBack} />
      ),
      status: <RoomFiltersStatus onClose={onClose} onBack={handleBack} />,
      label: <RoomFiltersLabels onClose={onClose} onBack={handleBack} />,
      responsible: (
        <RoomFiltersResponsible onClose={onClose} onBack={handleBack} />
      ),
      readingStatus: (
        <RoomFiltersReadingStatus onClose={onClose} onBack={handleBack} />
      ),
    }),
    [handleBack, onClose],
  );
  const currentRoute = useMemo(
    () => routes[currentRouteKey],
    [currentRouteKey, routes],
  );

  return (
    <RoomFiltersSheet.Paper width={400}>
      <RoomFiltersContextProvider
        poolId={poolId}
        onFilter={onFilter}
        initialRoomFilters={initialRoomFilters}
        defaultRoomFilters={defaultRoomFilters}
      >
        {currentRoute}
      </RoomFiltersContextProvider>
    </RoomFiltersSheet.Paper>
  );
};
