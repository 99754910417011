import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { addColumnOperation } from "@kraaft/shared/core/modules/schema/operations/addColumn.operation";
import { addSectionOperation } from "@kraaft/shared/core/modules/schema/operations/addSection.operation";
import { deleteOperation } from "@kraaft/shared/core/modules/schema/operations/delete.operation";
import { deleteColumnOperation } from "@kraaft/shared/core/modules/schema/operations/deleteColumn.operation";
import { duplicateElementOperation } from "@kraaft/shared/core/modules/schema/operations/duplicateElement.operation";
import { editColumnDefinitionOperation } from "@kraaft/shared/core/modules/schema/operations/editColumnDefinition.operation";
import { editConditionOperation } from "@kraaft/shared/core/modules/schema/operations/editCondition.operation";
import { editElementDescriptionOperation } from "@kraaft/shared/core/modules/schema/operations/editDescription.operation";
import { editMetadataOperation } from "@kraaft/shared/core/modules/schema/operations/editMetadata.operation";
import { editSectionOperation } from "@kraaft/shared/core/modules/schema/operations/editSection.operation";
import { renameOperation } from "@kraaft/shared/core/modules/schema/operations/rename.operation";
import { renameColumnOperation } from "@kraaft/shared/core/modules/schema/operations/renameColumn.operation";
import { reorderElementOperation } from "@kraaft/shared/core/modules/schema/operations/reorderElement.operation";
import { setDefaultVisibilityOperation } from "@kraaft/shared/core/modules/schema/operations/setDefaultVisibility.operation";
import { setHighlightedCheckboxOperation } from "@kraaft/shared/core/modules/schema/operations/setHighlightedColumn.operation";
import { taskManager } from "@kraaft/shared/core/store/offline/taskManager";
import { DeclareOfflineFeature } from "@kraaft/shared/core/utils/optimistic/newOptimistic/createOfflineFeature";

export const SchemaOfflineFeature = DeclareOfflineFeature<KSchema>("Schema")(
  {
    rename: renameOperation,
    renameColumn: renameColumnOperation,
    reorderElement: reorderElementOperation,
    editSection: editSectionOperation,
    editMetadata: editMetadataOperation,
    addSection: addSectionOperation,
    addColumn: addColumnOperation,
    editColumnDefinition: editColumnDefinitionOperation,
    deleteColumn: deleteColumnOperation,
    delete: deleteOperation,
    setHighlightedCheckbox: setHighlightedCheckboxOperation,
    editCondition: editConditionOperation,
    duplicateElement: duplicateElementOperation,
    setDefaultVisibility: setDefaultVisibilityOperation,
    editElementDescription: editElementDescriptionOperation,
  },
  taskManager.createTaskManager("Schema"),
);
