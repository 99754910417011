import { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import {
  DocumentDeleter,
  extractDocumentTitle,
  extractImageTitle,
  ImageThumbnail,
} from "@kraaft/shared/components/attachmentList/attachmentListUtils";
import { DocumentLoader } from "@kraaft/shared/components/documentLoader";
import { FileRow } from "@kraaft/shared/components/filesList/fileRow";
import { FileRowRenderingProps } from "@kraaft/shared/components/filesList/filesList.props";
import { Attachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { Icon } from "@kraaft/ui";

type FileRowFactoryProps = FileRowRenderingProps & {
  attachment: Attachment;
  index: number;
};

const FileRowFactory = ({
  attachment,
  index,
  onAttachmentClick,
  allowDeletion,
  context,
  onDelete,
}: FileRowFactoryProps) => {
  const rightComponent = useMemo(() => {
    return <DocumentDeleter onDelete={() => onDelete?.(attachment)} />;
  }, [attachment, onDelete]);

  const leftComponent = useMemo(() => {
    if (attachment.type === "video") {
      return (
        <View style={styles.iconAsPreview}>
          <Icon size="MEDIUM" name="play" />
        </View>
      );
    }
    if (attachment.type === "image") {
      return <ImageThumbnail attachment={attachment} />;
    }
    if (attachment.type === "audio") {
      return (
        <View style={styles.iconAsPreview}>
          <Icon size="MEDIUM" name="microphone-01" />
        </View>
      );
    }
    return (
      <View style={styles.iconAsPreview}>
        <Icon size="MEDIUM" name="file-02" />
      </View>
    );
  }, [attachment]);

  switch (attachment.type) {
    case "image":
      return (
        <FileRow
          attachment={attachment}
          leftComponent={leftComponent}
          extractTitle={extractImageTitle({ ...context, index })}
          onPress={onAttachmentClick}
          {...(allowDeletion ? { rightComponent } : {})}
        />
      );
    case "video":
    case "document":
    case "audio":
      return (
        <DocumentLoader
          downloadUrl={attachment.original.downloadUrl}
          filename={attachment.original.filename}
          type="view"
          trackingSource="modular folder"
        >
          <FileRow
            attachment={attachment}
            leftComponent={leftComponent}
            extractTitle={extractDocumentTitle({ ...context, index })}
            {...(allowDeletion ? { rightComponent } : {})}
          />
        </DocumentLoader>
      );
  }
};

const styles = StyleSheet.create({
  iconAsPreview: {
    width: 28,
  },
});

export { FileRowFactory };
