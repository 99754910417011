import { useCallback } from "react";

import { useMeshContext } from "@kraaft/helper-hooks";
import { RoomFiltersContext } from "@kraaft/shared/components/filters/roomFilters.context";

export function useFilterRooms() {
  const {
    onFilter,
    visibility,
    statusId,
    labelIds,
    responsibleId,
    showArchived,
    readingStatus,
  } = useMeshContext(RoomFiltersContext);

  return useCallback(() => {
    onFilter({
      visibility,
      statusId,
      labelIds,
      responsibleId,
      showArchived,
      readingStatus,
    });
  }, [
    labelIds,
    onFilter,
    readingStatus,
    responsibleId,
    showArchived,
    statusId,
    visibility,
  ]);
}
