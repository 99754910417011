import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useInputDefaultValueBehavior } from "@kraaft/helper-hooks";
import { EditorProps } from "@kraaft/shared/components/modular/details/editors/types";
import { EMPTY_LOCKED_RECORD_PLACEHOLDER } from "@kraaft/shared/components/modular/details/utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { useUrlMatcherForTextInput } from "@kraaft/shared/core/utils/useUrlMatcherForTextInput";
import { DroppableInput, TextInputWithMatchers } from "@kraaft/ui";

type Props = EditorProps<KColumnType.shortText>;

const ShortTextEditor = ({
  editor: { label, disabled, value, setValue, sectionLockInfo },
  column,
  columnContext: { autoFocus },
  testID,
}: Props) => {
  const { t } = useTranslation();
  const [text, setText, { onBlur, ...extraInputProps }] =
    useInputDefaultValueBehavior(value ?? "", autoFocus);
  const matchers = useUrlMatcherForTextInput();

  const handleBlur = useCallback(() => {
    onBlur();
    if (!sectionLockInfo.isLocked && !disabled) {
      setValue(text);
    }
  }, [sectionLockInfo.isLocked, disabled, onBlur, setValue, text]);

  const placeholder = !sectionLockInfo.isLocked
    ? t("modularity.answer")
    : EMPTY_LOCKED_RECORD_PLACEHOLDER;

  return (
    <DroppableInput
      nativeID={`${testID}-${column.key}`}
      accessibilityLabel={label}
      disabled={disabled || sectionLockInfo.isLocked}
      value={text}
      placeholder={placeholder}
      autoFocus={autoFocus}
      disableAutocomplete
      returnKeyType="done"
      returnKeyLabel={t("validate")}
      onChangeText={setText}
      onBlur={handleBlur}
      TextInputComponent={TextInputWithMatchers}
      extraTextInputProps={{ matchers }}
      translate={t}
    />
  );
};

export { ShortTextEditor };
