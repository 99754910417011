import { useCallback, useEffect, useRef } from "react";

import { isNative } from "@kraaft/helper-functions";
import { useMeshContext } from "@kraaft/helper-hooks";
import { PermissionRequesterContext } from "@kraaft/shared/components/permissionRequester/permissionRequester.context";
import { PermissionRequesterSheet } from "@kraaft/shared/components/permissionRequester/permissionRequester.sheet";
import { PermissionRequest } from "@kraaft/shared/components/permissionRequester/permissionRequester.types";
import { usePermissionStatusOf } from "@kraaft/shared/components/permissionRequester/usePermissionStatusOf";
import { permissions } from "@kraaft/shared/core/services/permission/permissions.provider";
import { Permissions } from "@kraaft/shared/core/services/permission/permissions.types";
import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

interface PermissionRequesterSheetContentProps {
  permissionRequest: PermissionRequest;
  onClearRequestedType?: () => void;
  portalHostname?: string;
}

const PermissionRequesterSheetContent = ({
  permissionRequest,
  onClearRequestedType,
  portalHostname,
}: PermissionRequesterSheetContentProps) => {
  const status = usePermissionStatusOf(permissionRequest.type);
  const neededToOpenSheet = useRef(false);
  const closedSheetFromHere = useRef(false);

  const handleClosed = useCallbackRealtime(
    async ([_permissionRequest]) => {
      const currentStatus = await permissions.statusOf(_permissionRequest.type);

      if (neededToOpenSheet.current && !closedSheetFromHere.current) {
        trackEvent({
          eventName: "Permission Explainer Sheet Skipped",
          type: _permissionRequest.type,
          current_status: currentStatus,
        });
      }
      _permissionRequest.promiseResolver(currentStatus);

      onClearRequestedType?.();
    },
    [onClearRequestedType],
    [permissionRequest],
  );

  const { open, close, element } = PermissionRequesterSheet.use({
    permissionRequest,
    onClosed: handleClosed,
    portalHostname,
  });

  const openSheetProxy = useCallback(() => {
    trackEvent({
      eventName: "Permission Explainer Sheet Opened",
      type: permissionRequest.type,
      current_status: status,
      from: permissionRequest.trackingFrom,
    });

    open();
  }, [open, permissionRequest.trackingFrom, permissionRequest.type, status]);

  useEffect(() => {
    neededToOpenSheet.current = false;
    closedSheetFromHere.current = false;
  }, [permissionRequest]);

  useEffect(() => {
    if (status === "unavailable") {
      return;
    }
    if (!isNative()) {
      handleClosed().catch(console.error);
      return;
    }
    if (!Permissions.convertStatusToBoolean(status)) {
      openSheetProxy();
      neededToOpenSheet.current = true;
    } else {
      if (neededToOpenSheet.current) {
        closedSheetFromHere.current = true;
        close();
      } else {
        handleClosed().catch(console.error);
      }
    }
  }, [close, openSheetProxy, status, handleClosed]);

  return element;
};

interface PermissionRequesterSheetProviderProps {
  portalHostname?: string;
}

export const PermissionRequesterSheetProvider = ({
  portalHostname,
}: PermissionRequesterSheetProviderProps) => {
  const { currentPermissionRequest, clearCurrentPermissionRequest } =
    useMeshContext(PermissionRequesterContext);

  return (
    currentPermissionRequest !== undefined && (
      <PermissionRequesterSheetContent
        permissionRequest={currentPermissionRequest}
        onClearRequestedType={clearCurrentPermissionRequest}
        portalHostname={portalHostname}
      />
    )
  );
};
