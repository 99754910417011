import { LibrarySchemaOfflineFeature } from "@kraaft/shared/core/modules/librarySchema/librarySchema.offlineFeature";
import { ReduxOfflineFeature } from "@kraaft/shared/core/store/offline/offlineBundle";

export const {
  Actions: OfflineLibrarySchemaActions,
  Selectors: OfflineLibrarySchemaSelectors,
  StateActions: OfflineLibrarySchemaStateActions,
} = ReduxOfflineFeature.Create(
  LibrarySchemaOfflineFeature,
  {
    selectRawAggregate: (state: any) => state.librarySchema.librarySchemas,
  },
  () => ({}),
);
