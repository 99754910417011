import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useMeshContext } from "@kraaft/helper-hooks";
import { RoomFiltersContext } from "@kraaft/shared/components/filters/roomFilters.context";
import { RoomFiltersSheet } from "@kraaft/shared/components/filters/roomFilters.sheet";
import { RoomFiltersFooter } from "@kraaft/shared/components/filters/roomFiltersFooter";
import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { ReadingStatus } from "@kraaft/shared/core/modules/filter/filterState";

interface RoomFiltersReadingStatusProps {
  onClose: () => void;
  onBack: () => void;
}

export const RoomFiltersReadingStatus = ({
  onClose,
  onBack,
}: RoomFiltersReadingStatusProps) => {
  const { t } = useTranslation();

  const {
    resetReadingStatus,
    setReadingStatus,
    isDefaultReadingStatus,
    readingStatus,
  } = useMeshContext(RoomFiltersContext);

  const handleSelect = useCallback(
    ([newReadingStatus]: ReadingStatus[]) => {
      if (newReadingStatus !== undefined) {
        setReadingStatus(newReadingStatus);
      }
    },
    [setReadingStatus],
  );

  const items = useMemo<KSelectableListItem<ReadingStatus>[]>(
    () => [
      { label: t("roomFilters.allReadingStatus"), identifier: "all" },
      { label: t("roomFilters.unread"), identifier: "unread" },
      { label: t("roomFilters.read"), identifier: "read" },
    ],
    [t],
  );

  return (
    <>
      <RoomFiltersSheet.Header onClose={onBack} withBackBehavior>
        {t("roomFilters.readingStatus")}
      </RoomFiltersSheet.Header>
      <RoomFiltersSheet.Content>
        <KSelectionList
          items={items}
          selected={[readingStatus]}
          setSelected={handleSelect}
          isMultiple={false}
          disableScroll
          noPadding
        />
      </RoomFiltersSheet.Content>
      <RoomFiltersFooter
        onFiltered={onClose}
        onErase={resetReadingStatus}
        canErase={!isDefaultReadingStatus}
      />
    </>
  );
};
