import { PropsWithChildren, useCallback } from "react";
import {
  Pressable,
  PressableStateCallbackType,
  StyleSheet,
} from "react-native";

import { PreviewSquare } from "@kraaft/shared/components/imagesPreview/previewSquare";
import { TrackableViewProps } from "@kraaft/shared/core/types";
import {
  Image,
  ImageProps,
  ImagePropsPreferredSizeHelper,
  ImageSource,
} from "@kraaft/ui";

export type ImagePreviewSquareProps<
  Source extends ImageSource = ImageSource,
  Local extends boolean = boolean,
> = TrackableViewProps & {
  onPress: () => void;
  downloadUrl: string | undefined;
  contentFit?: ImageProps["contentFit"];
  backgroundColor?: string;
} & ImagePropsPreferredSizeHelper<Source, Local>;

const ImagePreviewSquare = <
  Source extends ImageSource = ImageSource,
  Local extends boolean = boolean,
>({
  onPress,
  downloadUrl,
  contentFit = "cover",
  backgroundColor,
  children,
  local,
  preferredSize,
  ...trackableViewProps
}: PropsWithChildren<ImagePreviewSquareProps<Source, Local>>) => {
  const getPressableStyle = useCallback(
    (state: PressableStateCallbackType) => [
      styles.imageContainer,
      backgroundColor ? { backgroundColor } : undefined,
      state.pressed && styles.imageContainerPressed,
    ],
    [backgroundColor],
  );

  return (
    <PreviewSquare>
      <Pressable
        onPress={onPress}
        accessibilityLabel={trackableViewProps.accessibilityLabel}
        style={getPressableStyle}
      >
        <Image
          preferredSize={preferredSize}
          style={styles.image}
          local={local}
          contentFit={contentFit}
          source={downloadUrl}
        />
        {children}
      </Pressable>
    </PreviewSquare>
  );
};

const styles = StyleSheet.create({
  imageContainer: {
    flexGrow: 1,
  },
  imageContainerPressed: {
    opacity: 0.75,
  },
  image: {
    flexGrow: 1,
    resizeMode: "cover",
  },
});

export { ImagePreviewSquare };
