import { HoverState } from "@kraaft/shared/core/modules/schema/schema.offline";

export type Transition = "top" | "bottom" | undefined;

export function getTranslateFromTransition(transition: Transition) {
  return `translateY(${
    transition === "bottom" ? "64px" : transition === "top" ? "-64px" : "0px"
  })`;
}

// eslint-disable-next-line complexity
export function getTransitionFromIndexes(
  currentHover: HoverState | undefined,
  index: number,
  hoverKeyIndex: number,
  draggedKeyIndex: number,
) {
  let transition: Transition;
  if (hoverKeyIndex === -1) {
    if (draggedKeyIndex < index && draggedKeyIndex !== -1) {
      transition = "top";
    } else {
      transition = undefined;
    }
  } else if (
    hoverKeyIndex === index &&
    (draggedKeyIndex > index || draggedKeyIndex === -1)
  ) {
    if (currentHover?.placement === "after") {
      transition = undefined;
    } else {
      transition = "bottom";
    }
  } else if (hoverKeyIndex === index && draggedKeyIndex < index) {
    if (currentHover?.placement === "after" && draggedKeyIndex !== -1) {
      transition = "top";
    } else {
      transition = undefined;
    }
  } else if (
    hoverKeyIndex > index &&
    draggedKeyIndex < index &&
    draggedKeyIndex !== -1
  ) {
    transition = "top";
  } else if (
    hoverKeyIndex < index &&
    (draggedKeyIndex > index || draggedKeyIndex === -1)
  ) {
    transition = "bottom";
  }
  return transition;
}
