import { memo, useCallback, useEffect, useRef, useState } from "react";
import { StyleSheet } from "react-native";

import { CarouselImageProps } from "@kraaft/shared/components/carousel/placeholders/carousel.props";
import { GenericCarouselAttachment } from "@kraaft/shared/components/carousel/placeholders/genericCarouselAttachment";
import { ImageManipulation } from "@kraaft/shared/components/carousel/placeholders/imageManipulation";
import { ImageManipulationHandler } from "@kraaft/shared/components/carousel/placeholders/imageManipulation/imageManipulation.props";
import { fileAllocator } from "@kraaft/shared/core/modules/file/fileAllocator";
import { GeoLocation, Size } from "@kraaft/shared/core/types";
import { Image, ImageProxyBuilder, useCarouselIsFocused } from "@kraaft/ui";
import { ImageLoadedData } from "@kraaft/ui/src/display/image/imageImplementation.types";

const CarouselImage_ = <T,>({
  index,
  item,
  roomId,
  attachment,
  onLocationChange,
  onClose,
  uploadContext,
  onDelete,
}: CarouselImageProps<T>) => {
  const focused = useCarouselIsFocused(index);
  const imageManipulationHandle = useRef<ImageManipulationHandler>(null);

  const [imageSize, setImageSize] = useState<Size | undefined>();

  const handleLocationChange = useCallback(
    (location: GeoLocation | null | undefined) => {
      onLocationChange(item, location);
    },
    [item, onLocationChange],
  );

  const handleDelete = useCallback(() => {
    onDelete?.(item);
  }, [item, onDelete]);

  useEffect(() => {
    if (!focused) {
      imageManipulationHandle.current?.reset();
    }
  }, [focused]);

  const { downloadUrl: originalImageUrl, filename } = attachment.original;

  const downloadableUrl = fileAllocator.parseRemotePath(
    ImageProxyBuilder.fromUrl(originalImageUrl)
      .setGeolocation(attachment.geolocation?.coords)
      .buildUrl(),
  );

  const handleImageLoad = useCallback((event: ImageLoadedData) => {
    const { width, height } = event;
    setImageSize((oldSize) => {
      if (
        oldSize === undefined ||
        oldSize.width !== width ||
        oldSize.height !== height
      ) {
        return { width, height };
      }
      return oldSize;
    });
  }, []);

  return (
    <GenericCarouselAttachment
      attachment={attachment}
      index={index}
      onLocationChange={handleLocationChange}
      roomId={roomId}
      onClose={onClose}
      uploadContext={uploadContext}
      onDelete={handleDelete}
    >
      {originalImageUrl && (
        <ImageManipulation
          onDelete={handleDelete}
          handle={imageManipulationHandle}
          path={downloadableUrl}
          mediaSize={imageSize}
          name={filename}
        >
          <Image
            source={originalImageUrl}
            style={styles.image}
            contentFit="contain"
            preferredSize="large"
            geolocation={attachment.geolocation?.coords}
            onLoad={handleImageLoad}
          />
        </ImageManipulation>
      )}
    </GenericCarouselAttachment>
  );
};

const styles = StyleSheet.create({
  image: {
    height: "100%",
    width: "100%",
  },
});

export const CarouselImage = memo(CarouselImage_) as typeof CarouselImage_;
