import { Dispatch, MutableRefObject, SetStateAction } from "react";
import isEqual from "fast-deep-equal";

import { createMeshContext, useMeshContext } from "@kraaft/helper-hooks";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import {
  KSchema,
  KSchemaColumnDefinition,
  KSchemaElement,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { HoverState } from "@kraaft/shared/core/modules/schema/schema.offline";
import { ElementCreation } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor";
import { FormBuilderElement } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementDrag/elementDrag.utils";
export interface SchemaBuilderContextProps {
  // Edition actions
  renameElement: (key: string, newValue: string, hasBoolean: boolean) => void;
  deleteElement: (key: string) => void;
  insertElement: (element: KSchemaElement, placement: HoverState) => void;
  reorderElement: (
    hoverState: HoverState,
    target: string,
    tryToMoveInsideElement: string | undefined,
  ) => void;
  editColumnDefinition: (
    columnKey: string,
    newDefinition: KSchemaColumnDefinition,
  ) => void;
  editSection: (
    key: string,
    edits: {
      color?: string;
    },
  ) => void;
  editCondition: (
    key: string,
    condition: CompositeCondition | undefined,
  ) => void;
  editDescription: (
    elementKey: string,
    description: {
      text?: string | undefined;
      image?: ModernFile<LocalPath> | null | undefined;
      document?: ModernFile<LocalPath> | null | undefined;
    },
  ) => void;

  // Form builder state
  blurEverything: () => void;
  addElement: (
    element: FormBuilderElement,
    placement?: HoverState,
    inElement?: string,
  ) => void;

  duplicate: (key: string) => void;

  currentElementCreation: ElementCreation | undefined;
  setCurrentElementCreation: (column: ElementCreation | undefined) => void;

  currentElementEditionKey: string | undefined;
  setCurrentElementEditionKey: (newKey: string | undefined) => void;

  currentHoveredKey: HoverState | undefined;
  setCurrentHoveredKey: Dispatch<SetStateAction<HoverState | undefined>>;

  currentDraggedKey: string | undefined;
  setCurrentDraggedKey: (draggedKey: string | undefined) => void;

  resetDrag: () => void;
  resetCreation: () => void;

  lockOnEnd: MutableRefObject<boolean>;

  schema: KSchema;
  isColumnDeletionInstant: boolean;
  hidePreview: boolean;
}

export const SchemaBuilderContext =
  createMeshContext<SchemaBuilderContextProps>();

export const useSchemaBuilderRootSection = () =>
  useMeshContext(
    SchemaBuilderContext,
    (context) => context.schema.rootSection,
    isEqual,
  );
