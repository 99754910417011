import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";
import { useSelector } from "react-redux";

import { useBooleanState } from "@kraaft/helper-hooks";
import { WebOnlyUnderlineHover } from "@kraaft/shared/components/message/messageSender/webOnlyUnderlineHover";
import { UserBadge } from "@kraaft/shared/components/message/userBadge/userBadge";
import { UserContactInfoSheet } from "@kraaft/shared/components/userContactInfoSheet/userContactInfoSheet";
import { UserMessage } from "@kraaft/shared/core/modules/message/messageState";
import { selectUserRole } from "@kraaft/shared/core/modules/poolMember/poolMemberSelectors";
import { selectUser } from "@kraaft/shared/core/modules/user/userSelectors";
import { getUsername } from "@kraaft/shared/core/modules/user/userUtils";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

import { styles } from "./messageSender.styles";

interface Props {
  poolId: string | undefined;
  message: UserMessage;
}

const MessageSender = (props: Props) => {
  const { poolId, message } = props;

  const { t } = useTranslation();

  const textRef = useRef(null);
  const [isUserContactInfoOpen, openUserContactInfo, closeUserContactInfo] =
    useBooleanState(false);

  const author = useSelector(selectUser(message.senderId));
  const authorRole = useSelector(selectUserRole(message.senderId, poolId));

  const authorUserName = getUsername(author);
  const extraText =
    authorRole === UserPoolRole.OUTSIDER ? (
      <Text style={styles.extraText}>{` (${t("outsider")})`}</Text>
    ) : undefined;

  const textStyle = useMemo(
    () => [
      styles.messageSenderName,
      author?.usernameColor ? { color: author?.usernameColor } : {},
    ],
    [author?.usernameColor],
  );

  const handleOnPress = useCallback(() => {
    trackEvent({
      eventName: "Click On User Name",
      from: "chat",
    });
    openUserContactInfo();
  }, [openUserContactInfo]);

  return (
    <View style={styles.messageSenderNameContainer}>
      <UserBadge userRole={authorRole} badgeColor={author?.usernameColor} />

      <WebOnlyUnderlineHover color={author?.usernameColor}>
        <Text
          style={textStyle}
          numberOfLines={2}
          ref={textRef}
          onPress={handleOnPress}
        >
          {authorUserName}
          {extraText}
        </Text>
      </WebOnlyUnderlineHover>
      <UserContactInfoSheet
        anchor={textRef}
        open={isUserContactInfoOpen}
        onClose={closeUserContactInfo}
        userId={message.senderId}
      />
    </View>
  );
};

export { MessageSender };
