import {
  KRoomSchema,
  KSchema,
  KSchemaIcon,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { AutoNumberingMode } from "@kraaft/shared/core/modules/schema/schema.actions";
import { optimisticSchemaEditMetadata } from "@kraaft/shared/core/modules/schema/schema.optimisticHelper";
import { Api } from "@kraaft/shared/core/services/api";
import { Operation } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations";

export const editMetadataOperation = Operation.editOne<KSchema>()
  .payload<{
    name?: string;
    icon?: KSchemaIcon;
    autonumbering?: AutoNumberingMode;
    display?: KRoomSchema["roomCardDisplayColumns"];
  }>()
  .expected((aggregate, payload) => {
    optimisticSchemaEditMetadata(aggregate, payload);
    return aggregate;
  })
  .mutate(async (payload) => {
    const { updatedAt } = await Api.editSchema({
      schemaId: payload.id,
      name: payload.name,
      icon: payload.icon,
      roomCardDisplayColumns: payload.display,
    });
    return { updatedAt };
  });
