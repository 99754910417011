import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { KSchemaSection } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { HoverState } from "@kraaft/shared/core/modules/schema/schema.offline";
import { optimisticSchemaAddSection } from "@kraaft/shared/core/modules/schema/schema.optimisticHelper";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { Api } from "@kraaft/shared/core/services/api";
import { HttpError } from "@kraaft/shared/core/services/firebase/httpError";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { getStore } from "@kraaft/shared/core/store";
import { EditOneOperationBuilder } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations/editOne.operation";

export const addSchemaSectionOperation =
  EditOneOperationBuilder.create<LibrarySchema>()
    .payload<{
      section: KSchemaSection;
      placement: HoverState;
      index: number;
    }>()
    .expected((aggregate, payload) => {
      optimisticSchemaAddSection(aggregate.schema, payload);
      return aggregate;
    })
    .mutate(async (payload) => {
      try {
        const { key, updatedAt } = await Api.addLibrarySchemaSection({
          librarySchemaId: payload.id,
          index: payload.index,
          name: payload.section.name,
          parentSectionKey: KSchemaUtils.rootSectionKey,
        });

        // TODO see if necessary
        // yield* put(
        //   LibrarySchemaStateActions.setCreatedKey({
        //     librarySchemaId: librarySchema.id,
        //     columnKey: key,
        //   }),
        // );

        return { updatedAt };
      } catch (e) {
        if (HttpError.isHttpErrorWithCode(e, "MaxColumnCountExceededError")) {
          getStore().dispatch(
            showError({
              title: i18n.t("formBuilder.schema.error.maxColumnCountExceeded"),
            }),
          );
        }
        throw e;
      }
    });
