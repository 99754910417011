import { useSyncExternalStore } from "react";

import { storage } from "@kraaft/shared/core/modules/storage/storage.provider";
import { Listeners } from "@kraaft/shared/core/utils/listeners";
import { Logger } from "@kraaft/shared/core/utils/logger/logger";
import { PersistedLog } from "@kraaft/shared/core/utils/logger/logger.types";

class LogStorage {
  static MAX_ITEMS = 500;

  constructor() {
    this.initPromise = this.load();
  }

  private static storageKey = "persistedLogs";
  private static storageKeyEnabled = "persistedLogsEnabled";

  private startupLogsBuffer = new Array<PersistedLog>();
  private logs = new Array<PersistedLog>();
  private readonly initPromise: Promise<unknown>;

  enabled = false;

  async enable(enabled: boolean) {
    this.enabled = enabled;
    await storage.setItem(LogStorage.storageKeyEnabled, enabled);
  }

  private async save() {
    await storage.setItem(LogStorage.storageKey, this.logs);
  }

  async clearLogs() {
    this.logs = [];
    await this.save();
  }

  async addLog(log: PersistedLog) {
    await this.initPromise;
    if (!this.enabled) {
      return;
    }
    if (this.logs.length > LogStorage.MAX_ITEMS) {
      this.logs.splice(0, this.logs.length - LogStorage.MAX_ITEMS);
    }
    this.logs = [...this.logs, log];
    await this.save();
  }

  getLogs() {
    return this.logs;
  }

  private async load() {
    const enabled =
      (await storage.getItem(LogStorage.storageKeyEnabled)) ?? false;
    this.enabled = enabled;
    if (this.enabled) {
      this.logs = await this.getStoredLogs();
    } else {
      this.logs = [];
    }
  }

  private async getStoredLogs() {
    try {
      const item = await storage.getItem(LogStorage.storageKey);
      if (!Array.isArray(item)) {
        await this.clearLogs();
        return [];
      }
      return item;
    } catch (e) {
      await this.clearLogs();
      return [];
    }
  }
}

export class PersistentLogger extends Logger {
  private static storage = new LogStorage();
  public static onLog = new Listeners<(log: PersistedLog | null) => void>();

  public static getLogs() {
    return PersistentLogger.storage.getLogs();
  }

  public static clearLogs() {
    return PersistentLogger.storage
      .clearLogs()
      .then(async () => {
        await PersistentLogger.onLog.trigger(null);
      })
      .catch(console.error);
  }

  static async setPersistenceEnabled(enabled: boolean) {
    await PersistentLogger.storage.enable(enabled);
  }

  public static create(module: string, ...namespaces: string[]) {
    return new PersistentLogger(module, namespaces);
  }

  private _log(level: PersistedLog["level"], ...args: any[]) {
    if (this.isEnabled) {
      console.log(...this.formattedNameSpace, ...args);
    }

    const log: PersistedLog = {
      level,
      module: this.module,
      namespace: this.namespaces,
      values: args,
      timestamp: Date.now(),
    };
    PersistentLogger.storage
      .addLog(log)
      .then(async () => {
        await PersistentLogger.onLog.trigger(log);
      })
      .catch(console.error);
  }

  public log(...args: any[]) {
    this._log("info", ...args);
  }

  public warn(...args: any[]) {
    this._log("warn", ...args);
  }

  public error(...args: any[]) {
    this._log("error", ...args);
  }

  public createSubLogger(namespaces: string[]): Logger {
    return new PersistentLogger(this.module, [
      ...this.namespaces,
      ...namespaces,
    ]).setEnabled(this.isEnabled);
  }
}

export function usePersistentLogs() {
  return useSyncExternalStore(
    PersistentLogger.onLog.register.bind(PersistentLogger.onLog),
    PersistentLogger.getLogs,
  );
}
