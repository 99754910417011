import { KSchemaElement } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ActionSheet } from "@kraaft/ui";
import { useDeleteElementActionItems } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementActions/useDeleteElementActionItems";
import { useDuplicateActionItems } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementActions/useDuplicateActionItems";
import { useOptionItem } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementActions/useOptionItem";

interface ElementMenuActionSheetProps {
  onDelete(key: string): void;
  element: KSchemaElement;
  forceInstantDeletion: boolean;
  openOptions(): void;
}

export const ElementMenuActionSheet =
  ActionSheet().create<ElementMenuActionSheetProps>(
    ({ ActionSheetContent, ActionSheetItem }) =>
      ({ onDelete, element, forceInstantDeletion, openOptions }) => {
        const deleteActions = useDeleteElementActionItems(
          onDelete,
          element,
          forceInstantDeletion,
        );

        const duplicateActions = useDuplicateActionItems(element);
        const optionsAction = useOptionItem({ openOptions });

        return (
          <ActionSheetContent>
            {duplicateActions}
            {optionsAction}
            {deleteActions}
          </ActionSheetContent>
        );
      },
  );
