import { ReactNode, useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useMeshContextSetup } from "@kraaft/helper-hooks";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { OfflineLibrarySchemaActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.offline";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import {
  KSchemaColumnDefinition,
  KSchemaElement,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { HoverState } from "@kraaft/shared/core/modules/schema/schema.offline";
import { selectCreatedColumn } from "@kraaft/shared/core/modules/schema/schema.selectors";
import {
  getIndexFromPlacement,
  KSchemaUtils,
} from "@kraaft/shared/core/modules/schema/schema.utils";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";
import { useEffectDifference } from "@kraaft/shared/core/utils/useEffectDifference";
import { getFieldProp } from "@kraaft/web/src/components/modularTable/components/fields";
import {
  getDefaultColumnValue,
  getDefaultSectionValue,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/columnUtils";
import { FormBuilderElement } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementDrag/elementDrag.utils";
import { ElementCreation } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementsEditor";
import {
  SchemaBuilderContext,
  SchemaBuilderContextProps,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

interface Props {
  librarySchema: LibrarySchema;
  children: ReactNode;
}

export const LibrarySchemaBuilderContextProvider = ({
  librarySchema,
  children,
}: Props) => {
  const dispatch = useDispatch();

  const { schema } = librarySchema;

  const lockOnEnd = useRef(false);
  const [currentDraggedKey, setCurrentDraggedKey] = useState<
    string | undefined
  >(undefined);
  const [currentHoveredKey, setCurrentHoveredKey] = useState<
    HoverState | undefined
  >(undefined);

  const [currentElementEditionKey, setCurrentElementEditionKey] = useState<
    string | undefined
  >(undefined);
  const [currentElementCreation, setCurrentElementCreation] = useState<
    ElementCreation | undefined
  >(undefined);
  const justCreatedColumn = useSelector(selectCreatedColumn(librarySchema.id));

  const blurEverything = useCallback(() => {
    setCurrentElementEditionKey(undefined);
    setCurrentElementCreation(undefined);
  }, []);

  const insertElement = useCallbackRealtime(
    (
      [_schema, _currentElementCreation],
      element: KSchemaElement,
      placement: HoverState,
    ) => {
      if (!_currentElementCreation || element.name.length === 0) {
        return;
      }
      if (element.elementType === "section") {
        const newIndex = getIndexFromPlacement(_schema, placement);

        dispatch(
          OfflineLibrarySchemaActions.addSchemaSection({
            id: _schema.id,
            section: element,
            placement,
            index: newIndex,
          }),
        );
      }
      if (element.elementType === "column") {
        const newIndex = getIndexFromPlacement(_schema, placement);
        const context = KSchemaUtils.findWithContext(
          _schema.rootSection,
          placement.key,
        );

        if (newIndex === undefined || !context) {
          return null;
        }

        dispatch(
          OfflineLibrarySchemaActions.addSchemaColumn({
            id: _schema.id,
            column: element,
            placement,
            containerKey:
              _currentElementCreation.inSection ?? context.parentSection.key,
            index: newIndex,
          }),
        );
      }
      blurEverything();
    },
    [blurEverything, dispatch],
    [schema, currentElementCreation],
  );

  const addElement = useCallbackRealtime(
    (
      [_schema],
      item: FormBuilderElement,
      placement?: HoverState,
      inElement?: string,
    ) => {
      const columns = Object.values(_schema.rootSection.elements).sort(
        KSchemaUtils.orderByIndex,
      );
      const columnKey = placement?.key ?? columns[columns.length - 1]?.key;
      if (!columnKey) {
        return;
      }
      if (item.type === "section") {
        const section = getDefaultSectionValue("");
        setCurrentElementEditionKey(section.key);
        setCurrentElementCreation({
          type: "section",
          placement: placement ?? { key: columnKey, placement: "after" },
          section,
          inSection: inElement,
        });
      } else {
        const column = getDefaultColumnValue("", item.columnType);
        setCurrentElementEditionKey(column.key);
        setCurrentElementCreation({
          type: "column",
          placement: placement ?? { key: columnKey, placement: "after" },
          column,
          inSection: inElement,
        });
      }
    },
    [],
    [schema],
  );

  const editColumnDefinition = useCallbackRealtime(
    ([_schema], columnKey: string, newDefinition: KSchemaColumnDefinition) => {
      dispatch(
        OfflineLibrarySchemaActions.editSchemaColumnDefinition({
          id: _schema.id,
          columnKey,
          definition: newDefinition,
        }),
      );
    },
    [dispatch],
    [schema],
  );

  const renameElement = useCallbackRealtime(
    ([schemaRef], columnKey: string, name: string, hasEditor: boolean) => {
      const element = KSchemaUtils.findElement(
        schemaRef.rootSection,
        columnKey,
      );
      if (element?.name !== name) {
        if (element?.elementType === "column") {
          dispatch(
            OfflineLibrarySchemaActions.renameSchemaColumn({
              id: schema.id,
              columnKey,
              name,
            }),
          );
        } else if (element?.elementType === "section") {
          dispatch(
            OfflineLibrarySchemaActions.editSchemaSection({
              id: schema.id,
              key: element.key,
              edits: {
                name,
              },
            }),
          );
        }
      }
      if (!hasEditor) {
        setCurrentElementEditionKey(undefined);
      }
    },
    [dispatch, schema.id],
    [schema],
  );

  const reorderElement = useCallbackRealtime(
    (
      [_schema],
      hoverState: HoverState,
      target: string,
      tryToMoveInsideElement: string | undefined,
    ) => {
      let payload: {
        id: string;
        targetKey: string;
        afterKey: string | undefined;
        sectionKey: string;
      };
      const container = tryToMoveInsideElement
        ? KSchemaUtils.findElement(_schema.rootSection, tryToMoveInsideElement)
        : KSchemaUtils.findWithContext(_schema.rootSection, hoverState.key)
            ?.parentSection;

      if (container?.elementType !== "section") {
        return;
      }

      if (
        tryToMoveInsideElement &&
        KSchemaUtils.findElement(container, hoverState.key)
      ) {
        payload = {
          id: _schema.id,
          targetKey: target,
          sectionKey: container.key,
          afterKey: undefined,
        };
      }

      if (hoverState.placement === "after") {
        payload = {
          id: _schema.id,
          targetKey: target,
          sectionKey: container.key,
          afterKey: hoverState.key,
        };
      } else {
        const orderedElements = KSchemaUtils.orderedSectionElements(container);

        const relativeElementIndex = orderedElements.findIndex(
          (element) => element.key === hoverState.key,
        );

        payload = {
          id: _schema.id,
          targetKey: target,
          sectionKey: container.key,
          afterKey: orderedElements[relativeElementIndex - 1]?.key,
        };
      }

      dispatch(OfflineLibrarySchemaActions.reorderSchemaElement(payload));
    },
    [dispatch],
    [schema],
  );

  const deleteElement = useCallback(
    (columnKey: string) => {
      dispatch(
        OfflineLibrarySchemaActions.deleteSchemaColumn({
          id: schema.id,
          columnKey,
        }),
      );
    },
    [dispatch, schema.id],
  );

  const createdColumnType = justCreatedColumn
    ? KSchemaUtils.findColumn(schema.rootSection, justCreatedColumn)?.type
    : undefined;
  const columnCreationRef = useRef(currentElementCreation);
  columnCreationRef.current = currentElementCreation;
  useEffectDifference(
    ([, oldJustCreatedColumn, oldType]) => {
      if (
        columnCreationRef.current ||
        !justCreatedColumn ||
        (oldJustCreatedColumn === justCreatedColumn &&
          oldType === createdColumnType)
      ) {
        return;
      }
      if (
        createdColumnType &&
        getFieldProp("columnEditor", createdColumnType)
      ) {
        setCurrentElementEditionKey(justCreatedColumn);
      }
    },
    [currentElementCreation, justCreatedColumn, createdColumnType],
  );

  const resetDrag = useCallback(() => {
    setCurrentHoveredKey(undefined);
    setCurrentDraggedKey(undefined);
  }, []);

  const resetCreation = useCallback(() => {
    setCurrentElementCreation(undefined);
    setCurrentElementEditionKey(undefined);
  }, []);

  const editSection = useCallback(
    (
      key: string,
      edits: {
        color?: string;
      },
    ) => {
      dispatch(
        OfflineLibrarySchemaActions.editSchemaSection({
          id: schema.id,
          key,
          edits: {
            color: edits.color,
          },
        }),
      );
    },
    [dispatch, schema.id],
  );

  const editCondition = useCallback(
    (key: string, condition: CompositeCondition | undefined) => {
      dispatch(
        OfflineLibrarySchemaActions.editSchemaElementCondition({
          id: schema.id,
          key: key,
          condition,
        }),
      );
    },
    [dispatch, schema.id],
  );

  const duplicate = useCallback(
    (key: string) => {
      dispatch(
        OfflineLibrarySchemaActions.duplicateSchemaElement({
          id: schema.id,
          key,
        }),
      );
    },
    [dispatch, schema.id],
  );

  const currentUserId = useSelector(selectCurrentUserId);

  const editDescription = useCallback(
    (
      elementKey: string,
      description: {
        text?: string | undefined;
        image?: ModernFile<LocalPath> | null | undefined;
        document?: ModernFile<LocalPath> | null | undefined;
      },
    ) => {
      dispatch(
        OfflineLibrarySchemaActions.editSchemaElementDescription({
          id: schema.id,
          userId: currentUserId ?? "",
          description,
          elementKey,
        }),
      );
    },
    [currentUserId, dispatch, schema.id],
  );

  const contextValue = useMeshContextSetup<SchemaBuilderContextProps>({
    insertElement,
    addElement,
    editColumnDefinition,
    renameElement,
    reorderElement,
    deleteElement,
    blurEverything,
    currentElementCreation,
    currentElementEditionKey,
    setCurrentElementCreation,
    setCurrentElementEditionKey,
    currentHoveredKey,
    setCurrentHoveredKey,
    currentDraggedKey,
    setCurrentDraggedKey,
    resetDrag,
    resetCreation,
    lockOnEnd,
    editSection,
    editCondition,
    duplicate,
    editDescription,
    schema,
    isColumnDeletionInstant: true,
    hidePreview: false,
  });

  return (
    <SchemaBuilderContext.Provider value={contextValue}>
      {children}
    </SchemaBuilderContext.Provider>
  );
};
