import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { selectAllRoomCards } from "@kraaft/shared/core/modules/roomCard/roomCard.selectors";
import { RoomCardUtils } from "@kraaft/shared/core/modules/roomCard/roomCard.utils";

export function useLastReadRoomId() {
  const allRoomCards = useSelector(selectAllRoomCards);

  const focusedRoomId = useParams<{ roomId?: string }>().roomId;

  const firstFocusedRoomReadingStatus = useRef<
    undefined | { roomId: string; isUnread: boolean }
  >();

  const focusedRoomReadingStatus = useMemo(() => {
    if (!focusedRoomId) {
      return undefined;
    }

    const focusedRoomCard = allRoomCards[focusedRoomId];
    const isUnread =
      focusedRoomCard && RoomCardUtils.isMemberCard(focusedRoomCard)
        ? focusedRoomCard.unread
        : false;

    return {
      roomId: focusedRoomId,
      isUnread,
    };
  }, [allRoomCards, focusedRoomId]);

  return useMemo(() => {
    if (!focusedRoomReadingStatus) {
      return undefined;
    }

    if (focusedRoomReadingStatus.isUnread) {
      firstFocusedRoomReadingStatus.current = focusedRoomReadingStatus;
      return focusedRoomReadingStatus.roomId;
    }

    if (
      focusedRoomReadingStatus.roomId !==
      firstFocusedRoomReadingStatus.current?.roomId
    ) {
      firstFocusedRoomReadingStatus.current = undefined;
      return undefined;
    }

    return focusedRoomReadingStatus.roomId;
  }, [focusedRoomReadingStatus]);
}
