import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { omitBy } from "lodash";

import { useMeshContext } from "@kraaft/helper-hooks";
import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaElement } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { useDebounceOptimistic } from "@kraaft/shared/core/utils/useDebounce";
import { Text } from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";
import { FilterMenuContent } from "@kraaft/web/src/components/filterMenu/filterMenuContent";
import { DEFAULT_FILTER } from "@kraaft/web/src/components/filterMenu/useFilterState";
import {
  SchemaBuilderContext,
  useSchemaBuilderRootSection,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

interface ElementPopupConditionProps {
  element: KSchemaElement;
}

export const ElementOptionsCondition = ({
  element,
}: ElementPopupConditionProps) => {
  const { editCondition } = useMeshContext(SchemaBuilderContext);
  const rootSection = useSchemaBuilderRootSection();

  const { t } = useTranslation();

  const { update, value: filters } = useDebounceOptimistic(
    element.condition ?? DEFAULT_FILTER,
    500,
    (condition) => {
      editCondition(element.key, condition);
    },
    { keepUpdatesOnDebounce: true },
  );

  const handleSetFilters = useCallback(
    (newFilters: CompositeCondition) => {
      update(() => newFilters);
    },
    [update],
  );

  const columns = useMemo(() => {
    // Cant execute condition on itself or children
    const forbiddenColumns =
      element.elementType === "column"
        ? [element.key]
        : [
            element.key,
            ...KSchemaUtils.flattenSection(element).map((e) => e.key),
          ];
    return omitBy(
      KSchemaUtils.flattenColumnsWithPath(rootSection),
      ({ column }) => forbiddenColumns.includes(column.key),
    );
  }, [element, rootSection]);

  return (
    <div>
      <Box>
        <Box gap="S8">
          <Text size="BODY" weight="bold" color="FONT_HIGH_EMPHASIS">
            {t("schemaColumnOptions.conditions.title")}
          </Text>
          <Text size="BODY" color="FONT_LOW_EMPHASIS">
            <Trans
              i18nKey="schemaColumnOptions.conditions.subtitle"
              components={{
                Field: <Text weight="bold" color="FONT_LOW_EMPHASIS" />,
              }}
              values={{ elementName: element.name }}
            />
          </Text>
        </Box>
        <Box grow>
          <FilterMenuContent
            columns={columns}
            filters={filters}
            setFilters={handleSetFilters}
            disabledColumnTypes={[KColumnType.roomName]}
          />
        </Box>
      </Box>
    </div>
  );
};
