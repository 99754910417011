import { EventChannel, eventChannel } from "redux-saga";
import { put, select, takeEvery } from "typed-redux-saga/macro";

import {
  FirestoreRoomCard,
  firestoreSubscribeToRoomMemberCard,
  normalizeRoomCard,
} from "@kraaft/shared/core/modules/roomCard/queries/firestore.roomCard";
import {
  RoomCardActions,
  RoomCardStateActions,
} from "@kraaft/shared/core/modules/roomCard/roomCard.actions";
import { selectRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.selectors";
import { selectRoomSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";

type Meta = EventChannel<FirestoreRoomCard | null> | undefined;

function createChannel(userId: string, roomId: string) {
  return eventChannel<FirestoreRoomCard | null>((emit) => {
    return firestoreSubscribeToRoomMemberCard(userId, roomId, emit);
  });
}

function* subscribeToRoomMemberCard(
  registerMeta: (meta: Meta) => void,
  action: ReturnType<typeof RoomCardActions.subscribeToRoomMemberCard>,
) {
  const currentUserId = yield* select(selectCurrentUserId);

  if (!currentUserId) {
    return;
  }

  const channel = createChannel(currentUserId, action.payload.roomId);

  registerMeta(channel);

  yield* takeEvery(channel, (firestoreRoomCard) => {
    return receiveRoomMemberCard(firestoreRoomCard, action.payload.roomId);
  });
}

function* receiveRoomMemberCard(
  firestoreRoomCard: FirestoreRoomCard | null,
  roomId: string,
) {
  const roomCard = yield* select(selectRoomCard(roomId));

  if (!roomCard || roomCard.type === "pool") {
    return;
  }

  const schema = yield* select(selectRoomSchema(firestoreRoomCard?.poolId));

  if (!schema) {
    return;
  }

  const allSchemaColumns = schema
    ? KSchemaUtils.flattenColumnsDict(schema.rootSection)
    : {};

  if (firestoreRoomCard) {
    yield* put(
      RoomCardStateActions.setRoomCard({
        roomId: firestoreRoomCard.roomId,
        roomCard: normalizeRoomCard(firestoreRoomCard, allSchemaColumns),
      }),
    );
  } else {
    yield* put(RoomCardStateActions.deleteRoomCard({ roomId }));
  }
}

function* unsubscribeFromRoomMemberCard(meta: Meta) {
  meta?.close();
}

export function* subscribeToRoomMemberCardSaga() {
  yield takeCountedDeep(
    RoomCardActions.subscribeToRoomMemberCard,
    RoomCardActions.unsubscribeFromRoomMemberCard,
    subscribeToRoomMemberCard,
    unsubscribeFromRoomMemberCard,
    (action) => action.payload.roomId,
  );
}
