import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useMeshContext } from "@kraaft/helper-hooks";
import { KSchemaElement } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ActionSheetItem } from "@kraaft/ui";
import { useDeleteSchemaColumn } from "@kraaft/web/src/components/multiStepDialog/customs/deleteSchemaColumn";
import { SchemaBuilderContext } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

export function useDeleteElementActionItems(
  onDelete: (key: string) => void,
  element: KSchemaElement,
  forceInstantDeletion: boolean,
) {
  const { isColumnDeletionInstant } = useMeshContext(SchemaBuilderContext);
  const instantDeletion = forceInstantDeletion || isColumnDeletionInstant;

  const { t } = useTranslation();
  const internOnDelete = useCallback(() => {
    onDelete(element.key);
  }, [element.key, onDelete]);

  const openDeleteDialog = useDeleteSchemaColumn({
    columnName: element.name,
    onDelete: internOnDelete,
  });

  if (element.elementType === "column" && element.nonDeletable) {
    return null;
  }

  return (
    <ActionSheetItem
      label={t("delete")}
      destructive
      onClick={instantDeletion ? internOnDelete : openDeleteDialog}
    />
  );
}
