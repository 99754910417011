import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { useMeshContext } from "@kraaft/helper-hooks";
import { filePicker } from "@kraaft/shared/core/modules/file/filePicker";
import { KSchemaElement } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import {
  Button,
  FontSize,
  Icon,
  Image,
  Radius,
  Spacing,
  Text,
  TextInput,
} from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";
import { SchemaBuilderContext } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

interface ElementOptionsDescriptionProps {
  element: KSchemaElement;
}

const MAX_DESCRIPTION_TEXT_THRESHOLD_LENGTH = 350;
const MAX_DESCRIPTION_TEXT_LENGTH = 400;

export const ElementOptionsDescription = ({
  element,
}: ElementOptionsDescriptionProps) => {
  const { t } = useTranslation();
  const { editDescription } = useMeshContext(SchemaBuilderContext);
  const description = element.description;

  const [descriptionText, setDescriptionText] = useState(
    description?.text ?? "",
  );

  const handleTextInputBlur = useCallback(() => {
    editDescription(element.key, { text: descriptionText });
  }, [descriptionText, editDescription, element.key]);

  const handlePickImage = useCallback(async () => {
    const image = await filePicker.pickImage();
    if (!image) {
      return;
    }
    editDescription(element.key, { image });
  }, [editDescription, element.key]);

  const handlePickDocument = useCallback(async () => {
    const document = await filePicker.pickDocument();
    if (!document) {
      return;
    }
    editDescription(element.key, { document });
  }, [editDescription, element.key]);

  const handleDeleteImage = useCallback(() => {
    editDescription(element.key, { image: null });
  }, [editDescription, element.key]);

  const handleDeleteDocument = useCallback(() => {
    editDescription(element.key, { document: null });
  }, [editDescription, element.key]);

  const documentName = description?.document?.filename;
  const imagePath = description?.image?.downloadUrl;

  const handleChangeText = useCallback((newText: string) => {
    setDescriptionText(newText.slice(0, MAX_DESCRIPTION_TEXT_LENGTH));
  }, []);

  return (
    <View style={styles.root}>
      <Box gap="S16">
        <Box gap="S8">
          <Text size="BODY" weight="bold" color="FONT_HIGH_EMPHASIS">
            {t("schemaColumnOptions.description.title")}
          </Text>
          <Box>
            <TextInput
              multiline
              containerStyle={styles.input}
              accessibilityLabel="description"
              onChangeText={handleChangeText}
              value={descriptionText}
              onBlur={handleTextInputBlur}
            />
            {descriptionText.length > MAX_DESCRIPTION_TEXT_THRESHOLD_LENGTH ? (
              <Text style={styles.lengthText} color="FONT_LOW_EMPHASIS">
                {descriptionText.length}/{MAX_DESCRIPTION_TEXT_LENGTH}
              </Text>
            ) : null}
          </Box>
        </Box>
        <Box>
          <Text size="BODY" weight="bold" color="FONT_HIGH_EMPHASIS">
            {t("schemaColumnOptions.description.image")}
          </Text>
          {imagePath ? (
            <Box mt="S8" row items="center" gap="S8">
              <Image
                preferredSize="small"
                source={{ uri: imagePath }}
                style={styles.image}
              />
              <Button
                icon="upload-cloud-01"
                variant="TERTIARY"
                onPress={handlePickImage}
              />
              <Button
                icon="trash-03"
                destructive
                variant="TERTIARY"
                onPress={handleDeleteImage}
              />
            </Box>
          ) : (
            <Button
              icon="image-plus"
              text="Ajouter une image"
              variant="QUATERNARY"
              style={styles.button}
              onPress={handlePickImage}
            />
          )}
        </Box>
        <Box>
          <Text size="BODY" weight="bold" color="FONT_HIGH_EMPHASIS">
            {t("schemaColumnOptions.description.document")}
          </Text>
          {documentName ? (
            <Box row items="center" gap="S8">
              <Box row items="center">
                <Icon name="file-04" />
                <Text>{documentName}</Text>
              </Box>
              <Button
                icon="upload-cloud-01"
                variant="TERTIARY"
                onPress={handlePickDocument}
              />
              <Button
                icon="trash-03"
                destructive
                variant="TERTIARY"
                onPress={handleDeleteDocument}
              />
            </Box>
          ) : (
            <Button
              icon="file-plus-02"
              text="Ajouter un document"
              variant="QUATERNARY"
              style={styles.button}
              onPress={handlePickDocument}
            />
          )}
        </Box>
      </Box>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: "100%",
  },
  input: {
    height: 150,
    width: "100%",
  },
  button: {
    marginRight: "auto",
  },
  image: {
    height: 100,
    width: "auto",
    maxWidth: "100%",
    borderRadius: Radius.SMALL,
  },
  lengthText: {
    position: "absolute",
    right: 0,
    bottom: -(FontSize.BODY + Spacing.S2),
  },
});
