import { nanoid } from "@kraaft/helper-functions";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";

export class BlobResolveError extends Error {}

export abstract class FileUploader<FileType extends ModernFile<LocalPath>> {
  abstract upload(params: {
    file: FileType;
    storagePath: string;
    uploadUrl: string;
  }): Promise<void>;

  /**
   * Uploads a file to the user's storage, returning the storage path
   */
  abstract uploadToUserSpace(
    file: ModernFile<LocalPath>,
    userId: string,
  ): Promise<string>;

  protected generateUserSpaceStoragePath(userId: string, filename: string) {
    return `users/${userId}/${nanoid()}/${filename}`;
  }
}
