import React from "react";
import { StyleSheet, View } from "react-native";

import { DetailsEditorDescription } from "@kraaft/shared/components/modular/details/editors/detailsEditorDescription";
import { MODULAR_LABEL_LIMIT } from "@kraaft/shared/constants/constants";
import { KSchemaElementDescription } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { textEllipsis } from "@kraaft/shared/core/utils";
import { Color, ColorStyle, IconAsText, Spacing, Text } from "@kraaft/ui";

interface EditorBoxProps {
  label: string;
  columnKey: string;
  children?: React.ReactNode | React.ReactNode[];
  isLocked: boolean;
  description: KSchemaElementDescription | undefined;
}

export const DetailsEditorBox = ({
  label,
  columnKey,
  children,
  isLocked,
  description,
}: EditorBoxProps) => {
  return (
    <View
      style={styles.container}
      nativeID={`editorKey-${columnKey}`}
      // fix this when on react-native-web 0.19
      {...{
        dataSet: {
          label: `editorLabel-${label}`,
        },
      }}
    >
      <Text weight="bold" size="BODY" color="FONT_HIGH_EMPHASIS">
        {isLocked && (
          <IconAsText iconName="locked-fill" style={styles.iconAsText} />
        )}
        {textEllipsis(label, MODULAR_LABEL_LIMIT.COLUMN_LABEL)}
      </Text>
      <DetailsEditorDescription description={description} />
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
    padding: Spacing.S16,
    gap: Spacing.S8,
  },

  iconAsText: {
    color: Color.YELLOW_EARTH,
    letterSpacing: 4,
  },
});
