import { useTranslation } from "react-i18next";

import { ActionSheetItem } from "@kraaft/ui";

interface UseOptionItemProps {
  openOptions(): void;
}

export function useOptionItem({ openOptions }: UseOptionItemProps) {
  const { t } = useTranslation();

  return (
    <ActionSheetItem
      label={t("schemaColumnOptions.contextMenuEntry")}
      onClick={openOptions}
    />
  );
}
