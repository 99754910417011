import { keyBy } from "lodash";
import { EventChannel, eventChannel } from "redux-saga";
import { put, select, spawn, take, takeEvery } from "typed-redux-saga/macro";

import { LibrarySchemaSource } from "@kraaft/shared/core/modules/librarySchema/librarySchema.actions";
import { OfflineLibrarySchemaStateActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.offline";
import {
  selectRawPrivateLibrarySchemas,
  selectRawPublicLibrarySchemas,
} from "@kraaft/shared/core/modules/librarySchema/librarySchema.selectors";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { PoolStateActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { selectCurrentPool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import {
  UserActions,
  UserStateActions,
} from "@kraaft/shared/core/modules/user/userActions";
import { selectCurrentUser } from "@kraaft/shared/core/modules/user/userSelectors";
import { isUserSuperadmin } from "@kraaft/shared/core/modules/user/userUtils";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { waitOnFor } from "@kraaft/shared/core/utils/sagas";

interface ChannelPayload {
  data: LibrarySchema[];
  from: LibrarySchemaSource;
}

export function* subscribeToLibrarySchemasSaga() {
  yield* spawn(subscribeIfSuperadmin);
  yield* spawn(subscribeToPublic);
  yield* spawn(subscribeToPrivate);
}

function* subscribeIfSuperadmin() {
  while (true) {
    yield* take(UserActions.userConnectedToFirebase);
    const currentUser = yield* waitOnFor(
      [UserStateActions.loggedUserReceived],
      selectCurrentUser,
    );

    if (!isUserSuperadmin(currentUser)) {
      continue;
    }

    const channel = eventChannel<ChannelPayload>((emit) =>
      Firestore.subscribeToSuperadminLibrarySchemas((data) =>
        emit({ data, from: "superadmin" }),
      ),
    );

    yield* takeEvery(channel, receiveLibrarySchemas);

    yield* take(UserActions.userDisconnectedFromFirebase);
    channel.close();
  }
}
function* subscribeToPublic() {
  while (true) {
    yield* take(UserActions.userConnectedToFirebase);

    const currentUser = yield* waitOnFor(
      [UserStateActions.loggedUserReceived],
      selectCurrentUser,
    );

    if (isUserSuperadmin(currentUser)) {
      continue;
    }

    const channel = eventChannel<ChannelPayload>((emit) =>
      Firestore.subscribeToPublicLibrarySchemas((data) =>
        emit({ data, from: "public" }),
      ),
    );

    yield* takeEvery(channel, receiveLibrarySchemas);

    yield* take(UserActions.userDisconnectedFromFirebase);
    channel.close();
  }
}
function* subscribeToPrivate() {
  while (true) {
    const currentUser = yield* waitOnFor(
      [UserStateActions.loggedUserReceived],
      selectCurrentUser,
    );

    if (isUserSuperadmin(currentUser)) {
      continue;
    }

    const pool = yield* waitOnFor(
      [PoolStateActions.setPools],
      selectCurrentPool,
    );
    const companyId = pool.companyId;

    let channel: EventChannel<any> | undefined;

    if (companyId) {
      channel = eventChannel<ChannelPayload>((emit) =>
        Firestore.subscribeToCompanyLibrarySchemas(companyId, (data) =>
          emit({ data, from: "private" }),
        ),
      );
      yield* takeEvery(channel, receiveLibrarySchemas);
    }

    yield* take([
      UserActions.userDisconnectedFromFirebase,
      PoolStateActions.setPoolLocation,
    ]);
    channel?.close();
  }
}

function* receiveLibrarySchemas(librarySchemas: {
  data: LibrarySchema[];
  from: LibrarySchemaSource;
}) {
  if (librarySchemas.from === "superadmin") {
    yield* put(
      OfflineLibrarySchemaStateActions.receive(
        keyBy(librarySchemas.data, (data) => data.id),
      ),
    );
  } else if (librarySchemas.from === "public") {
    const privateLibrarySchemas = yield* select(selectRawPrivateLibrarySchemas);
    yield* put(
      OfflineLibrarySchemaStateActions.receive({
        ...keyBy(privateLibrarySchemas, (it) => it.id),
        ...keyBy(librarySchemas.data, (data) => data.id),
      }),
    );
  } else if (librarySchemas.from === "private") {
    const publicLibrarySchemas = yield* select(selectRawPublicLibrarySchemas);
    yield* put(
      OfflineLibrarySchemaStateActions.receive({
        ...keyBy(publicLibrarySchemas, (it) => it.id),
        ...keyBy(librarySchemas.data, (data) => data.id),
      }),
    );
  }
}
