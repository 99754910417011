type KnownHost = "prod" | "preprod" | "dev" | "local";
type GetStorageUrlsByEnvType = Record<
  KnownHost,
  { crossDomain: string; intraDomain: string }
>;

// I don't know what else can I do without importing anything from shared
function getStorageUrlsByEnv(): GetStorageUrlsByEnvType {
  return {
    prod: {
      crossDomain: "https://storage.googleapis.com/kraaft-624e5.appspot.com/",
      intraDomain: "https://storage.kraaft.co/",
    },
    preprod: {
      crossDomain: "https://storage.googleapis.com/kraaft-preprod.appspot.com/",
      intraDomain: "https://preprod-storage.kraaft.co/",
    },
    dev: {
      crossDomain: "https://storage.googleapis.com/kraaft-dev.appspot.com/",
      intraDomain: "https://dev-storage.kraaft.co/",
    },
    local: {
      crossDomain: "https://storage.googleapis.com/",
      intraDomain: "http://127.0.0.1:9199/",
    },
  };
}

export function isKraaftUrl(url: string) {
  return Object.values(getStorageUrlsByEnv())
    .flatMap((env) => Object.values(env))
    .some((kraaftUrl) => url.startsWith(kraaftUrl));
}
