import { useCallback } from "react";
import { Platform, StyleSheet, View } from "react-native";

import { ImageManipulation } from "@kraaft/shared/components/carousel/placeholders/imageManipulation";
import { FilePath } from "@kraaft/shared/core/modules/file/file";
import {
  Carousel,
  CarouselItemRenderer,
  Image,
  Sheet,
  Spacing,
  useMaxSafeAreaInsets,
} from "@kraaft/ui";

interface Item {
  downloadUrl: FilePath;
  filename: string;
}

export const DetailsEditorFullscreenImageSheet = Sheet({
  default: "overlay",
}).create<Item>(({ Content }) => ({ filename, downloadUrl, onClose }) => {
  const handleRenderItem = useCallback<CarouselItemRenderer<Item>>(
    ({ item }) => {
      return (
        <View style={styles.item}>
          <ImageManipulation
            path={item.downloadUrl}
            mediaSize={undefined}
            name={filename}
          >
            <Image
              source={downloadUrl}
              contentFit="contain"
              preferredSize="large"
              style={styles.image}
            />
          </ImageManipulation>
        </View>
      );
    },
    [downloadUrl, filename],
  );

  const insets = useMaxSafeAreaInsets({
    top: Spacing.S8,
    bottom: Spacing.S8,
  });

  return (
    <Content>
      <Carousel
        items={[{ downloadUrl, filename }]}
        keyExtractor={(item) => item.downloadUrl}
        open
        onClose={onClose}
        renderItem={handleRenderItem}
        insets={insets}
      />
    </Content>
  );
});

const styles = StyleSheet.create({
  item: {
    flexGrow: 1,
    padding: Platform.select({ default: Spacing.NONE, web: Spacing.S32 }),
  },
  image: {
    height: "100%",
    width: "100%",
  },
});
