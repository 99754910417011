import { isDate, isInteger, isObject, isPlainObject, isString } from "lodash";

import {
  firestore,
  FirestoreTypes,
} from "@kraaft/shared/core/services/firestore/sdk";

import { FirestoreTimestamp, LegacyFirestoreTimestamp } from "./firestoreTypes";

export function isFirestoreTimestamp(
  timestamp: unknown,
): timestamp is FirestoreTypes.Timestamp {
  return isObject(timestamp) && "toDate" in timestamp;
}

export function isLegacyFirestoreTimestamp(
  // This is a typeguard
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  timestamp: any,
): timestamp is LegacyFirestoreTimestamp {
  return (
    isPlainObject(timestamp) &&
    isInteger(timestamp._seconds) &&
    isInteger(timestamp._nanoseconds)
  );
}

export function isValidDate(timestamp: string) {
  return !Number.isNaN(new Date(timestamp).getTime());
}

export function parseDate(timestamp: FirestoreTimestamp | undefined): Date {
  if (isDate(timestamp)) {
    return timestamp;
  }
  if (isString(timestamp) && isValidDate(timestamp)) {
    return new Date(timestamp);
  }
  if (isFirestoreTimestamp(timestamp)) {
    return timestamp.toDate();
  }
  if (isLegacyFirestoreTimestamp(timestamp)) {
    const { _seconds, _nanoseconds } = timestamp;
    const firestoreTimestamp = new firestore.Timestamp(_seconds, _nanoseconds);
    return firestoreTimestamp.toDate();
  }
  return new Date(0); // A lot of components don't know what to do if this function returns undefined.
}

export function parseDateIfNeeded(date: unknown) {
  return parseDate(date as any);
}
