import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { RoomFiltersValues } from "@kraaft/shared/components/filters/roomFilters.context";
import { HeaderPoolSelector } from "@kraaft/shared/components/headerPoolSelector";
import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { RoomSelectionFilterButton } from "@kraaft/shared/components/roomSelection/roomSelectionFilterButton";
import { RoomSelectionList } from "@kraaft/shared/components/roomSelection/roomSelectionList";
import { DEFAULT_ROOM_FILTERS } from "@kraaft/shared/core/modules/filter/filterReducers";
import {
  selectCurrentPoolLocation,
  selectUserPoolCount,
} from "@kraaft/shared/core/modules/pool/poolSelectors";
import { PoolLocation } from "@kraaft/shared/core/modules/pool/poolState";
import { RoomFiltersWithPool } from "@kraaft/shared/core/modules/room/selectors/utils";
import { useRoomCards } from "@kraaft/shared/core/modules/roomCard/hooks/useRoomCards";
import { PortalHost } from "@kraaft/ui";
import { UnlabeledTextInput } from "@kraaft/web/src/components/unlabeledTextInput";

import { CurrentStepProps } from "../../../multiStepDialogTypes";

import { inlineStyles, useStyles } from "./selectRoomStep.styles";

const PORTAL_HOSTNAME = "chooseRoomDialog";

export interface SelectRoomStepParams {
  roomId: string;
  handleForward: (selectedRoomId: string) => void;
  includePreserveCaptionStep: boolean;
}

export interface SelectRoomStepState {
  selectedRoomId?: string;
}

type SelectRoomStepProps = CurrentStepProps<
  SelectRoomStepParams,
  SelectRoomStepState
>;

const SelectRoomStep = (props: SelectRoomStepProps) => {
  const {
    onClose,
    step,
    setStep,
    roomId,
    handleForward,
    includePreserveCaptionStep,
  } = props;

  const { t } = useTranslation();

  const classes = useStyles();

  const userPoolCount = useSelector(selectUserPoolCount);
  const defaultPoolLocation = useSelector(selectCurrentPoolLocation);
  const [selected, setSelected] = useState<string[]>(
    step.state?.selectedRoomId ? [step.state.selectedRoomId] : [],
  );
  const [poolLocation, setPoolLocation] = useState<PoolLocation | undefined>(
    defaultPoolLocation,
  );
  const [roomFilters, setRoomFilters] =
    useState<RoomFiltersValues>(DEFAULT_ROOM_FILTERS);
  const [searchText, setSearchText] = useState("");

  const hasSelected = selected.length > 0;

  const filters = useMemo<RoomFiltersWithPool>(
    () => ({
      visibility: roomFilters.visibility,
      statusId: roomFilters.statusId,
      labelIds: roomFilters.labelIds,
      responsibleId: roomFilters.responsibleId,
      showArchived: roomFilters.showArchived,
      readingStatus: roomFilters.readingStatus,
      searchText,
      location: poolLocation,
    }),
    [
      poolLocation,
      roomFilters.labelIds,
      roomFilters.readingStatus,
      roomFilters.responsibleId,
      roomFilters.showArchived,
      roomFilters.statusId,
      roomFilters.visibility,
      searchText,
    ],
  );

  const {
    pinnedRoomCards,
    otherRoomCards,
    loadMore,
    isLoading: isLoadingMore,
  } = useRoomCards({ filters, filterIdPrefix: "forwardMessageSelectionStep" });

  const roomCards = useMemo(
    () => [...pinnedRoomCards, ...otherRoomCards],
    [otherRoomCards, pinnedRoomCards],
  );

  const goToNextStep = useCallback(() => {
    const selectedRoomId = selected[0];
    if (selectedRoomId) {
      setStep({
        index: 1,
        state: { selectedRoomId },
      });
    }
  }, [selected, setStep]);

  const handleSubmit = useCallback(() => {
    const selectedRoomId = selected[0];
    if (selectedRoomId) {
      handleForward(selectedRoomId);
    }
  }, [handleForward, selected]);

  const content = (
    <div className={classes.container}>
      <span className={classes.headerContentContainer}>
        <UnlabeledTextInput
          value={searchText}
          placeholder={t("searchRoom")}
          onChangeText={setSearchText}
          style={inlineStyles.input}
        />
        <span className={classes.headerRightIcons}>
          {userPoolCount > 1 && (
            <HeaderPoolSelector
              currentPoolLocation={poolLocation}
              onChangePoolLocation={setPoolLocation}
            />
          )}
          <RoomSelectionFilterButton
            poolId={poolLocation?.poolId}
            defaultRoomFilters={DEFAULT_ROOM_FILTERS}
            filters={roomFilters}
            setFilters={setRoomFilters}
          />
        </span>
      </span>
      <div className={classes.listContainer}>
        <RoomSelectionList
          roomCards={roomCards}
          hiddenRoomIds={[roomId]}
          selected={selected}
          setSelected={setSelected}
          isMultiple={false}
          minSelection={1}
          onEndReached={loadMore}
          isLoadingMore={isLoadingMore}
        />
      </div>
    </div>
  );

  const actions: FooterActions = [
    includePreserveCaptionStep
      ? {
          accessibilityLabel: t("validate"),
          text: t("validate"),
          onPress: goToNextStep,
          disabled: !hasSelected,
          variant: "PRIMARY",
        }
      : {
          icon: "send-03-fill",
          accessibilityLabel: t("send"),
          text: t("send"),
          onPress: handleSubmit,
          disabled: !hasSelected,
          variant: "PRIMARY",
        },
    {
      accessibilityLabel: t("cancel"),
      text: t("cancel"),
      onPress: onClose,
    },
  ];

  return (
    <>
      <KDialogContent
        title={t("sendInRoom")}
        content={content}
        actions={actions}
        fullHeight
      />
      <PortalHost hostname={PORTAL_HOSTNAME} />
    </>
  );
};

export { SelectRoomStep };
