import { memo, useCallback, useMemo } from "react";
import { View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import {
  GeolocationOpener,
  GeolocationOpenerProps,
} from "@kraaft/shared/components/geolocationOpener";
import { getResizedDimensions } from "@kraaft/shared/components/message/messageImage/messageImageUtils";
import {
  MessageRendererProps,
  useMessageStyles,
} from "@kraaft/shared/components/message/messageUtils";
import { MessageWithHeader } from "@kraaft/shared/components/message/messageWithHeader";
import { TextProcessor } from "@kraaft/shared/components/textProcessor/textProcessor";
import { MessageTypes } from "@kraaft/shared/core/modules/message";
import { messageHasReactions } from "@kraaft/shared/core/modules/message/messageUtils";
import { formatAddress } from "@kraaft/shared/core/utils";
import { Image, Text } from "@kraaft/ui";

import { styles as baseStyles } from "../message.styles";
import { styles } from "./messageGeolocation.styles";

type Props = MessageRendererProps<MessageTypes.GeolocationMessage> & {
  sizerWidth: number;
};

const MessageGeolocation_ = (props: Props) => {
  const { roomId, message, onLongPress, answer, sizerWidth } = props;
  const { isReply, thumbnail, geolocation } = message;

  const fileUrl =
    thumbnail.downloadUrl !== "optimistic" ? thumbnail.downloadUrl : undefined;

  const displayMap = thumbnail.downloadUrl !== "optimistic";
  const { textStyle, messageStyle } = useMessageStyles({
    isReply,
    hasReactions: messageHasReactions(message),
    isFontSizeLargeBody: true,
    sizerWidth,
  });
  const imageSize = getResizedDimensions(
    thumbnail.size ?? { width: 0, height: 0 },
    sizerWidth,
    isNative(),
  );

  const rootStyle = useMemo(
    () => [baseStyles.messageBox, messageStyle, { maxWidth: imageSize.width }],
    [imageSize.width, messageStyle],
  );

  const imageStyle = useMemo(
    () => [styles.image, { width: imageSize.width }],
    [imageSize.width],
  );

  const textWrapperStyle = useMemo(
    () => [textStyle, baseStyles.text],
    [textStyle],
  );

  const context = useMemo<GeolocationOpenerProps["context"]>(
    () => ({
      roomId,
      source: "message",
    }),
    [roomId],
  );

  const handleLongPress = useCallback(() => {
    onLongPress?.(message);
  }, [message, onLongPress]);

  return (
    <MessageWithHeader
      roomId={roomId}
      message={message}
      answerToMessage={answer}
      onLongPressPreview={handleLongPress}
    >
      <View style={rootStyle}>
        <GeolocationOpener
          geolocation={message.geolocation}
          context={context}
          onLongPress={handleLongPress}
        >
          {displayMap && (
            <Image
              source={fileUrl}
              contentFit="cover"
              preferredSize="medium"
              style={imageStyle}
            />
          )}

          <Text style={textWrapperStyle}>
            <TextProcessor textStyle={textStyle} textComponent={Text}>
              {formatAddress(geolocation)}
            </TextProcessor>
          </Text>
        </GeolocationOpener>
      </View>
    </MessageWithHeader>
  );
};

export const MessageGeolocation = memo(MessageGeolocation_);
