import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

import { isNative } from "@kraaft/helper-functions";
import { useBooleanState } from "@kraaft/helper-hooks";
import { DateTimePicker } from "@kraaft/shared/components/dateTimePicker";
import { EditorProps } from "@kraaft/shared/components/modular/details/editors/types";
import {
  EMPTY_LOCKED_RECORD_PLACEHOLDER,
  formatDateCell,
} from "@kraaft/shared/components/modular/details/utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { ColorStyle, LabelledActionCard } from "@kraaft/ui";

type Props = EditorProps<KColumnType.date>;

const DateEditor = (props: Props) => {
  const {
    editor: { label, disabled, value, setValue, sectionLockInfo },
    column,
    testID,
  } = props;
  const { t } = useTranslation();
  const dateRef = useRef(null);

  const [isDatepickerVisible, openDatePicker, closeDatePicker] =
    useBooleanState(false);

  const dateString = formatDateCell(value, column.displayTime);

  const handleChange = useCallback(
    (date: Date | undefined) => {
      closeDatePicker();
      setTimeout(() => setValue(date), 0); // prevent android datepicker reopen when validating date
    },
    [closeDatePicker, setValue],
  );

  const placeholder =
    dateString ??
    (!sectionLockInfo.isLocked
      ? t("modularity.selectDate")
      : EMPTY_LOCKED_RECORD_PLACEHOLDER);

  return (
    <>
      <LabelledActionCard
        id={`${testID}-${column.key}`}
        iconName={KSchemaUtils.getIcon(props.column)}
        label={placeholder}
        color={
          dateString
            ? ColorStyle.FONT_HIGH_EMPHASIS
            : ColorStyle.FONT_LOW_EMPHASIS
        }
        accessibilityLabel={label}
        onPress={openDatePicker}
        disabled={disabled || sectionLockInfo.isLocked}
        hideChevron={sectionLockInfo.isLocked}
      />
      {!isNative() && <div ref={dateRef} />}
      <DateTimePicker
        displayTime={column.displayTime}
        visible={isDatepickerVisible}
        date={value || new Date()}
        onClose={closeDatePicker}
        onChange={handleChange}
        anchorRef={dateRef}
      />
    </>
  );
};

export { DateEditor };
