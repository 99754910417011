import { createAction } from "@reduxjs/toolkit";

import {
  ReadingStatus,
  Visibility,
} from "@kraaft/shared/core/modules/filter/filterState";

interface SetRoomFilterSearchTextPayload {
  poolId: string;
  searchText: string;
}
export const setRoomFilterSearchText =
  createAction<SetRoomFilterSearchTextPayload>(
    "@filters/SET_ROOM_FILTER_SEARCH_TEXT",
  );

export const FilterStateActions = {
  setRoomFilters: createAction<{
    poolId: string;
    visibility: Visibility;
    statusId: string | undefined;
    labelIds: string[];
    responsibleId: string | undefined;
    showArchived: boolean;
    readingStatus: ReadingStatus;
  }>("@filter/SET_ROOM_FILTERS"),
  resetFilters: createAction<{ poolId: string }>("@filters/RESET_FILTERS"),
  setRoomReconciliatedFilters: createAction<{
    poolId: string;
    visibility: Visibility;
    statusId: string | undefined;
    labelIds: string[];
    responsibleId: string | undefined;
  }>("@filters/SET_ROOM_RECONCILIATED_FILTERS"),
  setRoomFilterVisibility: createAction<{
    poolId: string;
    visibility: Visibility;
  }>("@filter/SET_ROOM_FILTER_VISIBILITY"),
};
