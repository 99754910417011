import { createSelector } from "@reduxjs/toolkit";
import memoize from "lodash/memoize";

import { RoomType } from "@kraaft/shared/core/modules/room/roomState";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import {
  selectRoom,
  selectRoomUserHistory,
} from "@kraaft/shared/core/modules/room/selectors/directSelectors";
import { selectRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.selectors";
import { lodashKeyResolver } from "@kraaft/shared/core/utils/utils";

export const selectRoomOrRoomCard = memoize((roomId: string) =>
  createSelector(
    selectRoom(roomId),
    selectRoomCard(roomId),
    (room, roomCard) => [room, roomCard] as const,
  ),
);

export const selectUserRoomOrRoomCard = memoize(
  (roomId: string) =>
    createSelector(
      selectRoomUserHistory(roomId),
      selectRoomCard(roomId),
      (userRoom, roomCard) => [userRoom, roomCard] as const,
    ),
  lodashKeyResolver,
);

export const selectRoomNotificationFilter = memoize(
  (roomId: string) =>
    createSelector(selectUserRoomOrRoomCard(roomId), ([userRoom, roomCard]) => {
      if (userRoom) {
        return userRoom.notificationFilter ?? "inherit";
      }
      return roomCard && roomCard.type === "member"
        ? roomCard.notificationSource
        : "inherit";
    }),
  lodashKeyResolver,
);

export const selectIsUserMemberOfRoom = memoize(
  (roomId: string) =>
    createSelector(selectUserRoomOrRoomCard(roomId), ([userRoom, roomCard]) =>
      Boolean(userRoom || roomCard?.type === "member"),
    ),
  lodashKeyResolver,
);

export const selectIsEveryoneRoom = memoize((roomId: string) =>
  createSelector(selectRoomOrRoomCard(roomId), ([room, roomCard]) =>
    Boolean(room?.type === RoomType.EVERYONE || roomCard?.isEveryoneRoom),
  ),
);

export const selectRoomTitle = memoize((roomId: string) =>
  createSelector(selectRoomOrRoomCard(roomId), ([room, roomCard]) => {
    if (room) {
      return getExistingRoomRecordField(room.record.properties, "title") ?? "";
    }
    return roomCard?.title;
  }),
);

export const selectIsRoomArchivedForUser = memoize(
  (roomId: string) =>
    createSelector(
      selectUserRoomOrRoomCard(roomId),
      ([userRoom, roomCard]) =>
        userRoom?.isArchived ??
        (roomCard && roomCard.type === "member" && roomCard.isArchivedForUser),
    ),
  lodashKeyResolver,
);

export const selectIsRoomArchivedForAll = memoize((roomId: string) =>
  createSelector(
    selectRoomOrRoomCard(roomId),
    ([room, roomCard]) => room?.isArchivedForAll ?? roomCard?.isArchivedForAll,
  ),
);

export const selectRoomEmoji = memoize((roomId: string) =>
  createSelector(selectRoomOrRoomCard(roomId), ([room, roomCard]) => {
    return room?.emoji ?? roomCard?.emoji;
  }),
);
