import { StyleSheet, View } from "react-native";

import { ColorStyle, Preloader, Spacing } from "@kraaft/ui";

interface BidirectionalLoaderProps {
  nativeID?: string;
}

export const BidirectionalLoader = ({ nativeID }: BidirectionalLoaderProps) => {
  return (
    <View style={styles.root} nativeID={nativeID}>
      <Preloader color={ColorStyle.ACTION_CTA} />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    padding: Spacing.S16,
  },
});
