import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StatusBar } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { CarouselImage } from "@kraaft/shared/components/carousel/placeholders/carouselImage";
import { CarouselVideo } from "@kraaft/shared/components/carousel/placeholders/carouselVideo";
import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import { CarouselStateActions } from "@kraaft/shared/core/modules/carousel/carousel.actions";
import { UploadAttachmentContext } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { ModularFolderCarouselItem } from "@kraaft/shared/core/modules/modularFolder/components/modularFolderCarousel/modularFolderCarousel.types";
import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import {
  selectModularFolderCarousel,
  selectModularFolderCarouselData,
  selectModularFolderSchemaId,
} from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { GeoLocation } from "@kraaft/shared/core/types";
import {
  Carousel,
  Sheet,
  Spacing,
  useCarouselOpenClose,
  useMaxSafeAreaInsets,
} from "@kraaft/ui";
import { CarouselItemRenderer } from "@kraaft/ui/src/display/carousel/carousel.types";

function modularFolderAttachmentKeyExtractor(item: ModularFolderCarouselItem) {
  return item.attachment.id;
}

interface ModularFolderCarouselProps {
  roomId: string;
  modularFolderId: string;
  schemaId: string;
}

const ModularFolderCarouselSheet = Sheet({
  default: "overlay",
}).create<ModularFolderCarouselProps>(
  ({ Content }) =>
    ({ roomId, schemaId, modularFolderId, onClose }) => {
      const dispatch = useDispatch();
      const data = useSelector(selectModularFolderCarouselData);
      const { t } = useTranslation();

      const columnKey = data?.column?.key;
      const isColumnPhotoQualityHD =
        data?.column?.type === KColumnType.attachment &&
        data?.column.photoQualityHD;

      const uploadContext = useMemo<UploadAttachmentContext>(
        () => ({
          type: "modularFolder",
          roomId,
          schemaId,
          modularFolderId,
          columnKey,
          isPhotoQualityHD: isColumnPhotoQualityHD,
        }),
        [columnKey, isColumnPhotoQualityHD, modularFolderId, roomId, schemaId],
      );

      const handleGeolocationChange = useCallback(
        (
          item: ModularFolderCarouselItem,
          location: GeoLocation | null | undefined,
        ) => {
          if (!data?.column) {
            return;
          }
          dispatch(
            OfflineModularFolderActions.updateAttachmentGeolocation({
              id: modularFolderId,
              attachmentId: item.attachment.id,
              columnKey: data.column.key,
              geolocation: location ?? undefined,
            }),
          );
        },
        [data?.column, dispatch, modularFolderId],
      );

      const handleDelete = useCallback(
        (item: ModularFolderCarouselItem) => {
          const title =
            item.attachment.type === "image"
              ? t("confirmDeletePhotoTitle")
              : t("confirmDeleteVideoTitle");
          const message =
            item.attachment.type === "image"
              ? t("confirmDeletePhotoFromFolderMessage")
              : t("confirmDeleteVideoFromFolderMessage");
          newConfirmationAlert({
            title,
            message,
            onConfirm: () => {
              dispatch(
                OfflineModularFolderActions.deleteAttachment({
                  id: modularFolderId,
                  attachmentId: item.attachment.id,
                  columnKey,
                }),
              );
            },
          });
        },
        [columnKey, dispatch, modularFolderId, t],
      );

      const renderItem = useCallback<
        CarouselItemRenderer<ModularFolderCarouselItem>
      >(
        ({ index, item }) => {
          if (item.attachment.type === "image") {
            return (
              <CarouselImage
                index={index}
                item={item}
                attachment={item.attachment}
                roomId={roomId}
                uploadContext={uploadContext}
                onClose={onClose}
                onLocationChange={handleGeolocationChange}
                onDelete={handleDelete}
              />
            );
          }
          if (item.attachment.type === "video") {
            return (
              <CarouselVideo
                index={index}
                item={item}
                attachment={item.attachment}
                roomId={roomId}
                uploadContext={uploadContext}
                onClose={onClose}
                onLocationChange={handleGeolocationChange}
                onDelete={handleDelete}
              />
            );
          }
          return null;
        },
        [onClose, handleDelete, handleGeolocationChange, roomId, uploadContext],
      );

      const insets = useMaxSafeAreaInsets({
        top: Spacing.S8,
        bottom: Spacing.S8,
      });

      if (!data) {
        return null;
      }

      return (
        <Content>
          <StatusBar backgroundColor="black" barStyle="light-content" />
          <Carousel
            initialIndex={data.initialIndex}
            items={data.attachments}
            open
            onClose={onClose}
            keyExtractor={modularFolderAttachmentKeyExtractor}
            renderItem={renderItem}
            insets={insets}
          />
        </Content>
      );
    },
);

export const ModularFolderCarousel = () => {
  const dispatch = useDispatch();
  const modularFolderCarousel = useSelector(selectModularFolderCarousel);
  const schemaId = useSelector(
    selectModularFolderSchemaId(
      modularFolderCarousel?.show ? modularFolderCarousel.folderId : undefined,
    ),
  );
  const { open, onClose } = useCarouselOpenClose(modularFolderCarousel?.show);

  const closeCarousel = useCallback(() => {
    dispatch(CarouselStateActions.close());
  }, [dispatch]);

  const { element } = ModularFolderCarouselSheet.use({
    open,
    onClose,
    onClosed: closeCarousel,
    roomId: modularFolderCarousel?.show ? modularFolderCarousel.roomId : "",
    schemaId,
    modularFolderId: modularFolderCarousel?.show
      ? modularFolderCarousel.folderId
      : "",
  });

  if (!modularFolderCarousel?.show) {
    return null;
  }

  return element;
};
