import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { fileUpload } from "@kraaft/shared/core/modules/file/fileUploader";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { Api } from "@kraaft/shared/core/services/api";
import { EditOneOperationBuilder } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations/editOne.operation";

export const addReportTemplateOperation =
  EditOneOperationBuilder.create<LibrarySchema>()
    .payload<{
      name: string;
      librarySchemaId: string;
      file: ModernFile<LocalPath>;
      forceAdd: boolean;
    }>()
    .expected((aggregate, payload) => {
      aggregate.reportTemplates ??= [];
      aggregate.reportTemplates.push({
        id: "temp",
        enabled: true,
        variant: "custom",
        name: payload.name,
        index: aggregate.reportTemplates.length,
        updatedAt: new Date(),
        format: payload.file.filename.endsWith(".docx") ? "docx" : "xlsx",
        file: {
          filename: payload.name,
          downloadUrl: payload.file.path,
        },
      });
      return aggregate;
    })
    .mutate(async (payload) => {
      const { librarySchemaId, name, file, forceAdd } = payload;

      const storagePathPayload = {
        librarySchemaId,
        filename: file.filename,
      };
      const uploadPath =
        await Api.generateLibrarySchemaReportTemplateUploadPath(
          storagePathPayload,
        );

      await fileUpload.upload({
        file,
        storagePath: uploadPath.storagePath,
        uploadUrl: uploadPath.uploadUrl,
      });

      const { updatedAt } = await Api.addLibrarySchemaReportTemplate({
        librarySchemaId,
        name,
        storagePath: uploadPath.storagePath,
        forceAdd,
      });
      return { updatedAt };
    });
