import {
  Attachment,
  AudioAttachment,
  DocumentAttachment,
  ImageAttachment,
  VideoAttachment,
} from "../../modules/folder/attachmentTypes";
import {
  FirestoreAttachment,
  FirestoreAudioAttachment,
  FirestoreImageAttachment,
  FirestoreVideoAttachment,
} from "./firestoreTypes";
import { parseDate } from "./parseDate";
import { prepareDownloadUrl } from "./prepareDownloadUrl";

// eslint-disable-next-line complexity
export function normalizeAttachment<T extends FirestoreAttachment>(
  document: T,
): Attachment | undefined {
  const makeBaseAttachment = () => ({
    id: document.id,
    type: document.type,
    original: {
      downloadUrl: prepareDownloadUrl(document.original.downloadUrl),
      filename: document.original.filename,
    },
    createdAt: parseDate(document.createdAt),
    senderUserId: document.senderUserId,
    messagePath: document.messagePath,
  });

  switch (document.type) {
    case "image": {
      const imageDocument = document as FirestoreImageAttachment;
      const imageAttachment: ImageAttachment = {
        ...makeBaseAttachment(),
        type: "image",
        caption: imageDocument.caption,
        size: imageDocument.original.size,
        captionModifiedAt:
          imageDocument.captionModifiedAt &&
          parseDate(imageDocument.captionModifiedAt),
        captionModifiedBy: imageDocument.captionModifiedBy,
        geolocation: imageDocument.geolocation
          ? imageDocument.geolocation
          : imageDocument.coords
            ? { coords: imageDocument.coords }
            : undefined,
      };
      return imageAttachment;
    }
    case "video": {
      const videoDocument = document as FirestoreVideoAttachment;
      const videoAttachment: VideoAttachment = {
        ...makeBaseAttachment(),
        type: "video",
        caption: videoDocument.caption,
        captionModifiedAt:
          videoDocument.captionModifiedAt &&
          parseDate(videoDocument.captionModifiedAt),
        captionModifiedBy: videoDocument.captionModifiedBy,
        geolocation: videoDocument.geolocation
          ? videoDocument.geolocation
          : videoDocument.coords
            ? { coords: videoDocument.coords }
            : undefined,
        converted: {
          mp4: videoDocument.converted.mp4
            ? {
                downloadUrl: prepareDownloadUrl(
                  videoDocument.converted.mp4.downloadUrl,
                ),
                filename: videoDocument.converted.mp4.filename,
              }
            : undefined,
        },
      };
      return videoAttachment;
    }
    case "document": {
      const documentAttachment: DocumentAttachment = {
        ...makeBaseAttachment(),
        type: "document",
      };
      return documentAttachment;
    }
    case "audio": {
      const audioDocument = document as FirestoreAudioAttachment;
      const audioAttachment: AudioAttachment = {
        ...makeBaseAttachment(),
        type: "audio",
        transcription: audioDocument.transcription,
        transcriptionLocale: audioDocument.transcriptionLocale,
        transcriptionModifiedAt:
          audioDocument.transcriptionModifiedAt &&
          parseDate(audioDocument.transcriptionModifiedAt),
      };
      return audioAttachment;
    }
    default:
      // future unknown types
      return undefined;
  }
}
