import React, { useCallback, useMemo } from "react";

import { GeoCoordinates } from "@kraaft/shared/core/types";

import { usePlaceholder } from "./hooks/usePlaceholder";
import { isKraaftUrl } from "./utils/storageUrls";
import type { ImageProps } from ".";
import { ImageImplementation } from "./imageImplementation";
import { ImageLoadedData } from "./imageImplementation.types";
import { ImageFormat, ImageProxyBuilder } from "./imageProxyBuilder";
import { preferredSizeByName, preferredSizeNameKeys } from "./utils";

export type ImageDynamicSize = Omit<ImageProps, "source" | "local"> & {
  source: ImageProps["source"];
  geolocation?: GeoCoordinates;
  format?: ImageFormat;
  quality?: number;
  debugProxy?: boolean;
};

const ImageDynamicSize_ = ({
  source,
  preferredSize,
  format,
  quality,
  geolocation,
  debugProxy,
  onLoad,
  contentFit,
  placeholder,
  placeholderContentFit: basePlaceholderContentFit,
  style,
  fadeDuration,
  testID,
  alt,
}: ImageDynamicSize) => {
  const originalSourceUrl =
    typeof source === "object" ? source?.uri ?? undefined : source;

  const setImageSize = useCallback(
    (imageProxyBuilder: ImageProxyBuilder, width: number, height: number) => {
      if (contentFit === "cover") {
        return imageProxyBuilder.setCoverMax(width, height);
      }
      return imageProxyBuilder.setContainMax(width, height);
    },
    [contentFit],
  );

  const computedSource = useMemo(() => {
    if (!originalSourceUrl) {
      return undefined;
    }

    if (typeof originalSourceUrl === "number") {
      return originalSourceUrl;
    }

    if (!originalSourceUrl.startsWith("http")) {
      return originalSourceUrl;
    }

    if (!isKraaftUrl(originalSourceUrl)) {
      return originalSourceUrl;
    }

    const imageBuilder = ImageProxyBuilder.fromUrl(originalSourceUrl)
      .setGeolocation(geolocation)
      .setFormat(format)
      .setQuality(quality)
      .setDebugMode(debugProxy);

    if (preferredSize === "original" || !preferredSize) {
      return imageBuilder.buildUrl();
    }

    if (typeof preferredSize === "object") {
      return setImageSize(
        imageBuilder,
        preferredSize.width,
        preferredSize.height,
      ).buildUrl();
    }
    if (preferredSizeNameKeys.includes(preferredSize)) {
      const size = preferredSizeByName[preferredSize];
      return setImageSize(imageBuilder, size.width, size.height).buildUrl();
    }
    return undefined;
  }, [
    originalSourceUrl,
    geolocation,
    format,
    quality,
    debugProxy,
    preferredSize,
    setImageSize,
  ]);

  const { placeholder: smallImagePlaceholder, registerImageLoaded } =
    usePlaceholder(computedSource);

  const placeholderContentFit = (() => {
    if (basePlaceholderContentFit) {
      return basePlaceholderContentFit;
    }
    return contentFit;
  })();

  const onImageLoad = useCallback(
    (event: ImageLoadedData) => {
      registerImageLoaded(event.source, {
        width: event.width,
        height: event.height,
      });
      onLoad?.(event);
    },
    [registerImageLoaded, onLoad],
  );

  return (
    <ImageImplementation
      alt={alt}
      style={style}
      source={computedSource}
      contentFit={contentFit}
      placeholder={placeholder ?? smallImagePlaceholder}
      placeholderContentFit={placeholderContentFit}
      onImageLoaded={onImageLoad}
      fadeDuration={fadeDuration}
      testID={testID}
    />
  );
};

export const ImageDynamicSize = React.memo(ImageDynamicSize_);
