import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { orderBy } from "lodash";

import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { Pill } from "@kraaft/shared/components/pill";
import { Toggle } from "@kraaft/shared/components/toggle";
import { useDictCompanies } from "@kraaft/shared/core/modules/company/hooks/useCompanies";
import { LibrarySchemaActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.actions";
import { getDuplicateLibrarySchemaLoaderId } from "@kraaft/shared/core/modules/librarySchema/librarySchema.actions.utils";
import { OfflineLibrarySchemaActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.offline";
import {
  LibrarySchema,
  LibrarySchemaLanguage,
} from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { KSchemaColors } from "@kraaft/shared/core/modules/schema/schema.colors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { useLoaderState } from "@kraaft/shared/core/utils/useLoader";
import { Icon } from "@kraaft/ui";
import { LegacyContextMenuButton } from "@kraaft/web/src/components/contextMenuButton";
import { DraggableLineDecoration } from "@kraaft/web/src/components/draggableLineDecoration";

import { useStyles } from "./orderableLibrarySchemaItem.styles";

const OrderableLibrarySchemaItem = (librarySchema: LibrarySchema) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigationService = useNavigationService();
  const companies = useDictCompanies();

  const { loading: isDuplicateButtonLoading } = useLoaderState(
    getDuplicateLibrarySchemaLoaderId(librarySchema.id),
  );

  const handleRowPress = useCallback(() => {
    navigationService.navigate("LibrarySchemaBuilder", {
      librarySchemaId: librarySchema.id,
    });
  }, [librarySchema.id, navigationService]);

  const handleDuplicate = useCallback(
    (language: LibrarySchemaLanguage) => () => {
      dispatch(
        LibrarySchemaActions.duplicateLibrarySchema({
          librarySchemaId: librarySchema.id,
          targetLanguage: language,
        }),
      );
    },
    [dispatch, librarySchema.id],
  );

  const handleEnabledChanged = useCallback(
    (newEnabled: boolean) => {
      dispatch(
        OfflineLibrarySchemaActions.edit({
          id: librarySchema.id,
          update: {
            enabled: newEnabled,
          },
        }),
      );
    },
    [dispatch, librarySchema.id],
  );

  const handleDelete = useCallback(() => {
    function deleteLibrarySchema() {
      dispatch(
        OfflineLibrarySchemaActions.delete({
          id: librarySchema.id,
        }),
      );
    }
    if (librarySchema.enabled) {
      newConfirmationAlert({
        title: t("deleteLibrarySchemaConfirmation"),
        onConfirm: deleteLibrarySchema,
      });
    } else {
      deleteLibrarySchema();
    }
  }, [dispatch, librarySchema.enabled, librarySchema.id, t]);

  const actions = useMemo<ActionSheetItem[]>(() => {
    const languageList = orderBy(
      Object.values(LibrarySchemaLanguage),
      [
        (language) => language === librarySchema.language,
        (language) => t(`locale.${language}`),
      ],
      ["desc", "asc"],
    );

    return [
      ...languageList.map<ActionSheetItem>((language) => {
        const isCurrentLanguage = language === librarySchema.language;
        return {
          label: isCurrentLanguage
            ? t("schemaLibrary.configure.row.duplicate")
            : t("schemaLibrary.configure.row.copyIn", {
                name: t(`locale.${language}`),
              }),
          icon: isCurrentLanguage ? "copy-03" : "copy-07",
          onPress: handleDuplicate(language),
          disabled: isDuplicateButtonLoading,
        };
      }),
      {
        label: t("schemaLibrary.configure.row.delete"),
        style: "destructive",
        icon: "trash-01",
        onPress: handleDelete,
      },
    ];
  }, [
    t,
    handleDelete,
    librarySchema.language,
    handleDuplicate,
    isDuplicateButtonLoading,
  ]);

  const librarySchemaCompany = librarySchema.companyId
    ? companies[librarySchema.companyId]
    : undefined;

  return (
    <>
      <DraggableLineDecoration>
        <div className={classes.row} onClick={handleRowPress}>
          <div className={classes.iconContainer}>
            <Icon
              name={KSchemaUtils.getSchemaIconName(librarySchema.schema.icon)}
            />
          </div>
          <span className={classes.name}>{librarySchema.schema.name}</span>
          <span className={classes.actionsContainer}>
            {librarySchema.companyId && (
              <Pill
                content={librarySchemaCompany?.name ?? librarySchema.companyId}
                color={librarySchemaCompany?.color ?? KSchemaColors.Colors[5]}
                size="small"
              />
            )}
            <Toggle
              value={librarySchema.enabled}
              setValue={handleEnabledChanged}
            />
            <LegacyContextMenuButton actions={actions} vertical />
          </span>
        </div>
      </DraggableLineDecoration>
    </>
  );
};

export { OrderableLibrarySchemaItem };
