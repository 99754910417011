import { fromPairs, keyBy, toInteger } from "lodash";
import { Dictionary, SafeDictionary } from "ts-essentials";

import { compactMap } from "@kraaft/helper-functions";
import * as MessageTypes from "@kraaft/shared/core/modules/message/messageState";
import { FirestoreTypes } from "@kraaft/shared/core/services/firestore/sdk";

import { StorageImagePreview } from "../../modules/folder/attachmentTypes";
import * as Types from "./firestoreTypes";
import { normalizeAttachment } from "./normalizeAttachment";
import { parseDate } from "./parseDate";
import { prepareDownloadUrl } from "./prepareDownloadUrl";

function normalizeReaction(
  reaction: Types.MessageReaction,
): MessageTypes.MessageReaction {
  return {
    emoji: reaction.emoji,
    reactedAt: parseDate(reaction.reactedAt),
  };
}

function normalizeReactions(
  reactions: Types.FirestoreUserMessage["reactions"],
): MessageTypes.UserMessage["reactions"] {
  if (!reactions) {
    return {};
  }
  return fromPairs(
    Object.entries(reactions).map(([key, value]) => [
      key,
      normalizeReaction(value),
    ]),
  );
}

function normalizeImagePreview(
  firestorePreview: Types.FirestoreStorageImagePreview,
): StorageImagePreview {
  return {
    size: {
      width: toInteger(firestorePreview.size?.width),
      height: toInteger(firestorePreview.size?.height),
    },
    downloadUrl: prepareDownloadUrl(firestorePreview.file.downloadUrl),
    filename: firestorePreview.file.filename,
  };
}

function normalizePreviews(
  previews: SafeDictionary<
    Types.FirestoreStorageImagePreview,
    "original" | "medium"
  >,
) {
  const images: Dictionary<StorageImagePreview> = {};

  for (const preview of Object.values(previews)) {
    if (preview !== undefined && preview.format !== "small") {
      images[preview.format] = normalizeImagePreview(preview);
    }
  }

  return images;
}

// eslint-disable-next-line complexity
export function normalizeMessage(
  id: string,
  data: Types.FirestoreMessage,
  userId: string | undefined,
  lastMessage: MessageTypes.LastMessage | undefined,
) {
  let newLastMessage = lastMessage;
  let message: MessageTypes.Message | undefined;

  const createdAt = parseDate(data.createdAt);
  const baseMessage = {
    id: id,
    senderId: data.senderId,
    createdAt: createdAt,
    updatedAt: parseDate(data.updatedAt),
  };

  if (
    !newLastMessage ||
    newLastMessage.createdAt.getTime() < createdAt.getTime()
  ) {
    newLastMessage = baseMessage;
  }

  if (data.type === "log") {
    switch (data.event.type) {
      case "folderCompleted":
      case "folderUnchecked":
      case "folderCreated":
      case "internalMemberJoinedPool":
      case "workflowMessage":
        message = { ...baseMessage, type: "log", event: data.event };
        break;
    }
  } else {
    const baseUserMessage = {
      ...baseMessage,
      optimisticId: data.optimisticId,
      answerTo: data.answerTo,
      forwarded: data.forwarded,
      deletedBy: data.deletedBy,
      deletedAt: data.deletedAt && parseDate(data.deletedAt),
      modularObjects: data.modularObjects,
      sendingStatus: "persisted" as const,
      isReply: !(data.senderId === userId),
      reactions: normalizeReactions(data.reactions),
    };

    if (data.type === "image") {
      const { attachment } = data;

      message = {
        ...baseUserMessage,
        type: "image",
        attachment: normalizeAttachment(attachment),
      } as MessageTypes.ImageMessage;
    } else if (data.type === "audio") {
      const { attachment } = data;

      message = {
        ...baseUserMessage,
        type: "audio",
        attachment: normalizeAttachment(attachment),
      } as MessageTypes.AudioMessage;
    } else if (data.type === "video") {
      const { attachment } = data;

      message = {
        ...baseUserMessage,
        type: "video",
        attachment: normalizeAttachment(attachment),
      } as MessageTypes.VideoMessage;
    } else if (data.type === "document") {
      const { attachment } = data;

      message = {
        ...baseUserMessage,
        type: "document",
        attachment: normalizeAttachment(attachment),
      } as MessageTypes.DocumentMessage;
    } else if (data.type === "text") {
      message = {
        ...baseUserMessage,
        type: "text",
        text: data.text,
        textModifiedAt: data.textModifiedAt && parseDate(data.textModifiedAt),
      };
    } else if (data.type === "geolocation") {
      message = {
        ...baseUserMessage,
        type: "geolocation",
        geolocation: data.geolocation,
        thumbnail: normalizeImagePreview(data.thumbnail),
      };
    }
  }

  // ignore unknown messages

  return { lastMessage: newLastMessage, message };
}

export function normalizeMessages(
  docs: FirestoreTypes.QueryDocumentSnapshot[],
  userId: string | undefined,
): {
  messages: Record<string, MessageTypes.Message>;
  lastMessage: MessageTypes.LastMessage | undefined; // can be different from last message createdAt if message type is unknown
} {
  let lastMessage: MessageTypes.LastMessage | undefined;
  const messages: MessageTypes.Message[] = compactMap(docs, (doc) => {
    const result = normalizeMessage(
      doc.id,
      doc.data() as Types.FirestoreMessage,
      userId,
      lastMessage,
    );
    lastMessage = result.lastMessage;
    return result.message;
  });

  return {
    messages: keyBy(messages, (message) => message.id),
    lastMessage,
  };
}
