export const poolAcquisitionSources = [
  "wordOfMouth",
  "linkedin",
  "facebook",
  "instagram",
  "youtube",
  "google",
  "newspapers",
  "tiktok",
] as const;

export type PoolAcquisitionSource =
  | (typeof poolAcquisitionSources)[number]
  | `other:${string}`;

export function isOtherAcquisitionSource(
  acquisitionSource: string,
): acquisitionSource is `other:${string}` {
  return acquisitionSource.startsWith("other:");
}
