import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ImageStyle } from "expo-image";

import { SignatureViewer } from "@kraaft/shared/components/signature/signatureViewer";
import { useDeleteSignature } from "@kraaft/shared/components/signature/signatureViewer/useDeleteSignature";
import { ImageAttachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { SectionLockInfo } from "@kraaft/shared/core/modules/schema/lockInfo.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { Button, Image, morphClassNameAsStyle, Sheet } from "@kraaft/ui";
import { IBasicCellProps } from "@kraaft/web/src/components/modularTable/components/fields/_basicCell/basicCellTypes";

import { useStyles } from "./basicCell.styles";

interface SignatureViewerSheetProps {
  value: ImageAttachment;
  column: KSchemaColumn<KColumnType.signature>;
  rowId: string;
  sectionLockInfo: SectionLockInfo;
}

const SignatureViewerSheet = Sheet({
  web: "popup",
})
  .create<SignatureViewerSheetProps>(
    ({ Paper, Header, Content, Footer }) =>
      ({ value, column, rowId, sectionLockInfo }) => {
        const { t } = useTranslation();
        const dispatch = useDispatch();

        const deleteSignature = useCallback(() => {
          dispatch(
            OfflineModularFolderActions.edit({
              editions: [
                {
                  id: rowId,
                  properties: [
                    [
                      column.key,
                      {
                        columnType: KColumnType.signature,
                        value: undefined,
                      },
                    ],
                  ],
                },
              ],
            }),
          );
        }, [column, dispatch, rowId]);

        const { dialog: deleteDialog, askDelete } =
          useDeleteSignature(deleteSignature);

        return (
          <>
            <Paper>
              <Header>{t("signature.word")}</Header>
              <Content>
                <SignatureViewer
                  attachment={value}
                  onDelete={askDelete}
                  hideDeleteButton
                />
              </Content>
              {!sectionLockInfo.isLocked && (
                <Footer>
                  <Button
                    accessibilityLabel={t("signature.viewer.erase")}
                    text={t("signature.viewer.erase")}
                    onPress={askDelete}
                    icon="trash-03"
                    destructive
                  />
                </Footer>
              )}
            </Paper>
            {deleteDialog}
          </>
        );
      },
  )
  .withDefaults({ size: "SMALL" });

export const SignatureCell = React.forwardRef<
  HTMLDivElement,
  IBasicCellProps<KColumnType.signature>
>(({ value, column, row, sectionLockInfo }, ref) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const { element, open } = SignatureViewerSheet.use({
    column,
    rowId: row.id,
    sectionLockInfo,
  });

  const handlePress = useCallback(() => {
    if (value === undefined) {
      return;
    }
    open({ value });
  }, [open, value]);

  const imageStyle = useMemo<ImageStyle>(() => {
    return morphClassNameAsStyle(styles.image) as ImageStyle;
  }, [styles.image]);

  return (
    <>
      <div className={styles.root} ref={ref}>
        {value && (
          <button type="button" onClick={handlePress} className="no-button">
            <Image
              style={imageStyle}
              preferredSize="thumbnail"
              source={value.original.downloadUrl}
              alt="signature"
            />
          </button>
        )}
      </div>
      {element}
    </>
  );
});
