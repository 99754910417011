import { RoomFilters } from "@kraaft/shared/core/modules/filter/filterState";
import { PoolLocation } from "@kraaft/shared/core/modules/pool/poolState";
import { SubscribeToRoomsPayload } from "@kraaft/shared/core/modules/room/roomActions";

export type RoomFilterIdParams = SubscribeToRoomsPayload | { poolId: string };

export interface RoomFiltersWithPool extends RoomFilters {
  location: PoolLocation | undefined;
}

export const computeRoomSubscriptionFilterId = (
  payload: RoomFilterIdParams,
) => {
  const filterId =
    "filters" in payload
      ? `, visibility=${payload.filters.visibility}, statusId=${
          payload.filters.statusId
        }, labelIds=${payload.filters.labelIds.join(",")}, responsibleId=${
          payload.filters.responsibleId
        }, showArchived=${payload.filters.showArchived}`
      : "";
  return `sub-poolId=${payload.poolId}${filterId}`;
};
