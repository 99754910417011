import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { LibrarySchemaStateActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.actions";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { HoverState } from "@kraaft/shared/core/modules/schema/schema.offline";
import { optimisticSchemaAddColumn } from "@kraaft/shared/core/modules/schema/schema.optimisticHelper";
import { Api } from "@kraaft/shared/core/services/api";
import { HttpError } from "@kraaft/shared/core/services/firebase/httpError";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { getStore } from "@kraaft/shared/core/store";
import { EditOneOperationBuilder } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations/editOne.operation";

export const addSchemaColumnOperation =
  EditOneOperationBuilder.create<LibrarySchema>()
    .payload<{
      column: KSchemaColumn;
      placement: HoverState;
      index: number;
      containerKey: string;
    }>()
    .expected((aggregate, payload) => {
      optimisticSchemaAddColumn(aggregate.schema, {
        column: { ...payload.column },
        containerKey: payload.containerKey,
        placement: payload.placement,
      });
      return aggregate;
    })
    .mutate(async (payload) => {
      try {
        const { columnKey, updatedAt } = await Api.addLibrarySchemaColumn({
          librarySchemaId: payload.id,
          name: payload.column.name,
          definition: payload.column,
          index: payload.index,
          parentSectionKey: payload.containerKey,
        });

        getStore().dispatch(
          LibrarySchemaStateActions.setCreatedKey({
            librarySchemaId: payload.id,
            columnKey: columnKey,
          }),
        );

        return { updatedAt };
      } catch (e) {
        if (HttpError.isHttpErrorWithCode(e, "MaxColumnCountExceededError")) {
          getStore().dispatch(
            showError({
              title: i18n.t("formBuilder.schema.error.maxColumnCountExceeded"),
            }),
          );
        }
        throw e;
      }
    });
