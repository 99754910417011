import mime from "mime";

import { ModernFileHelper } from "@kraaft/shared/core/modules/file/file";
import { fileAllocator } from "@kraaft/shared/core/modules/file/fileAllocator";
import { imageHelper } from "@kraaft/shared/core/modules/file/imageHelper";

import { FilePicker } from "./filePicker";

export class WebFilePicker implements FilePicker {
  private triggerWebFileInput(accept: string, multiple: boolean) {
    return new Promise<Array<File> | undefined>((resolve) => {
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.id = "web-file-input";
      input.type = "file";
      input.accept = accept;
      input.multiple = multiple;
      input.click();

      function handler() {
        if (!input.files) {
          resolve(undefined);
        } else {
          resolve([...input.files]);
        }
        input.remove();
      }

      input.addEventListener("cancel", handler);
      input.addEventListener("change", handler);
    });
  }

  async pickImage() {
    const files = await this.triggerWebFileInput("image/*", false);
    if (!files) {
      return undefined;
    }
    const [first] = files;
    if (!first) {
      return undefined;
    }

    const browserPath = fileAllocator.fromWebFile(first);
    return ModernFileHelper.image(
      first.name,
      fileAllocator.fromWebFile(first),
      {
        size: first.size,
        quality: await imageHelper.getQuality(browserPath),
      },
    );
  }

  async pickImages() {
    const files = await this.triggerWebFileInput("image/*", true);
    if (!files) {
      return undefined;
    }
    return Promise.all(
      files.map(async (file) => {
        const browserPath = fileAllocator.fromWebFile(file);
        return ModernFileHelper.image(file.name, browserPath, {
          size: file.size,
          quality: await imageHelper.getQuality(browserPath),
        });
      }),
    );
  }

  async pickMedia() {
    const files = await this.triggerWebFileInput("image/*,video/*", false);
    if (!files) {
      return undefined;
    }
    const [first] = files;
    if (!first) {
      return undefined;
    }

    const browserPath = fileAllocator.fromWebFile(first);
    if (mime.getType(first.name) === "image") {
      return ModernFileHelper.image(
        first.name,
        fileAllocator.fromWebFile(first),
        {
          size: first.size,
          quality: await imageHelper.getQuality(browserPath),
        },
      );
    }
    return ModernFileHelper.video(first.name, browserPath, {
      size: first.size,
    });
  }

  async pickMedias() {
    const files = await this.triggerWebFileInput("image/*,video/*", true);
    if (!files) {
      return undefined;
    }
    return Promise.all(
      files.map(async (file) => {
        const browserPath = fileAllocator.fromWebFile(file);
        if (mime.getType(file.name) === "image") {
          return ModernFileHelper.image(file.name, browserPath, {
            size: file.size,
            quality: await imageHelper.getQuality(browserPath),
          });
        }
        return ModernFileHelper.video(file.name, browserPath, {
          size: file.size,
        });
      }),
    );
  }

  async pickDocument() {
    const files = await this.triggerWebFileInput("*", false);
    if (!files) {
      return undefined;
    }
    const [first] = files;
    if (!first) {
      return undefined;
    }
    return ModernFileHelper.document(
      first.name,
      fileAllocator.fromWebFile(first),
      {
        size: first.size,
      },
    );
  }

  async pickDocuments() {
    const files = await this.triggerWebFileInput("*", true);
    if (!files) {
      return undefined;
    }
    return files.map((file) =>
      ModernFileHelper.document(file.name, fileAllocator.fromWebFile(file), {
        size: file.size,
      }),
    );
  }
}
