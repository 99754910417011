import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { Api } from "@kraaft/shared/core/services/api";
import { EditOneOperationBuilder } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations/editOne.operation";

export const toggleReportTemplateOperation =
  EditOneOperationBuilder.create<LibrarySchema>()
    .payload<{
      librarySchemaId: string;
      reportTemplateId: string;
      enabled: boolean;
    }>()
    .expected((aggregate, payload) => {
      const { reportTemplateId, enabled } = payload;
      const report = aggregate.reportTemplates.find(
        (r) => r.id === reportTemplateId,
      );

      if (report && enabled !== undefined) {
        report.enabled = enabled;
      }

      return aggregate;
    })
    .mutate(async (payload) => {
      const { librarySchemaId, reportTemplateId, enabled } = payload;

      const { updatedAt } = await Api.toggleLibrarySchemaReportTemplate({
        librarySchemaId,
        reportTemplateId,
        enabled,
      });

      return { updatedAt };
    });
