import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";

import { useBooleanState } from "@kraaft/helper-hooks";
import { UnknownObject } from "@kraaft/helper-types";
import { CollapseLine } from "@kraaft/shared/components/collapseLine";
import { EmptyState } from "@kraaft/shared/components/emptyState";
import { MODULAR_FOLDER_ORDERABLE_LIST } from "@kraaft/shared/components/modularFolderList/modularFolderList.utils";
import { ModularFolderListProps } from "@kraaft/shared/components/modularFolderList/modularFolderList/modularFolderList.types";
import { useCheckboxGuard } from "@kraaft/shared/components/modularFolderList/useCheckboxGuard";
import { useModularFolderCheckboxPress } from "@kraaft/shared/components/modularFolderList/useModularFolderCheckboxPress";
import { useModularFolderReorder } from "@kraaft/shared/components/modularFolderList/useModularFolderReorder";
import { useOrderableModularFolderList } from "@kraaft/shared/components/modularFolderList/useOrderableModularFolderList";
import { useIsModularFoldersSelectionEnabled } from "@kraaft/shared/components/modularFolders/modularFoldersSelection.context";
import { ModularFolderStateActions } from "@kraaft/shared/core/modules/modularFolder/modularFolderActions";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { selectSchemaHighlightedColumnKey } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";
import {
  ColorStyle,
  HideVertical,
  Identifier,
  OrderableList,
  OrderableListDragItem,
  ReorderPlacement,
  RowDropInfo,
  ScrollingContainer,
  Spacing,
} from "@kraaft/ui";

import { ModularFolderItemConnector } from "../modularFolderItem";

function ensureItemType(
  type: Identifier,
  _item: UnknownObject,
): _item is OrderableListDragItem<ModularFolder> {
  return type === MODULAR_FOLDER_ORDERABLE_LIST;
}

export const ModularFolderList = ({
  roomId,
  schemaId,
  id,
  header,
  footer,
  filteredFolders,
}: ModularFolderListProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [isCheckedSectionOpen, setCheckedSectionOpen] = useState(true);
  const [isDragging, setDragging, unsetDragging] = useBooleanState(false);

  const { checkboxGuard, openCheckboxGuard } = useCheckboxGuard(schemaId);
  const highlightedCheckboxColumnKey = useSelector(
    selectSchemaHighlightedColumnKey(schemaId),
  );
  const hasHighlightedCheckboxColumn = Boolean(highlightedCheckboxColumnKey);
  const isSelectionEnabled = useIsModularFoldersSelectionEnabled();

  const {
    alteredFolders,
    unchecked,
    checked,
    alterFolder,
    revertFolderChanges,
  } = useOrderableModularFolderList(
    filteredFolders,
    highlightedCheckboxColumnKey,
  );

  const currentUserId = useSelector(selectCurrentUserId);

  const handleModularFolderCheckboxPress = useModularFolderCheckboxPress({
    currentUserId,
    folders: alteredFolders,
    highlightedCheckboxColumnKey,
    openCheckboxGuard,
    alterRecord: alterFolder,
    revertRecordChanges: revertFolderChanges,
  });

  const handleModularReordered = useModularFolderReorder({
    currentUserId,
    folders: alteredFolders,
    highlightedCheckboxColumnKey,
    roomId,
    openCheckboxGuard,
    alterRecord: alterFolder,
    revertRecordChanges: revertFolderChanges,
  });

  const openCarousel = useCallback(
    (modularFolderId: string, imageIndex: number) => {
      dispatch(
        ModularFolderStateActions.openCarousel({
          roomId,
          folderId: modularFolderId,
          initialIndex: imageIndex,
        }),
      );
    },
    [dispatch, roomId],
  );

  const renderOrderableModularFolderItem = useCallbackRealtime(
    (
      [_handleModularFolderCheckboxPress, _openCarousel],
      modularFolder: ModularFolder,
    ) => {
      return (
        <ModularFolderItemConnector
          roomId={roomId}
          schemaId={schemaId}
          modularFolderId={modularFolder.id}
          openCarousel={_openCarousel}
          onCheckboxPress={(modularFolderId, value) =>
            _handleModularFolderCheckboxPress(modularFolderId, value, false)
          }
        />
      );
    },
    [roomId, schemaId],
    [handleModularFolderCheckboxPress, openCarousel],
  );

  const handleRequestReorder = useCallback(
    (sourceKey: string, placement: ReorderPlacement, targetKey: string) => {
      handleModularReordered(sourceKey, placement, targetKey);
    },
    [handleModularReordered],
  );

  const handleExternalTypeDropUnchecked = useCallback(
    (
      type: Identifier,
      item: UnknownObject,
      dropLocation: RowDropInfo | undefined,
    ) => {
      if (!ensureItemType(type, item)) {
        return;
      }

      if (dropLocation === undefined) {
        handleModularFolderCheckboxPress(item.sourceKey, false, true);
      } else {
        handleModularReordered(
          item.sourceKey,
          dropLocation.placement,
          dropLocation.key,
          false,
        );
      }
    },
    [handleModularFolderCheckboxPress, handleModularReordered],
  );

  const handleExternalTypeDropChecked = useCallback(
    (
      type: Identifier,
      item: UnknownObject,
      dropLocation: RowDropInfo | undefined,
    ) => {
      if (!ensureItemType(type, item)) {
        return;
      }

      if (dropLocation === undefined) {
        handleModularFolderCheckboxPress(item.sourceKey, true, true);
      } else {
        handleModularReordered(
          item.sourceKey,
          dropLocation.placement,
          dropLocation.key,
          true,
        );
      }
      setCheckedSectionOpen(true);
    },
    [handleModularFolderCheckboxPress, handleModularReordered],
  );

  const isCheckedSectionExpanded = isCheckedSectionOpen || isDragging;

  return (
    <>
      <View style={styles.mainContainer}>
        {isEmpty(alteredFolders) ? (
          <View style={styles.emptyStateContainer}>
            <EmptyState type="modularFolders" />
          </View>
        ) : (
          <ScrollingContainer
            id={id && `${id}-modularFolder-list`}
            className={classes.container}
          >
            {header}
            <OrderableList
              rows={unchecked}
              RowRenderer={renderOrderableModularFolderItem}
              rowContainerClassName={classes.rowContainer}
              sourceType={MODULAR_FOLDER_ORDERABLE_LIST}
              onRequestReorder={handleRequestReorder}
              onExternalTypeDrop={handleExternalTypeDropUnchecked}
              onDragStart={setDragging}
              onDragEnd={unsetDragging}
              paddingBottom="S12"
              grow={!hasHighlightedCheckboxColumn}
              disabled={isSelectionEnabled}
            />
            {hasHighlightedCheckboxColumn && (
              <>
                <View style={styles.sectionSeparator}>
                  <CollapseLine
                    onPress={setCheckedSectionOpen}
                    title={`${t("finished")} ( ${
                      Object.keys(checked).length
                    } )`}
                    open={isCheckedSectionExpanded}
                    style={styles.collapsibleLine}
                  />
                </View>
                <HideVertical
                  hidden={!isCheckedSectionExpanded}
                  className={classes.growingContainer}
                >
                  <OrderableList
                    rows={checked}
                    RowRenderer={renderOrderableModularFolderItem}
                    rowContainerClassName={classes.rowContainer}
                    sourceType={MODULAR_FOLDER_ORDERABLE_LIST}
                    onRequestReorder={handleRequestReorder}
                    onExternalTypeDrop={handleExternalTypeDropChecked}
                    onDragStart={setDragging}
                    onDragEnd={unsetDragging}
                    grow
                    disabled={isSelectionEnabled}
                  />
                </HideVertical>
              </>
            )}
          </ScrollingContainer>
        )}
        {footer}
      </View>
      {checkboxGuard}
    </>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
    padding: Spacing.S8,
  },
  sectionSeparator: {
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
  },
  collapsibleLine: {
    paddingTop: 0,
  },
  emptyStateContainer: {
    flex: 1,
    justifyContent: "center",
  },
});

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  growingContainer: {
    flexGrow: 1,
  },
  rowContainer: {
    display: "flex",
    padding: `${Spacing.S4}px 0`,
  },
}));
