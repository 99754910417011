import { Image, ImageLoadEventData } from "expo-image";
import { useCallback, useState } from "react";

import { ImageSource } from "./image.type";
import { ImageImplementationProps } from "./imageImplementation.types";

function getWebSource(source: ImageSource) {
  if (typeof source === "string") {
    return source;
  }
  if (typeof source === "number") {
    console.warn(
      "Web image implementation received number as source, this is meant for native",
    );
    return undefined;
  }
  if (Array.isArray(source)) {
    return source[0]?.uri;
  }
  return source?.uri;
}

export const ImageImplementation = ({
  source,
  onImageLoaded,
  contentFit,
  style,
  testID,
  alt,
  placeholder,
  placeholderContentFit,
  fadeDuration,
}: ImageImplementationProps) => {
  const [aspectRatio, setAspectRatio] = useState<number | undefined>();

  const handleLoad = useCallback(
    (event: ImageLoadEventData) => {
      setAspectRatio(event.source.width / event.source.height);
      onImageLoaded?.({
        width: event.source.width,
        height: event.source.height,
        source: event.source.url,
      });
    },
    [onImageLoaded],
  );

  return (
    <Image
      alt={alt}
      onLoad={handleLoad}
      source={{ uri: getWebSource(source) }}
      placeholder={{ uri: getWebSource(placeholder) }}
      contentFit={contentFit}
      placeholderContentFit={placeholderContentFit}
      transition={fadeDuration}
      style={[style, { aspectRatio, backgroundColor: "transparent" }]}
      {...{ "data-testid": testID }}
    />
  );
};
