import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { flatten, isString } from "lodash";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { KDialogFrame } from "@kraaft/shared/components/kDialog/kDialogFrame";
import { KDialogSize } from "@kraaft/shared/components/kDialog/kDialogProps";
import { selectLoader } from "@kraaft/shared/core/modules/loaders/loaderSelector";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import {
  importRooms,
  importRoomsLoaderId,
} from "@kraaft/shared/core/modules/room/roomActions";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KRoomSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { selectRoomSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { makeDialogStyle } from "@kraaft/web/src/components/importRoomDialog/importRoomDialog.styles";
import { SimplifiedTableRecords } from "@kraaft/web/src/components/simplifiedTable/simplifiedTableRecords";

interface ImportRoomDialogProps {
  open: boolean;
  onClose: () => void;
}

const INITIAL_ROWS = 7;
const CHECKBOX_TITLES = "checkboxTitles";

const ALLOWED_COLUMN_TYPES = [
  KColumnType.checkbox,
  KColumnType.currency,
  KColumnType.date,
  KColumnType.geolocation,
  KColumnType.longText,
  KColumnType.number,
  KColumnType.shortText,
  KColumnType.user,
  KColumnType.selectMultiple,
  KColumnType.selectSingle,
];

const ImportRoomDialog = ({ onClose, open }: ImportRoomDialogProps) => {
  const poolId = useSelector(selectCurrentPoolId);
  const roomSchema = useSelector(selectRoomSchema(poolId));

  if (!poolId || !roomSchema) {
    return null;
  }

  return (
    <ImportRoomDialogContent
      onClose={onClose}
      open={open}
      poolId={poolId}
      schema={roomSchema}
    />
  );
};

const ImportRoomDialogContent = (
  props: ImportRoomDialogProps & {
    schema: KRoomSchema;
    poolId: string;
  },
) => {
  const { open, onClose, poolId, schema } = props;
  const { t } = useTranslation();
  const classes = makeDialogStyle();

  const dispatch = useDispatch();

  const importLoader = useSelector(selectLoader(importRoomsLoaderId));

  const schemaWithCheckboxes: KRoomSchema = useMemo(() => {
    const convertedSchema = {
      ...schema,
      rootSection: KSchemaUtils.mapColumns(schema.rootSection, (column) => {
        if (column.type === KColumnType.roomMembers) {
          return {
            ...column,
            type: KColumnType.user,
            allowMultiple: true,
          };
        }
        return { ...column };
      }),
    };

    const lastColumn = KSchemaUtils.flattenSection(
      convertedSchema.rootSection,
    ).pop();

    return KSchemaUtils.withSpecificColumns(
      {
        ...convertedSchema,
        rootSection: {
          ...convertedSchema.rootSection,
          elements: {
            ...convertedSchema.rootSection.elements,
            [CHECKBOX_TITLES]: {
              elementType: "column",
              index: lastColumn?.index ?? 0,
              key: CHECKBOX_TITLES,
              reportKey: "checkboxTitles",
              name: t("checklistMenu"),
              type: KColumnType.shortText,
              description: undefined,
            },
          },
        },
      },
      (c) => ALLOWED_COLUMN_TYPES.includes(c.type),
    );
  }, [schema, t]);

  const createNewRecord = useCallback(() => {
    const newRecord = ModularRecordUtils.create(schema);
    return newRecord;
  }, [schema]);

  const initialRows = useMemo(
    () => Array.from({ length: INITIAL_ROWS }, createNewRecord),
    [createNewRecord],
  );
  const [records, setRecords] = useState(initialRows);

  useEffect(() => {
    if (importLoader?.status === LoaderStatus.SUCCESS) {
      setRecords(initialRows);
      onClose();
    }
  }, [onClose, importLoader, initialRows]);

  const importRoom = useCallback(async () => {
    const allRooms = records.map((room, row) => {
      // extract checkboxes
      const checkboxTitlesValue = room.properties?.checkboxTitles?.value;
      const checkboxTitles = isString(checkboxTitlesValue)
        ? checkboxTitlesValue.split(",").map((str) => str.trim())
        : [];
      // biome-ignore lint/performance/noDelete: <explanation>
      delete room.properties.checkboxTitles;

      const memberColumns = KSchemaUtils.getColumnsOfType(
        schema,
        KColumnType.roomMembers,
      );
      const memberIds = flatten(
        memberColumns.map(
          (column) => (room.properties[column.key]?.value as string[]) ?? [],
        ),
      );
      for (const column of memberColumns) {
        delete room.properties[column.key];
      }

      return {
        row,
        record: {
          id: room.id,
          schemaId: schema.id,
          properties: room.properties,
        },
        memberIds,
        checkboxTitles,
      };
    });

    const rooms = allRooms.filter(
      (room) =>
        ModularRecordUtils.getRecordField(
          room.record,
          "title",
          KColumnType.shortText,
          "",
        ).length > 0,
    );

    dispatch(
      importRooms({
        poolId,
        rooms,
      }),
    );
  }, [dispatch, poolId, records, schema]);

  const emptyTable = useCallback(() => {
    setRecords(initialRows);
  }, [initialRows]);

  const actions = useMemo<FooterActions>(
    () => [
      {
        accessibilityLabel: t("import"),
        text: t("import"),
        onPress: importRoom,
        variant: "PRIMARY",
        loading: importLoader?.status === LoaderStatus.LOADING,
      },
      {
        accessibilityLabel: t("emptyTable"),
        text: t("emptyTable"),
        onPress: emptyTable,
        disabled: records === initialRows,
      },
    ],
    [emptyTable, importLoader?.status, importRoom, initialRows, records, t],
  );

  return (
    <KDialogFrame size={KDialogSize.MEDIUM} open={open} onClose={onClose}>
      <KDialogContent
        title={t("importDialogRoom")}
        subtitle={t("importDialogRoomSubtitle")}
        content={
          <div className={classes.dialogContentContainer}>
            <SimplifiedTableRecords
              schema={schemaWithCheckboxes}
              records={records}
              onRecordsChange={setRecords}
              tableClassName={classes.scrollbarOffset}
              getNewRecord={createNewRecord}
              canAddRecord
              canDeleteRecord
              recordType="room"
              noCheckboxConfirmation
            />
          </div>
        }
        actions={actions}
      />
    </KDialogFrame>
  );
};

export { ImportRoomDialog };
