import { spawn } from "typed-redux-saga/macro";

import { createSchemaSaga } from "@kraaft/shared/core/modules/schema/sagas/createSchema";
import { reorderSchemaSaga } from "@kraaft/shared/core/modules/schema/sagas/reorderSchema";
import { subscribeToSchemasSaga } from "@kraaft/shared/core/modules/schema/sagas/subscribeToSchemas";

export function* schemaSagas() {
  yield* spawn(subscribeToSchemasSaga);
  yield* spawn(createSchemaSaga);
  yield* spawn(reorderSchemaSaga);
}
