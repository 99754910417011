import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { optimisticSchemaEditSection } from "@kraaft/shared/core/modules/schema/schema.optimisticHelper";
import { Api } from "@kraaft/shared/core/services/api";
import { EditOneOperationBuilder } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations/editOne.operation";

export const editSchemaSectionOperation =
  EditOneOperationBuilder.create<LibrarySchema>()
    .payload<{
      key: string;
      edits: {
        name?: string;
        color?: string;
      };
    }>()
    .expected((aggregate, payload) => {
      optimisticSchemaEditSection(aggregate.schema, payload);
      return aggregate;
    })
    .mutate(async (payload) => {
      const { updatedAt } = await Api.editLibrarySchemaSection({
        librarySchemaId: payload.id,
        sectionKey: payload.key,
        update: payload.edits,
      });
      return { updatedAt };
    });
