import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { Api } from "@kraaft/shared/core/services/api";
import { EditOneOperationBuilder } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations/editOne.operation";

export const setCompanyOperation =
  EditOneOperationBuilder.create<LibrarySchema>()
    .payload<{ companyId: string | undefined }>()
    .expected((aggregate, payload) => {
      aggregate.companyId = payload.companyId;
      return aggregate;
    })
    .mutate(async (payload) => {
      const { updatedAt } = await Api.setLibrarySchemaCompany({
        librarySchemaId: payload.id,
        companyId: payload.companyId,
      });

      return { updatedAt };
    });
