import { Api } from "@kraaft/shared/core/services/api";
import { Operation } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations";

import { KSchema } from "../modularTypes/kSchema";

export const deleteOperation = Operation.deleteOne<KSchema>().mutate(
  async (payload, task) => {
    await Api.deleteSchema({
      requestId: task.id,
      schemaId: payload.id,
    });
  },
);
