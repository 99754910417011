import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { fileUpload } from "@kraaft/shared/core/modules/file/fileUploader";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { optimisticEditSchemaElementDescription } from "@kraaft/shared/core/modules/schema/schema.optimisticHelper";
import { Api } from "@kraaft/shared/core/services/api";
import { Operation } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations";

export const editSchemaElementDescriptionOperation =
  Operation.editOne<LibrarySchema>()
    .payload<{
      userId: string;
      elementKey: string;
      description: {
        text?: string | undefined;
        image?: ModernFile<LocalPath> | null | undefined;
        document?: ModernFile<LocalPath> | null | undefined;
      };
    }>()
    .expected((aggregate, payload) => {
      optimisticEditSchemaElementDescription(
        aggregate.schema,
        payload.elementKey,
        payload.description,
      );
    })
    .mutate(async (payload) => {
      let imageStoragePath: string | undefined;
      if (payload.description.image) {
        imageStoragePath = await fileUpload.uploadToUserSpace(
          payload.description.image,
          payload.userId,
        );
      }

      let documentStoragePath: string | undefined;
      if (payload.description.document) {
        documentStoragePath = await fileUpload.uploadToUserSpace(
          payload.description.document,
          payload.userId,
        );
      }

      const { updatedAt } = await Api.editLibrarySchemaElementDescription({
        librarySchemaId: payload.id,
        elementKey: payload.elementKey,
        text: payload.description.text ?? undefined,
        image: payload.description.image !== null ? imageStoragePath : null,
        document:
          payload.description.document !== null ? documentStoragePath : null,
      });
      return { updatedAt };
    });
