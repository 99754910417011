import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { Api } from "@kraaft/shared/core/services/api";
import { DeleteOneOperationBuilder } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations/deleteOne.operation";

export const deleteOperation =
  DeleteOneOperationBuilder.create<LibrarySchema>().mutate(async (payload) => {
    await Api.deleteLibrarySchema({
      librarySchemaId: payload.id,
    });
  });
