import { useCallback, useState } from "react";
import {
  Pressable,
  PressableStateCallbackType,
  StyleSheet,
  View,
} from "react-native";

import { useMeshContext } from "@kraaft/helper-hooks";
import { EditorProps } from "@kraaft/shared/components/modular/details/editors/types";
import {
  CheckboxGuard,
  CheckboxGuardStrategy,
} from "@kraaft/shared/components/modularFolders/checkboxGuard/checkboxGuard";
import { getAppropriateCheckboxGuardStrategy } from "@kraaft/shared/components/modularFolders/checkboxGuard/getAppropriateCheckboxGuardStrategy";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularDisplayExtendedRequirementsContext } from "@kraaft/shared/core/modules/schema/useModularDisplayExtendedRequirements";
import { highlightOnFocus } from "@kraaft/shared/core/utils/highlightOnFocus";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { Color, ColorStyle, Icon, Radius, Spacing } from "@kraaft/ui";

type Props = EditorProps<KColumnType.checkbox>;

const CheckboxEditor = (props: Props) => {
  const {
    editor: { label, disabled, value, setValue, sectionLockInfo },
    column,
    testID,
  } = props;

  const [checkboxGuardStrategy, setCheckboxGuardStrategy] = useState<
    CheckboxGuardStrategy | undefined
  >(undefined);

  const handleCheckboxGuardClose = useCallback(() => {
    setCheckboxGuardStrategy(undefined);
  }, []);

  const handleCheckboxGuardConfirm = useCallback(() => {
    setCheckboxGuardStrategy(undefined);
    setValue(!value);
  }, [setValue, value]);

  const getPressableStyle = useCallback(
    (state: PressableStateCallbackType) => [
      styles.button,
      {
        backgroundColor: value
          ? column.isLockingSection
            ? Color.YELLOW_EARTH
            : Color.GREEN_LAGOON
          : ColorStyle.BACKGROUND_STANDARD,
      },
      state.pressed && styles.buttonPressed,
    ],
    [value, column.isLockingSection],
  );

  const handleCheckboxPress = useCallback(() => {
    if (column.isLockingSection && value) {
      trackEvent({
        eventName: "Tried To Unlock A Section",
        source: "table",
      });
    }

    setCheckboxGuardStrategy(
      getAppropriateCheckboxGuardStrategy(
        !!column.isLockingSection,
        !!value,
        sectionLockInfo.isCurrentUserAuthorizedToUnlock,
        sectionLockInfo.highestLockedSectionKey,
        sectionLockInfo.section.key,
      ),
    );
  }, [
    column.isLockingSection,
    sectionLockInfo.highestLockedSectionKey,
    sectionLockInfo.isCurrentUserAuthorizedToUnlock,
    sectionLockInfo.section.key,
    value,
  ]);

  const { recordType } = useMeshContext(
    ModularDisplayExtendedRequirementsContext,
  );

  return (
    <>
      <View style={styles.container}>
        <Pressable
          id={`${testID}-${column.key}`}
          style={getPressableStyle}
          onPress={handleCheckboxPress}
          disabled={
            disabled ||
            (sectionLockInfo.isLocked &&
              !(column.isLockingSection && value === true))
          }
          accessibilityLabel={label}
          // TODO fix this when on react-native-web 0.19
          {...{
            dataSet: {
              checked: Boolean(value),
              ...highlightOnFocus.dataSet,
            },
          }}
        >
          <Icon
            name={column.isLockingSection ? "lock-01" : "check"}
            color={value ? Color.WHITE : ColorStyle.FONT_LOW_EMPHASIS}
          />
        </Pressable>
      </View>
      <CheckboxGuard
        strategy={checkboxGuardStrategy}
        sectionLockInfo={sectionLockInfo}
        onClose={handleCheckboxGuardClose}
        onConfirm={handleCheckboxGuardConfirm}
        isInRoom={recordType === "room"}
        columnName={column.name}
      />
    </>
  );
};
const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    padding: Spacing.S8,
    borderRadius: Radius.MAXIMUM,
  },
  buttonPressed: {
    opacity: 0.75,
  },
});

export { CheckboxEditor };
