import i18n from "i18next";

import { PoolLanguage } from "@kraaft/shared/core/modules/pool/pool";
import { availableLanguages } from "@kraaft/shared/core/services/i18next/i18nextResources";
import { findClosestLanguage } from "@kraaft/shared/core/services/i18next/i18nextUtils";

class NumberFormatter {
  constructor(private locale: PoolLanguage) {}
  setLocale(locale: PoolLanguage) {
    this.locale = locale;
  }

  formatCurrency(value: number, currencyCode: string) {
    try {
      return new Intl.NumberFormat(this.locale, {
        style: "currency",
        currency: currencyCode,
      }).format(value);
    } catch (e) {
      return new Intl.NumberFormat(this.locale, {
        style: "currency",
        currency: "EUR",
      }).format(value);
    }
  }

  formatNumber(value: number, unit?: string) {
    if (unit) {
      return `${new Intl.NumberFormat(this.locale).format(value)} ${unit}`;
    }
    return new Intl.NumberFormat(this.locale).format(value);
  }

  getCurrencySymbolFromCode(code: string) {
    let currency: string;
    try {
      currency = new Intl.NumberFormat(this.locale, {
        style: "currency",
        currency: code,
      })
        // used to be .formatToParts() but it is not supported on mobile
        .format(1)
        .replaceAll(/[0-9]|\s|\.|,/gm, "");
    } catch (e) {
      currency = "€";
    }

    return currency;
  }

  /**
   *  inspired by
   *  https://stackoverflow.com/a/29273131
   */
  parseLocaleNumber(value: string) {
    const parts = new Intl.NumberFormat(this.locale).formatToParts(1111.1);
    const group = parts.find((part) => part.type === "group")?.value;
    const decimal = parts.find((part) => part.type === "decimal")?.value;
    const cleanedValue = value
      .replace(new RegExp(`\\${group}`, "g"), "")
      .replace(new RegExp(`\\${decimal}`, "g"), ".");

    return Number.isNaN(+cleanedValue) ? 0 : +cleanedValue;
  }

  static fromLanguageCode(code: string) {
    return new this(findClosestLanguage(availableLanguages, code) ?? "fr-FR");
  }
}

export const numberFormatter = NumberFormatter.fromLanguageCode(
  i18n.language ?? "fr-FR",
);
