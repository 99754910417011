import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { KSchemaIcon } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { AutoNumberingMode } from "@kraaft/shared/core/modules/schema/schema.actions";
import { optimisticSchemaEditMetadata } from "@kraaft/shared/core/modules/schema/schema.optimisticHelper";
import { Api } from "@kraaft/shared/core/services/api";
import { EditOneOperationBuilder } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations/editOne.operation";

export const editSchemaMetadataOperation =
  EditOneOperationBuilder.create<LibrarySchema>()
    .payload<{
      name?: string;
      icon?: KSchemaIcon;
      autonumbering?: AutoNumberingMode;
    }>()
    .expected((aggregate, payload) => {
      optimisticSchemaEditMetadata(aggregate.schema, payload);
      return aggregate;
    })
    .mutate(async (payload) => {
      const { updatedAt } = await Api.editLibrarySchemaMetadata({
        librarySchemaId: payload.id,
        update: { name: payload.name, icon: payload.icon },
      });
      return { updatedAt };
    });
