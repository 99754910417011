import {
  KSchema,
  SchemaDefaultVisibility,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { Api } from "@kraaft/shared/core/services/api";
import { Operation } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations";

export const setDefaultVisibilityOperation = Operation.editOne<KSchema>()
  .payload<{ defaultVisibility: SchemaDefaultVisibility }>()
  .expected((aggregate, payload) => {
    if (aggregate.collection === "folder") {
      aggregate.defaultVisibility = payload.defaultVisibility;
    }
    return aggregate;
  })
  .mutate(async (payload) => {
    const { updatedAt } = await Api.setSchemaDefaultVisibility({
      schemaId: payload.id,
      defaultVisibility: payload.defaultVisibility,
    });
    return { updatedAt };
  });
