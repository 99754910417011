import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { EmptyState } from "@kraaft/shared/components/emptyState";
import { selectFolderSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { ScreenParams } from "@kraaft/shared/core/services/navigation/navigationParams";

import { ConnectedModularFoldersViewer } from "./modularFoldersViewer.connected";

import { useStyles } from "./modularFoldersViewer.styles";

const ModularFoldersViewer = () => {
  const { schemaId } =
    useParams<
      ScreenParams<"ModularFoldersTable" | "ModularFoldersTableForRoom">
    >();

  const schema = useSelector(selectFolderSchema(schemaId));
  const classes = useStyles();

  if (!schema) {
    return (
      <div className={classes.emptyState}>
        <EmptyState type="database" />
      </div>
    );
  }

  return <ConnectedModularFoldersViewer schema={schema} />;
};

export { ModularFoldersViewer };
