import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  EditLocationPanel,
  EditLocationPanelProps,
  EditLocationSheet,
} from "@kraaft/shared/components/editLocation";
import {
  SlidePanel,
  SlidePanelProps,
} from "@kraaft/shared/components/slidePanel";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { selectRoom } from "@kraaft/shared/core/modules/room/selectors";
import { SectionLockInfo } from "@kraaft/shared/core/modules/schema/lockInfo.utils";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";

export type UseLocationEditorParams = Partial<
  Pick<
    EditLocationPanelProps,
    "thumbnailUrl" | "isOnlyEditable" | "geolocation" | "title" | "editTitle"
  >
> & {
  context: EditLocationPanelProps["context"];
  panelName: string;
  portalHostname?: string;
  slideDirection?: SlidePanelProps["direction"];
  onLocationChange: EditLocationPanelProps["updateGeolocation"];
  sectionLockInfo?: SectionLockInfo;
};

export const useLocationEditor = ({
  panelName,
  geolocation,
  context,
  thumbnailUrl,
  portalHostname,
  slideDirection,
  isOnlyEditable = true,
  onLocationChange,
  sectionLockInfo,
  ...props
}: UseLocationEditorParams) => {
  const { roomId, source } = context;

  const { t } = useTranslation();

  const { title = t("addLocation"), editTitle = t("editLocation") } = props;

  const [isVisible, open, close] = useBooleanState();

  const room = useSelector(selectRoom(roomId));

  const handleLocationChange = useCallback<
    EditLocationPanelProps["updateGeolocation"]
  >(
    (newGeolocation) => {
      trackEvent({
        eventName: "Edit Photo Location",
        room_id: roomId,
        room_name: getExistingRoomRecordField(
          room?.record.properties,
          "title",
          "",
        ),
        source,
      });

      close();
      onLocationChange(newGeolocation);
    },
    [roomId, room, source, close, onLocationChange],
  );

  const LocationEditorPanel = useMemo(
    () => (
      <SlidePanel
        direction={slideDirection}
        name={panelName}
        open={isVisible}
        onClose={close}
        portalHostname={portalHostname}
      >
        <EditLocationPanel
          thumbnailUrl={thumbnailUrl}
          geolocation={geolocation}
          context={context}
          editTitle={editTitle}
          title={title}
          isOnlyEditable={isOnlyEditable}
          setOpen={close}
          updateGeolocation={handleLocationChange}
          sectionLockInfo={sectionLockInfo}
          centerOnCurrentLocation
        />
      </SlidePanel>
    ),
    [
      slideDirection,
      panelName,
      isVisible,
      close,
      portalHostname,
      thumbnailUrl,
      geolocation,
      context,
      editTitle,
      title,
      isOnlyEditable,
      handleLocationChange,
      sectionLockInfo,
    ],
  );

  const { element, open: openLocationSheet } = EditLocationSheet.use({
    defaultValue: geolocation ?? undefined,
    thumbnailUrl: thumbnailUrl,
    title: geolocation ? editTitle : title,
    onLocationChange: handleLocationChange,
  });

  const handleOpen = useCallback(() => {
    open();
    openLocationSheet();
  }, [open, openLocationSheet]);

  return {
    editLocation: handleOpen,
    LocationEditorPanel,
    LocationEditorSheet: element,
  };
};
