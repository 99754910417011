import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { reorderElement } from "@kraaft/shared/core/modules/schema/schema.utils";
import { Api } from "@kraaft/shared/core/services/api";
import { EditOneOperationBuilder } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations/editOne.operation";

export const reorderSchemaElementOperation =
  EditOneOperationBuilder.create<LibrarySchema>()
    .payload<{
      targetKey: string;
      afterKey: string | undefined;
      sectionKey: string;
    }>()
    .expected((aggregate, payload) => {
      reorderElement(aggregate.schema, payload);
      return aggregate;
    })
    .mutate(async (payload) => {
      const { updatedAt } = await Api.moveLibrarySchemaElement({
        librarySchemaId: payload.id,
        sectionKey: payload.sectionKey,
        targetKey: payload.targetKey,
        afterKey: payload.afterKey,
      });
      return { updatedAt };
    });
