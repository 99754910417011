import { filterInPlace } from "@kraaft/helper-functions";
import { KeyValueStorage } from "@kraaft/shared/core/modules/storage/storage";
import { backgroundService } from "@kraaft/shared/core/services/backgroundService/backgroundService.provider";
import {
  OfflineTaskManagerInterface,
  TaskResult,
  TaskResultHelper,
} from "@kraaft/shared/core/services/backgroundService/offlineTaskManager";
import { Listeners } from "@kraaft/shared/core/utils/listeners";
import { Logger } from "@kraaft/shared/core/utils/logger/logger";
import { offlineLogger } from "@kraaft/shared/core/utils/optimistic/newOptimistic/offline.logger";
import type { Task } from "@kraaft/shared/core/utils/optimistic/newOptimistic/taskStore/task";
import { PromiseQueue } from "@kraaft/shared/core/utils/queue";

// Fix for webpack, otherwise it resolves all imports and detects a cycle dependency, while there is none
async function getRegisteredTaskManagers() {
  return (
    await import("@kraaft/shared/core/store/offline")
  ).getRegisteredTaskManagers();
}

/**
 * Strategy to adopt on task failure
 * skip: will ignore the task just failed, won't trigger listeners!
 * delete: will delete the task
 * delete-related: will delete any task having a common dependency with the one that just failed
 * halt: cancel the queue for now, without deleting the task that failed
 */
type TaskFailStrategy = "skip" | "delete-related" | "halt";

const taskExecutionStopReasons = [
  "never-ran",
  "halt",
  "finished",
  "timeout",
] as const;
type TaskExecutionStopReason = (typeof taskExecutionStopReasons)[number];

export class TaskExecutionError extends Error {
  constructor(
    public strategy: TaskFailStrategy,
    error: Error,
  ) {
    if (error) {
      super(error.message, { cause: error });
    } else {
      super();
    }
    this.name = "TaskExecutionError";
  }
}

interface TaskStoreModel {
  tasks: Array<Task>;
  taskExecutionStopReason: TaskExecutionStopReason;
}

export interface TaskStore {
  loadAll(): Promise<Array<Task>>;
  take(): Promise<Task | undefined>;
  append(task: Task): Promise<void>;
  edit(editor: (task: Task) => void): Promise<void>;
  delete(predicate: (task: Task) => boolean): Promise<void>;
  setLastStopReason(reason: TaskExecutionStopReason): Promise<void>;
}

export interface TaskExecutor {
  execute(): Promise<TaskResult>;
  stop(): Promise<boolean>;
}

export interface TaskProcessor {
  enqueue(task: Task): Promise<void>;
  reset(): Promise<void>;
  register(
    name: string,
    handler: (payload: any, task: Task) => Promise<any>,
  ): void;
  edit(editor: (task: Task) => void): Promise<void>;
  getQueue(): Promise<Task[]>;

  onTaskSucceeded: Listeners<(task: Task, result: any) => void>;
  onTaskAdded: Listeners<(task: Task) => void>;
  onTaskFailed: Listeners<(task: Task, error: any) => void>;
  onTaskDelayed: Listeners<(task: Task) => void>;
  onTaskSkipped: Listeners<(task: Task, error: any) => void>;
}

export interface NamedTaskManager extends TaskExecutor, TaskProcessor {
  name: string;
}

export class SimpleTaskManager
  implements TaskExecutor, TaskProcessor, OfflineTaskManagerInterface
{
  constructor(
    public readonly name: string,
    private readonly taskStore: TaskStore,
    private readonly handleTaskError: (error: any) => TaskFailStrategy,
  ) {
    this.logger = offlineLogger.createSubLogger([
      "SimpleTaskManager",
      this.name,
    ]);
  }

  private lastStopReason: TaskExecutionStopReason | undefined;

  logger: Logger;

  onTaskSucceeded = new Listeners<(task: Task, result: any) => void>();
  onTaskAdded = new Listeners<(task: Task) => void>();
  onTaskFailed = new Listeners<(task: Task, error: any) => void>();
  onTaskDelayed = new Listeners<(task: Task) => void>();
  onTaskSkipped = new Listeners<(task: Task, error: any) => void>();

  private shouldStop = false;
  private registry: Record<string, (payload: any, task: Task) => any> = {};
  private executing: Promise<TaskResult> | undefined;

  async getQueue() {
    const all = await this.taskStore.loadAll();
    return all;
  }

  edit(editor: (task: Task) => void) {
    return this.taskStore.edit(editor);
  }

  async enqueue(task: Task) {
    await this.onTaskAdded.trigger(task);
    await this.taskStore.append(task);
    if (this.lastStopReason === "halt") {
      return;
    }
    this.execute().catch(console.error);
  }

  register(name: string, handler: (payload: any, task: Task) => Promise<any>) {
    this.registry[name] = handler;
  }

  private async handle(task: Task) {
    const handler = this.registry[task.name];
    if (!handler) {
      return;
    }
    return await handler(task.payload, task);
  }

  private async setLastStopReason(reason: TaskExecutionStopReason) {
    this.lastStopReason = reason;
    await this.taskStore.setLastStopReason(reason);
  }

  // eslint-disable-next-line complexity
  private async executeWithoutDeduplication(): Promise<TaskResult> {
    let atLeastOne = false;
    while (true) {
      const task = await this.taskStore.take();
      if (!task) {
        if (atLeastOne) {
          await this.setLastStopReason("finished");
          return "success";
        }
        return "empty";
      }
      atLeastOne = true;
      if (this.shouldStop) {
        await this.setLastStopReason("timeout");
        return "timed-out";
      }
      try {
        const result = await this.handle(task);
        await this.onTaskSucceeded.trigger(task, result);
        await this.taskStore.delete((t) => t.id === task.id);
      } catch (error) {
        const failureStrategy =
          error instanceof TaskExecutionError
            ? error.strategy
            : this.handleTaskError(error);

        const name = typeof error.name === "string" ? error.name : "no name";
        const message =
          typeof error.message === "string" ? error.message : "no details";

        this.logger.warn(
          `Task error, Name(${
            task.name
          }) Strategy(${failureStrategy}) ErrorName(${name}) ErrorMessage(${message}) Error(${error.toString()})`,
        );

        if (failureStrategy === "delete-related") {
          await this.onTaskFailed.trigger(task, error);
          await this.taskStore.delete((t) => {
            return t.dependencies.some((dependency) =>
              task.dependencies.includes(dependency),
            );
          });
        } else if (failureStrategy === "skip") {
          await this.onTaskSkipped.trigger(task, error);
          await this.taskStore.delete((t) => t.id === task.id);
        } else if (failureStrategy === "halt") {
          await this.onTaskDelayed.trigger(task);
          await this.setLastStopReason("halt");
          return "partial-success";
        } else {
          this.logger.error(`Unknown strategy ${failureStrategy}`);
        }
      }
    }
  }

  async execute() {
    this.shouldStop = false;
    if (this.executing) {
      return this.executing;
    }
    this.executing = this.executeWithoutDeduplication().then((result) => {
      this.executing = undefined;
      return result;
    });
    return this.executing;
  }

  async stop() {
    this.shouldStop = true;
    return true;
  }

  async reset() {
    return this.taskStore.delete(() => true);
  }
}

export class NamespacedTaskManager
  implements TaskExecutor, OfflineTaskManagerInterface
{
  constructor(
    private readonly storage: KeyValueStorage,
    private readonly handleTaskError: (error: any) => TaskFailStrategy,
  ) {}

  name = "NamespacedTaskManager";

  static logger = offlineLogger.createSubLogger([NamespacedTaskManager.name]);

  private getNamespaceStorageKey(namespace: string) {
    return `offline-namespace-${namespace}`;
  }

  private static checkTaskStoreModelValidity(taskStoreModel: any) {
    return (
      taskStoreModel &&
      Array.isArray(taskStoreModel.tasks) &&
      taskExecutionStopReasons.includes(taskStoreModel.taskExecutionStopReason)
    );
  }

  private async getNamespaceForStorageKey(storageKey: string) {
    const existing: TaskStoreModel | undefined =
      await this.storage.getItem(storageKey);
    if (
      !existing ||
      !NamespacedTaskManager.checkTaskStoreModelValidity(existing)
    ) {
      NamespacedTaskManager.logger.warn(
        `Creating pristine TaskStoreModel for ${storageKey}`,
      );
      const pristine: TaskStoreModel = {
        taskExecutionStopReason: "never-ran",
        tasks: [],
      };
      await this.storage.setItem(storageKey, pristine);
      return pristine;
    }
    return existing;
  }

  /**
   * Creates a task manager for the given namespace
   * There should not be two namespaces created for the same name
   * If you create one, you must use it app-wide, as two concurrent managers
   * might override each other in the store
   */
  createTaskManager(name: string) {
    const storage = this.storage;
    const onUpdate = this.onUpdate;

    const namespaceStorageKey = this.getNamespaceStorageKey(name);
    const queue = new PromiseQueue();

    const storeForName: TaskStore = {
      loadAll: async () => {
        return queue.queue(async () => {
          const stored =
            await this.getNamespaceForStorageKey(namespaceStorageKey);
          return stored.tasks;
        });
      },
      append: async (task) => {
        await Promise.all([
          queue.queue(async () => {
            const stored =
              await this.getNamespaceForStorageKey(namespaceStorageKey);
            stored.tasks.push(task);
            await storage.setItem(namespaceStorageKey, stored);
          }),
          await backgroundService.registerBackgroundFetch(),
        ]);
        onUpdate.trigger().catch(console.error);
      },
      delete: async (predicate) => {
        await queue.queue(async () => {
          const stored =
            await this.getNamespaceForStorageKey(namespaceStorageKey);
          filterInPlace(stored.tasks, (task) => !predicate(task));
          await storage.setItem(namespaceStorageKey, stored);
        });
        onUpdate.trigger().catch(console.error);
      },
      edit: async (editor) => {
        await queue.queue(async () => {
          const stored =
            await this.getNamespaceForStorageKey(namespaceStorageKey);
          for (const task of stored.tasks) {
            editor(task);
          }
          await storage.setItem(namespaceStorageKey, stored);
        });
        onUpdate.trigger().catch(console.error);
      },
      take: async () => {
        return queue.queue(async () => {
          const stored =
            await this.getNamespaceForStorageKey(namespaceStorageKey);
          return stored.tasks[0];
        });
      },
      setLastStopReason: async (reason) => {
        await queue.queue(async () => {
          const stored =
            await this.getNamespaceForStorageKey(namespaceStorageKey);
          stored.taskExecutionStopReason = reason;
          await storage.setItem(namespaceStorageKey, stored);
        });
        onUpdate.trigger().catch(console.error);
      },
    };

    const taskManager = new SimpleTaskManager(
      name,
      storeForName,
      this.handleTaskError,
    );

    return taskManager;
  }

  private executing: Promise<TaskResult> | undefined;

  private async executeWithoutDeduplication() {
    const allTaskResults = await Promise.all(
      (await getRegisteredTaskManagers()).map((taskManager) =>
        taskManager.execute(),
      ),
    );
    return TaskResultHelper.mergeTaskResults(allTaskResults);
  }

  /**
   * You should not call that by hand, this should only be called by background service
   */
  async execute() {
    if (this.executing) {
      return this.executing;
    }
    this.executing = this.executeWithoutDeduplication().then((result) => {
      this.executing = undefined;
      return result;
    });
    return this.executing;
  }

  async stop() {
    await Promise.all(
      (await getRegisteredTaskManagers()).map((taskManager) =>
        taskManager.stop(),
      ),
    );
    return true;
  }

  onUpdate = new Listeners<() => void>();

  /**
   * Used for debugging purposes only
   */
  async dump(): Promise<NamespacedTaskManagerDump> {
    const models = await Promise.all(
      (await getRegisteredTaskManagers()).map(
        async (taskManager) =>
          [
            taskManager,
            await this.getNamespaceForStorageKey(
              this.getNamespaceStorageKey(taskManager.name),
            ),
          ] as const,
      ),
    );
    return models.reduce<NamespacedTaskManagerDump>(
      (acc, [taskManager, model]) => {
        acc.namespaces[taskManager.name] = model;
        return acc;
      },
      { namespaces: {} },
    );
  }

  /**
   * Used for debugging purposes only
   */
  async clear() {
    await Promise.all(
      (await getRegisteredTaskManagers()).map((taskManager) =>
        this.storage.removeItem(this.getNamespaceStorageKey(taskManager.name)),
      ),
    );
    this.onUpdate.trigger().catch(console.error);
  }
}

export interface NamespacedTaskManagerDump {
  namespaces: Record<string, TaskStoreModel>;
}
