import { useMemo } from "react";
import { View } from "react-native";

import { DocumentLoader } from "@kraaft/shared/components/documentLoader";
import { Attachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { ColorStyle, IconSize, Preloader } from "@kraaft/ui";

import { AttachmentListItem } from "./attachmentListItem";
import {
  AttachmentListContext,
  AttachmentThumbnail,
  DocumentDeleter,
  extractDocumentTitle,
  extractImageTitle,
  ImageThumbnail,
} from "./attachmentListUtils";

import { styles } from "./attachmentList.styles";

interface Props {
  attachments: Attachment[];
  context: AttachmentListContext;
  allowDeletion?: boolean;
  onDelete?: (attachment: Attachment) => void;
  onAttachmentClick?: (attachment: Attachment) => void;
  loading?: boolean;
}

interface RowAttachmentListProps extends Props {
  attachment: Attachment;
  index: number;
}

const RowAttachmentList = ({
  attachment,
  onAttachmentClick,
  allowDeletion,
  context,
  index,
  onDelete,
  ...props
}: RowAttachmentListProps): React.ReactElement => {
  const rightComponent = useMemo(() => {
    return <DocumentDeleter onDelete={() => onDelete?.(attachment)} />;
  }, [attachment, onDelete]);

  switch (attachment.type) {
    case "image":
      return (
        <AttachmentListItem
          attachment={attachment}
          {...props}
          leftComponent={<ImageThumbnail attachment={attachment} />}
          extractTitle={extractImageTitle({ ...context, index })}
          onClick={onAttachmentClick}
          {...(allowDeletion ? { rightComponent } : {})}
        />
      );
    case "video":
    case "document":
    case "audio":
      return (
        <DocumentLoader
          downloadUrl={attachment.original.downloadUrl}
          filename={attachment.original.filename}
          type="view"
          trackingSource="modular folder"
        >
          <AttachmentListItem
            {...{ attachment }}
            leftComponent={<AttachmentThumbnail attachment={attachment} />}
            extractTitle={extractDocumentTitle({ ...context, index })}
            {...(allowDeletion ? { rightComponent } : {})}
          />
        </DocumentLoader>
      );
  }
};

const AttachmentList = (props: Props) => {
  const { attachments, loading } = props;

  return (
    <View style={styles.container}>
      {attachments.map((attachment, index) => (
        <View
          key={attachment.id}
          style={[index < attachments.length - 1 && styles.itemGap]}
        >
          <RowAttachmentList attachment={attachment} index={index} {...props} />
        </View>
      ))}
      {loading && (
        <Preloader color={ColorStyle.ACTION_CTA} size={IconSize.MEDIUM} />
      )}
    </View>
  );
};

export { AttachmentList };
