import { useTranslation } from "react-i18next";
import { TouchableOpacity } from "react-native";

import { DocumentLoaderProps } from "@kraaft/shared/components/documentLoader/documentLoaderProps";
import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

const DocumentLoader = ({
  type,
  children,
  onLongPress,
  trackingSource,
  downloadUrl,
  filename,
}: DocumentLoaderProps) => {
  const { t } = useTranslation();

  async function openDocument() {
    trackEvent({
      eventName: "Open Document",
      source: trackingSource,
      document_name: filename,
    });

    if (type === "view") {
      await fileSaver.viewDocument(downloadUrl, filename);
    } else if (type === "download") {
      await fileSaver.download(downloadUrl, filename);
    }
  }

  return (
    <TouchableOpacity
      accessibilityLabel={t("openDocument")}
      onPress={openDocument}
      onLongPress={onLongPress}
    >
      {children}
    </TouchableOpacity>
  );
};

export { DocumentLoader };
