import { createReducer } from "@reduxjs/toolkit";

import { SchemaStateActions } from "@kraaft/shared/core/modules/schema/schema.actions";
import { OfflineSchemaStateActions } from "@kraaft/shared/core/modules/schema/schema.offline";
import { SchemaState } from "@kraaft/shared/core/modules/schema/schema.state";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { mergeInPlace } from "@kraaft/shared/core/store/mergeInPlace";

const initialState: SchemaState = {
  schemas: {},
  areSchemasLoadedByPoolId: {},
  createdColumnKey: undefined,
};

export const schemaReducers = createReducer(initialState, ({ addCase }) => {
  addCase(UserActions.userDisconnectedFromFirebase, () => initialState);

  addCase(OfflineSchemaStateActions.set, (state, { payload }) => {
    mergeInPlace(state.schemas, payload.aggregates);
  });

  addCase(SchemaStateActions.setCreatedKey, (state, { payload }) => {
    state.createdColumnKey = payload;
  });

  // Optimistic
  addCase(SchemaStateActions.optimistic.deleteSchema, (state, { payload }) => {
    delete state.schemas[payload.id];
  });

  addCase(
    SchemaStateActions.setSchemasLoadedForPoolId,
    (state, { payload }) => {
      state.areSchemasLoadedByPoolId[payload.poolId] = true;
    },
  );

  addCase(
    SchemaStateActions.optimistic.reorderSchemas,
    (state, { payload }) => {
      for (const { schemaId, index } of payload) {
        const schema = state.schemas[schemaId];

        if (schema?.collection === "folder") {
          schema.index = index;
        }
      }
    },
  );
});
