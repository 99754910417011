import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { OfflineLibrarySchemaActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.offline";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import {
  useNavigationService,
  useRouteService,
} from "@kraaft/shared/core/services/navigation";
import { Text } from "@kraaft/ui";
import { LibrarySchemaBuilderHeaderName } from "@kraaft/web/src/components/schemaBuilder/librarySchemaBuilder/librarySchemaBuilderHeader/librarySchemaBuilderHeaderName";
import { LibrarySchemaBuilderTabs } from "@kraaft/web/src/components/schemaBuilder/librarySchemaBuilder/librarySchemaBuilderTabs";
import * as TabBar from "@kraaft/web/src/components/tabBar";
import { Toggle } from "@kraaft/web/src/components/toggle/toggle";

import { useStyles } from "./librarySchemaBuilderHeader.styles";

interface LibrarySchemaBuilderHeaderProps {
  librarySchema: LibrarySchema;
}

const LibrarySchemaBuilderHeader = ({
  librarySchema,
}: LibrarySchemaBuilderHeaderProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigationService = useNavigationService();
  const route = useRouteService().getEditLibrarySchemaCurrentTab();

  const navigateToTab = useCallback(
    (newTab: LibrarySchemaBuilderTabs) => {
      navigationService.navigate("LibrarySchemaBuilder", {
        librarySchemaId: librarySchema.id,
        tab: newTab,
      });
    },
    [librarySchema.id, navigationService],
  );

  const handleToggleLibrarySchema = useCallback(
    (newValue: boolean) => {
      dispatch(
        OfflineLibrarySchemaActions.edit({
          id: librarySchema.id,
          update: { enabled: newValue },
        }),
      );
    },
    [dispatch, librarySchema.id],
  );

  return (
    <div className={classes.header}>
      <div className={classes.headerContainer}>
        <div className={classes.textContainer}>
          <LibrarySchemaBuilderHeaderName
            schemaName={librarySchema.schema.name}
          />
        </div>
        <div className={classes.toggleContainer}>
          <Toggle
            value={librarySchema.enabled}
            setValue={handleToggleLibrarySchema}
          />
          <Text size="BODY">
            {t("formBuilder.presentation.enableLibrarySchema")}
          </Text>
        </div>
      </div>
      <div className={classes.tabs}>
        <TabBar.Container value={route} onChange={navigateToTab}>
          <TabBar.Tab<LibrarySchemaBuilderTabs>
            icon="rows-01"
            label={t("formBuilder.schema.tabName")}
            value={LibrarySchemaBuilderTabs.schema}
            id="schema"
          />
          <TabBar.Tab<LibrarySchemaBuilderTabs>
            icon="settings-04"
            label={t("formBuilder.infos.tabName")}
            value={LibrarySchemaBuilderTabs.info}
            id="info"
          />
          <TabBar.Tab<LibrarySchemaBuilderTabs>
            icon="file-download-02"
            label={t("formBuilder.reports.tabName")}
            value={LibrarySchemaBuilderTabs.reports}
            id="reports"
          />
          <TabBar.Tab<LibrarySchemaBuilderTabs>
            icon="image-indent-left"
            label={t("formBuilder.presentation.tabName")}
            value={LibrarySchemaBuilderTabs.presentation}
            id="details"
          />
        </TabBar.Container>
      </div>
    </div>
  );
};

export { LibrarySchemaBuilderHeader };
