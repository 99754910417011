import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { Api } from "@kraaft/shared/core/services/api";
import { EditOneOperationBuilder } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/operations/editOne.operation";

export const editPresentationVideoOperation =
  EditOneOperationBuilder.create<LibrarySchema>()
    .payload<{ videoUrl?: string }>()
    .expected(async (aggregate, payload) => {
      aggregate.presentation.video = payload.videoUrl
        ? {
            type: "youtube",
            url: payload.videoUrl,
            id: "optimistic",
          }
        : undefined;
      return aggregate;
    })
    .mutate(async (payload) => {
      const { updatedAt } = await Api.setLibrarySchemaPresentationVideo({
        librarySchemaId: payload.id,
        videoUrl: payload.videoUrl,
      });

      return { updatedAt };
    });
