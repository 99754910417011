import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { BrowserPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import { OfflineLibrarySchemaActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.offline";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { ReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import { Api } from "@kraaft/shared/core/services/api/api";
import { AddReportTemplate } from "@kraaft/web/src/components/kDialog/customs/addReportTemplateDialog/addReportTemplateDialog";
import { OrderedListRows } from "@kraaft/web/src/components/orderableList/orderableList.types";
import { EditReportTemplates } from "@kraaft/web/src/components/schemaBuilder/tabs/editReportTemplates/editReportTemplates";

interface EditLibrarySchemaReportTemplatesProps {
  librarySchema: LibrarySchema;
}

const EditLibrarySchemaReportTemplates = ({
  librarySchema,
}: EditLibrarySchemaReportTemplatesProps) => {
  const dispatch = useDispatch();

  const handleAdd = useCallback(
    (params: Parameters<AddReportTemplate>[0]) => {
      dispatch(
        OfflineLibrarySchemaActions.addReportTemplate({
          id: librarySchema.id,
          file: params.file,
          name: params.name,
          librarySchemaId: params.schemaId,
          forceAdd: params.forceAdd,
        }),
      );
    },
    [dispatch, librarySchema.id],
  );

  const handleReorder = useCallback(
    (ordered: OrderedListRows<ReportTemplate>) => {
      dispatch(
        OfflineLibrarySchemaActions.reorderReportTemplates({
          id: librarySchema.id,
          orderedIds: Object.keys(ordered),
          librarySchemaId: librarySchema.id,
        }),
      );
    },
    [dispatch, librarySchema.id],
  );

  const handleToggle = useCallback(
    (reportTemplateId: string, enabled: boolean) => {
      dispatch(
        OfflineLibrarySchemaActions.toggleReportTemplate({
          id: librarySchema.id,
          reportTemplateId,
          enabled,
          librarySchemaId: librarySchema.id,
        }),
      );
    },
    [dispatch, librarySchema.id],
  );

  const handleRename = useCallback(
    (reportTemplateId: string, name: string) => {
      dispatch(
        OfflineLibrarySchemaActions.renameReportTemplate({
          id: librarySchema.id,
          reportTemplateId,
          name,
          librarySchemaId: librarySchema.id,
        }),
      );
    },
    [dispatch, librarySchema.id],
  );

  const handleReplaceFile = useCallback(
    (reportTemplateId: string, file: ModernFile<BrowserPath>) => {
      dispatch(
        OfflineLibrarySchemaActions.replaceReportTemplateFile({
          id: librarySchema.id,
          reportTemplateId,
          file,
          librarySchemaId: librarySchema.id,
        }),
      );
    },
    [dispatch, librarySchema.id],
  );

  const handleRemove = useCallback(
    (reportTemplateId: string) => {
      dispatch(
        OfflineLibrarySchemaActions.deleteReportTemplate({
          id: librarySchema.id,
          reportTemplateId,
          librarySchemaId: librarySchema.id,
        }),
      );
    },
    [dispatch, librarySchema.id],
  );

  const handleDownload = useCallback(async () => {
    const response = await Api.generateDefaultFolderTemplate({
      schemaId: librarySchema.id,
    });

    if (response?.downloadUrl) {
      await fileSaver.download(response.downloadUrl, response.filename);
    }
  }, [librarySchema.id]);

  return (
    <EditReportTemplates
      schema={librarySchema.schema}
      onAdd={handleAdd}
      onReorder={handleReorder}
      reportTemplates={librarySchema.reportTemplates}
      onToggle={handleToggle}
      onRename={handleRename}
      onReplaceFile={handleReplaceFile}
      onRemove={handleRemove}
      onDownload={handleDownload}
    />
  );
};
export { EditLibrarySchemaReportTemplates };
